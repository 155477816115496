import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import AddSourcePopup from './AddSourcePopup';
import ConfirmCreateBudget from './ConfirmCreateBudget';
import DeleteAccount from './DeleteAccount';
import Disconnect from './Disconnect';
import PaymentPopup from './PaymentPopup';
import css from './Popups.module.css';
import RemoveAccess from './RemoveAccess';
import RemoveBudget from './RemoveBudget';
import RemoveClient from './RemoveClient';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Popup = ({ type, onClose, onClick, setInvoices }) => {
  return (
    <div className={css.overlay} onClick={onClose}>
      <div
        className={` ${
          type === 'updatePayment' || type === 'addPayment'
            ? css.wrapperForPayment
            : css.wrapper
        }`}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {type === 'addSource' && (
          <AddSourcePopup onClose={onClose} onClick={onClick} />
        )}
        {type === 'disconnect' && (
          <Disconnect onClose={onClose} onClick={onClick} />
        )}
        {type === 'removeAccess' && (
          <RemoveAccess onClose={onClose} onClick={onClick} />
        )}
        {type === 'removeClient' && <RemoveClient />}
        {type === 'removeBudget' && <RemoveBudget />}
        {(type === 'user' || type === 'account') && (
          <DeleteAccount type={type} onClose={onClose} onClick={onClick} />
        )}
        {(type === 'updatePayment' || type === 'addPayment') && (
          <Elements stripe={stripePromise}>
            <PaymentPopup
              type={type}
              onClose={onClose}
              setInvoices={setInvoices}
            />
          </Elements>
        )}
      </div>
      {type === 'budget' && (
        <div className={css.tableWrapper}>
          <ConfirmCreateBudget onClose={onClose} onClick={onClick} />
        </div>
      )}
    </div>
  );
};

export default Popup;
