import React, { useMemo, useRef, useState } from 'react';
import arrowDown from '../../../assets/ArrowDownBlue.svg';
import arrowUp from '../../../assets/ArrowUpBlue.svg';
import { SORT_ORDER } from '../../../contants/dashboard';
import Skeleton from '../Skeleton';
import { sortDataInTable } from '../Tables/DashboardTableRows/functions';
import css from './../../../components/reusable/Tables/Tables.module.css';
import styles from './styles.module.css';
import TableDataCell from './tableDataCell';
import TableHeadComponent from './tableHeadComponent';

const MOCK_DATA_SKELETON = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const getRowData = (row, dataIndex) => {
  if (Array.isArray(dataIndex)) {
    return dataIndex.reduce((acc, key) => (acc ? acc[key] : undefined), row);
  } else {
    return row[dataIndex];
  }
};

const renderHeader = (
  columns,
  onClickSort,
  isLoading,
  sortKey = null,
  sortOrder = null,
) => {
  const handleClickHeaderCell = (keyOfColumn, sorter = false) => {
    if (sorter) {
      onClickSort(keyOfColumn);
    }
  };

  return (
    <tr>
      {columns.map((col, index) => {
        const key = col.key ? col.key : col.dataIndex;
        const isCurrentSortKey = sortKey === key;
        const classNameHeader = `${
          col?.classNameHeaderCell ? col?.classNameHeaderCell : ''
        } ${isCurrentSortKey ? styles.sortHeader : ''}`;

        return (
          <TableHeadComponent
            key={key}
            index={index}
            fixed={col.fixed}
            isLoading={isLoading}
            enableColumnResizing={col?.enableColumnResizing}
            width={col?.width}
            minWidth={col?.minWidth}
            style={{
              cursor: col?.sorter ? 'pointer' : 'text',
              color: isCurrentSortKey ? '#2055FD' : '#111A39',
              ...(col?.styleHeaderCell ? col?.styleHeaderCell : {}),
            }}
            className={classNameHeader}
            onClickHeaderCell={() => handleClickHeaderCell(key, col?.sorter)}
          >
            {col?.sorter ? (
              <div className={css.headerSort}>
                <div style={{ whiteSpace: 'nowrap' }}>
                  {col.title}
                  {isCurrentSortKey && (
                    <img
                      className={css.imgIcon}
                      alt={
                        sortOrder === SORT_ORDER.ASCEND
                          ? 'arrow up'
                          : 'arrow down'
                      }
                      src={
                        sortOrder === SORT_ORDER.ASCEND ? arrowUp : arrowDown
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              col.title
            )}
          </TableHeadComponent>
        );
      })}
    </tr>
  );
};

export const getColumnClassName = (className, record, index) => {
  if (className) {
    if (typeof className === 'function') return className(record, index);
    else return className;
  }

  return '';
};

export const getColumnStyle = (style, record, index) => {
  if (style) {
    if (typeof style === 'function') return style(record, index);
    else return style;
  }

  return {};
};

// Column Type
// title: Title of column. Is is header
// dataIndex: The corresponding data field in the object
// key (Optional): Unique identifier key for the column. If sorter is identified, please set key. Otherwise, using dataIndex is key
// sorter (Optional): Enables sorting data in the column. Default: false
// -- If sorter is set to true, it will use the default sorting behavior based on the data type.
// -- For strings, it performs a lexicographic (dictionary) sort.
// -- For numbers, it performs numeric sorting
// -- Can provide a custom sorting function as the value of sorter. Example: (a, b) => a.exampleData - b.exampleData,
// className (Optional): Can using function with 2 argument (record, index) to dynamically generate the className based on record value from function
// enableColumnResizing (Optional): default true. Allow resize width column
// style (Optional): Like props className
// render (Optional): Render function to create the content of the column cell with 2 arguments. There are: record and index

// Table component
// columns: column's array of table with column type
// dataSource: data of table
// isLoading: animation loading when load data in table
// unSortLastElement: sort exclude last element in dataSource
// rowClassNameProvider: className per row based on value return from rowClassNameProvider function provider
const Table = ({
  columns = [],
  dataSource = [],
  isLoading = false,
  unSortLastElement = false,
  rowClassNameProvider = null,
  haveTotalRow = false,
}) => {
  const tableRef = useRef();
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  if (!columns || !columns.length) {
    return <></>;
  }

  const formatColumns = {};
  columns.forEach(col => {
    const key = col.key ? col.key : col.dataIndex;
    formatColumns[key] = col;
  });

  // useMemo is used to memoize the result of the sorting operation
  const sortedData = useMemo(() => {
    // get sorter of column's current key
    const currentSelectedColumn = columns.find(col => {
      if (col?.key) return col.key === sortKey;
    });

    // check type sort
    return isLoading
      ? MOCK_DATA_SKELETON
      : sortDataInTable(
          dataSource,
          sortKey,
          sortOrder === SORT_ORDER.DESCEND,
          unSortLastElement,
          currentSelectedColumn?.sorter,
        );
  }, [dataSource, sortKey, sortOrder, isLoading]);

  // Function to handle click events for sorting
  const handleClickSort = key => {
    if (key) {
      // Update the sort order based on the current state
      // If the clicked key is the same as the current sort key, toggle between ASCEND and DESCEND
      // Otherwise, set the sort order to DESCEND for the new key
      setSortOrder(
        sortOrder === SORT_ORDER.DESCEND && key === sortKey
          ? SORT_ORDER.ASCEND
          : SORT_ORDER.DESCEND,
      );

      // Update the sort key to the clicked key
      setSortKey(key);
    }
  };

  return (
    <div
      className={`${styles.wrapperTable} ${
        isLoading ? styles.overfolowXHidden : ''
      }`}
    >
      <table
        className={`${styles.mainTable} ${
          !isLoading ? styles.tableLayoutFixed : ''
        }`}
        ref={tableRef}
      >
        <thead
          className={`${styles.headerTable} ${
            isLoading ? styles.loadingBar : ''
          }`}
        >
          {renderHeader(
            columns,
            handleClickSort,
            isLoading,
            sortKey,
            sortOrder,
          )}
        </thead>

        <tbody className={styles.bodyTable}>
          {sortedData.map((row, rowIndex) => {
            const customRowClassName = rowClassNameProvider
              ? rowClassNameProvider(row)
              : '';
            return (
              <tr key={row.id} className={`${customRowClassName}`}>
                {columns.map((col, columnIndex) => {
                  const key = col.key ? col.key : col.dataIndex;

                  const columnClassName = getColumnClassName(
                    col.className,
                    row,
                    columnIndex,
                  );
                  const colStyle = getColumnStyle(col?.style, row, columnIndex);

                  const handleOnClick = () => {
                    if (col?.onClickDataCell)
                      col.onClickDataCell(row, columnIndex);
                  };

                  const handleOnMouseEnter = () => {
                    if (col?.onMouseEnterDataCell)
                      col.onMouseEnterDataCell(row, columnIndex);
                  };

                  const handleOnMouseLeave = () => {
                    if (col?.onMouseLeaveDataCell)
                      col.onMouseLeaveDataCell(row, columnIndex);
                  };

                  return (
                    <TableDataCell
                      index={columnIndex}
                      className={columnClassName}
                      zIndex={columns.length - columnIndex}
                      fixed={col?.fixed}
                      isLoading={isLoading}
                      width={col?.width}
                      minWidth={col?.minWidth}
                      style={{
                        ...colStyle,
                      }}
                      onClick={handleOnClick}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    >
                      {isLoading ? (
                        <Skeleton />
                      ) : formatColumns[key].render ? (
                        formatColumns[key].render(row, columnIndex)
                      ) : (
                        getRowData(row, col.dataIndex)
                      )}
                    </TableDataCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
