import React from 'react';
import ReactSelect, { components } from 'react-select';
import calendar from '../../../assets/calendar.svg';
import css from './Select.module.css';

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img alt="timezone" src={calendar} />
      </components.DropdownIndicator>
    )
  );
};

const Select = ({
  options,
  value,
  name,
  onChange,
  styled,
  defaultValue,
  placeHolder,
  noOptionsMessage,
  placeHolderColor,
  disabled,
  className = '',
  menuPortalTarget = document.querySelector('main'),
  customOption = null,
  customValueContainer = null,
  styleMenu = {},
  styleInput = {},
  styleSingleValue = {},
  stylePlaceholder = {},
  styleMenuPortal = {},
  styleMenuList = {},
}) => (
  <div className={css.container}>
    <ReactSelect
      components={{
        ...(styled ? { DropdownIndicator } : {}),
        ...(customOption ? {Option: customOption} : {}),
        ...(customValueContainer ? {ValueContainer: customValueContainer} : {}),
      }}
      name={name}
      className={className}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      defaultValue={defaultValue}
      placeholder={placeHolder}
      classNamePrefix="modal"
      styles={{
        singleValue: () => ({
          gridArea: '1/1/2/3',
          color: 'var(--gray2)',
          margin: '0 2rem',
          fontSize: '1.6rem',
          lineHeight: '1.9rem',
          ...styleSingleValue,
        }),
        placeholder: () => ({
          gridArea: '1/1/2/3',
          color: placeHolderColor ? placeHolderColor : 'var(--gray)', // eslint-disable-line
          margin: '0 2rem',
          fontSize: '1.6rem',
          lineHeight: '1.9rem',
          fontWeight: '400',
          ...stylePlaceholder,
        }),
        input: () => ({
          gridArea: '1/1/auto/auto',
          color: 'var(--gray2)',
          margin: '0 2rem',
          fontSize: '1.6rem',
          lineHeight: '1.9rem',
          ...styleInput,
        }),
        menu: () => ({
          width: '50%',
          zIndex: 222,
          backgroundColor: 'var(--white)',
          boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          fontWeight: 400,
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          color: 'var(--gray2)',
          ...styleMenu,
        }),
        menuList: (base) => ({
          ...base,
          ...styleMenuList,
        }),
        option: (base) => ({
          ...base,
          cursor: "pointer"
        }),
        menuPortal: base => ({
          ...base,
          zIndex: 9999,
          ...styleMenuPortal,
        }),
      }}
      menuPortalTarget={menuPortalTarget}
      isSearchable={true}
      maxMenuHeight={260}
      menuPlacement="auto"
      value={value}
      noOptionsMessage={noOptionsMessage}
    />
  </div>
);

export default Select;
