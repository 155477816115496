import React from 'react';
import Item from './Item';
import styles from './styles.module.css';

const Collapse = ({ items = [] }) => {

  return (
    <div>
      {items.map((item, index) => {
        return (
          <div key={item?.key || index} className={styles.collapseContainer}>
            <div>
              <Item item={item}/>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Collapse;
