import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import edit from '../../../../assets/edit.svg';
import css from '../../../../components/reusable/Tables/Tables.module.css';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import Button from '../../Button/Button';
import Input from '../../Input/Input';

const BudgetNameCell = ({
  record,
  budgetToEdit,
  error,
  budgetIdSelected,

  isEdit,
  isAmount,
  isEditHover,
  isAmountHover,

  handleChangeBudgetName,
  handleCloseBudgetNameOrAmountModal,
  handleClickSaveBtn,
  handleOpenBudgetNameModal,
  handleClickBudget,
}) => {
  const popupBudgetNameRef = useRef(null);
  const rowParentRef = useRef(null);

  const [isOpenBudgetName, setIsOpenBudgetName] = useState(false);

  useClickOutside(popupBudgetNameRef, () => {
    if (isOpenBudgetName) {
      if (rowParentRef.current) {
        rowParentRef.current.style.zIndex = 0;
      }

      handleCloseBudgetNameOrAmountModal();
    }
  });

  useEffect(() => {
    if (isEdit === false && budgetIdSelected === null) {
      if (rowParentRef.current) {
        rowParentRef.current.style.zIndex = 0;
      }

      setIsOpenBudgetName(false);
      return;
    }

    if (isEdit && !isAmount && record.budget_id === budgetIdSelected) {
      setIsOpenBudgetName(true);
      return;
    }
  }, [isEdit, isAmount, record.budget_id, budgetIdSelected]);

  useLayoutEffect(() => {
    const posBottomParent = document
      .getElementById('dashboardTableBody')
      .getBoundingClientRect().bottom;
    if (popupBudgetNameRef.current && posBottomParent && isOpenBudgetName) {
      const posBottomDropdown =
        popupBudgetNameRef.current.getBoundingClientRect().bottom;
      if (posBottomDropdown - posBottomParent > 40) {
        popupBudgetNameRef.current.style.top = '-18rem';
      }
    }
  }, [isOpenBudgetName]);

  return (
    <>
      <span
        style={
          record.row_type === 'total'
            ? { color: '#111A39' }
            : { color: '#1649EC' }
        }
        onClick={() => {
          if (handleClickBudget) handleClickBudget(record);
        }}
      >
        {record.row_type === 'total' ? 'Total' : record.budget_name}
      </span>
      <img
        className={css.edit}
        style={{
          opacity: `${
            isEditHover === record.budget_id && !isAmountHover ? 1 : 0
          }`,
          display: `${record.row_type === 'total' ? 'none' : ''}`,
        }}
        alt="edit"
        src={edit}
        onClick={e => {
          if (handleOpenBudgetNameModal) {
            const rowParent = e.target.parentElement.parentElement;
            rowParent.style.zIndex = 1;
            rowParentRef.current = rowParent;

            handleOpenBudgetNameModal(record.budget_id);
          }
        }}
      />
      {isOpenBudgetName && (
        <div className={css.editContainer} ref={popupBudgetNameRef}>
          <h2 className={css.editTitle}>Budget Name</h2>
          <Input
            name="name"
            styled="edit"
            value={budgetToEdit?.name || ''}
            onChange={handleChangeBudgetName}
          />
          {error && <p className={css.error}>{error}</p>}
          <div className={css.editBtns}>
            <Button
              styled="edit"
              title="Cancel"
              onClick={handleCloseBudgetNameOrAmountModal}
            />
            <Button styled="edit" title="Save" onClick={handleClickSaveBtn} />
          </div>
        </div>
      )}
    </>
  );
};

export default BudgetNameCell;
