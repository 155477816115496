import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

const Metrics = ({ metrics = [], setCustomView }) => {
  return metrics.map((metric, _) => {
    return <Metric metric={metric} key={metric.key} setCustomView={setCustomView} />;
  });
};

export default Metrics;

const Metric = ({ metric, setCustomView }) => {
  const [isChecked, setIsChecked] = useState(metric.checked);

  useEffect(() => {
    setIsChecked(metric.checked);
  }, [metric.checked]);

  const handleCheckedMetric = () => {
    setIsChecked(!isChecked);
    const sectionType = metric.key.split('/')[0];

    setCustomView(prev => {
      const { columns } = prev[sectionType];
      const metricIndex = columns.findIndex(item => item.key === metric.key);
      if (metricIndex !== -1) {
        const metric = columns[metricIndex];
        metric.checked = !isChecked;
      }

      return { ...prev };
    });
  };

  return (
    <div className={styles.metric}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckedMetric}
        id={metric.key}
      />
      <label htmlFor={metric.key}>{metric?.name}</label>
    </div>
  );
};
