import React from 'react';

import ErrorIcon from '../../../assets/error-icon.svg';
import WarningIcon from '../../../assets/warning-icon.svg';

const Alert = ({ type, message }) => {
  return (
    <div className="alert-message-wrapper">
      <div className={`alert-message ${type}`}>
        {type == 'warning' && <img src={WarningIcon} alt='' />}
        {type == 'error' && <img src={ErrorIcon} alt='' />}
        {type == 'success' && <i className="far fa-circle-check" />}
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  );
};


export default Alert;
