import React, { useState, useEffect, useRef } from 'react';
import Button from '../reusable/Button/Button';
import refresh from '../../assets/refresh.svg';
import bell from '../../assets/bell.svg';
import help from '../../assets/help.svg';
import css from './Header.module.css';
import { NotificationsModal } from '../NotificationModal/NotificationModal';
import { Help } from '../NotificationModal/Help';
import { toggleRefresh } from '../../features/root/rootSlice';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../config/api';
import { setAccountDetails } from '../../features/userSettings/userSettingsSlice';
import apiv2, { READ_NOTYF } from '../../config/apiv2';

const Header = () => {
  const dispatch = useDispatch();

  const { accountDetails } = useSelector(state => state.userSettings);

  useEffect(() => {
    api.GET_COMPANY().then(r => {
      const { id, name } = r.data;
      dispatch(
        setAccountDetails({
          id,
          name,
        }),
      );
    });
  }, [dispatch]);

  const [data, setData] = useState({});
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false);
  const [isOpenHelpModal, setIsOpenHelpModal] = useState(false);
  const [notyfCount, setNotyfCount] = useState();
  const [trigger, setTrigger] = useState(false);
  const [notyfData, setNotyfData] = useState();

  useEffect(() => {
    setData({
      companyName: accountDetails?.name ?? '',
      companyId: accountDetails?.id ?? '',
      // userName: 'Admin',
    });
  }, [accountDetails]);

  const handleClick = type => {
    handleClose();
    if (type === 'help') {
      setIsOpenHelpModal(true);
    }
    if (type === 'notif') {
      setIsOpenNotificationModal(true);
      notyfData.forEach(elem => {
        if (!elem.is_read) {
          READ_NOTYF({ notification_id: elem.id });
        }
      });
      setTrigger(prev => !prev);
    }
  };

  const handleClose = () => {
    setIsOpenNotificationModal(false);
    setIsOpenHelpModal(false);
  };

  const handleRefresh = () => {
    // !isRefresh &&
    dispatch(toggleRefresh());
  };

  useEffect(() => {
    apiv2.GET_NOTIF().then(res => {
      let count = 0;
      setNotyfData(res.data);
      res.data.forEach(elem => {
        if (!elem.is_read) {
          count += 1;
        }
      });
      setNotyfCount(count);
    });
  }, [trigger]);

  return (
    <header className={css.header}>
      {isOpenNotificationModal && (
        <NotificationsModal closeModal={handleClose} setTrigger={setTrigger} />
      )}
      {isOpenHelpModal && <Help closeModal={handleClose} />}
      <div className={css.wrapper}>
        <div className={css.left}>
          <span className={css.company}>{data.companyName}</span>
          <span className={css.id}>{data.companyId}</span>
        </div>
        <nav className={css.nav}>
          <Button styled="nav" onClick={handleRefresh}>
            <div className={css.navWrapper}>
              <img className={css.navImg} alt="refresh" src={refresh} />
              <span className={css.navText}>Refresh</span>
            </div>
          </Button>
          <Button styled="nav" onClick={handleClick.bind(this, 'help')}>
            <div className={css.navWrapper}>
              <img
                className={`${css.navImg} ${css.helpImg}`}
                alt="help"
                src={help}
              />
              <span className={css.navText}>Help</span>
            </div>
          </Button>
          <Button styled="nav" onClick={handleClick.bind(this, 'notif')}>
            <div className={css.navWrapper}>
              <img className={css.navImg} alt="notifications" src={bell} />
              <div className={css.amountNotification}>{notyfCount}</div>
              <span className={css.navText}>Notifications</span>
            </div>
          </Button>
          {/* <div className={css.right}>
            <Button styled="user" onClick={handleUserClick}>
              <div className={css.btnWrapper}>
                <img className={css.userImg} alt="user" src={user} />
                <span className={css.userName}>{data.userName}</span>
              </div>
            </Button>
          </div> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
