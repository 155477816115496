export const PAGE_SIZES = [
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '250',
    value: 250,
  },
  {
    label: '500',
    value: 500,
  },
  {
    label: '750',
    value: 750,
  },
];
