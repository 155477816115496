import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './styles.module.css';

const TableHeadComponent = ({
  index,
  width = '180px',
  minWidth = '180px',
  style,
  className,
  fixed,
  children,
  isLoading,
  enableColumnResizing = true,
  onClickHeaderCell,
}) => {
  const ref = useRef();
  const resizerRef = useRef();

  const [position, setPosition] = useState(0);
  const [isResizing, setIsResizing] = useState(false);

  let temp = 0;
  let w = 0;

  useLayoutEffect(() => {
    if (fixed && ref.current) {
      const tHeads = Array.from(
        document.querySelectorAll('#dashboardTableBody table th'),
      );

      const sumWidth = Array.from(tHeads)
        .slice(0, index)
        .reduce((sum, currTh) => sum + currTh.clientWidth, 0);
      setPosition(sumWidth);
      // setPosition(ref.current.offsetLeft);
    }
  }, [ref.current, isLoading]);

  const mouseDownHandler = event => {
    event.stopPropagation()
    setIsResizing(true)

    temp = event.clientX;
    const th = event.target.parentNode;
    const styles = window.getComputedStyle(th);
    w = parseInt(styles.width, 10);

    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseUpHandler = e => {
    setIsResizing(false);

    resetPositionOfFixedCol();
    window.removeEventListener('mousemove', mouseMoveHandler);
    window.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = e => {
    const dx = e.clientX - temp;
    const _w = dx + w;
    if (_w <= parseInt(minWidth, 10)) return;
    resetPositionOfFixedCol();
    ref.current.style.width = `${dx + w}px`;
  };

  const resetPositionOfFixedCol = () => {
    const tHeads = Array.from(
      document.querySelectorAll('#dashboardTableBody table th'),
    );

    for (let i = index + 1; i < tHeads.length; i += 1) {
      const th = tHeads[i];
      const styles = window.getComputedStyle(th);

      if (styles.position === 'sticky') {
        // Calculate the sum of widths from the current index to 0
        const sumWidth = Array.from(tHeads)
          .slice(0, i)
          .reduce((sum, currTh) => sum + currTh.clientWidth, 0);
        th.style.left = sumWidth + 'px';

        const tDataCells = Array.from(
          document.querySelectorAll(
            `#dashboardTableBody table tr td:nth-child(${i + 1})`,
          ),
        );

        tDataCells.forEach(td => {
          td.style.left = sumWidth + 'px';
        });
      }
    }
  };

  const handleClickTableDataCell = event => {
    if (onClickHeaderCell && event.target !== resizerRef.current) {
      onClickHeaderCell();
    }
  };

  return (
    <th
      ref={ref}
      style={{
        width: width,
        minWidth: minWidth,
        ...style,
        ...(fixed ? { position: 'sticky', zIndex: 9 } : {}),
        [fixed]: position,
      }}
      className={className}
      onClick={handleClickTableDataCell}
    >
      {children}
      {enableColumnResizing && (
        <div
          ref={resizerRef}
          className={`resizer-handler ${styles.resizer} ${
            isResizing ? styles.resizing : ''
          }`}
          onMouseDown={mouseDownHandler}
        ></div>
      )}
    </th>
  );
};

export default TableHeadComponent;
