import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClientsToRemove,
  setRemoveClient,
} from '../../../features/dashboard/dashboardSlice';
import { successClientDelete } from '../../../helpers/notyf';
import Button from '../Button/Button';
import api from '../../../config/api';
import css from './Popups.module.css';

const RemoveClient = () => {
  const { clientsToRemove } = useSelector(state => state.dashboard);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setClientsToRemove([]));
    dispatch(setRemoveClient(false));
  };

  const handleRemove = () => {
    clientsToRemove.map(el =>
      api
        .DELETE_CLIENT(el)
        .then(res => res.status === 'OK' && successClientDelete()),
    );
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <h1 className={css.title2}>{`Remove ${clientsToRemove.length} Client${
        clientsToRemove.length > 1 ? 's' : ''
      }?`}</h1>
      <p className={css.text}>
        Deleting clients will remove them and their associated budgets from your
        dashboard.
      </p>
      <div className={css.btnsWrapper}>
        <Button styled="close" title="Cancel" onClick={handleClose} />
        <Button styled="delete" title="Remove" onClick={handleRemove} />
      </div>
    </>
  );
};

export default RemoveClient;
