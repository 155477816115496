import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.png';
import Modal from '../../components/reusable/Modals/Modal';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import { CREATE_ACCOUNT_PATH, DASHBOARD_PATH, SETTING_USAGE_BILLING_PATH, SETTING_USER_PATH } from '../../contants';
import { PERMISSIONS } from '../../contants/permissions';
import { setMe, setTriggerGetMe } from '../../features/me/meSlice';
import { successLogin } from '../../helpers/notyf';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    success: false,
    activated: false,
    restorePassword: false,
    message: '',
    remember: false,
  });
  const [cookies, setCookies] = useState();

  const needRefresh = useSelector(state => state.me.triggerGetMe)
  const dispatch = useDispatch();
  
  const navigate = useNavigate();

  document.title = ' Airtomic: Log In';

  useEffect(() => {
    const cookies = document.cookie.split(';');
    const array = [];
    cookies.forEach(elem => {
      const element = elem.split('=');
      array.push({
        [element[0]]: element[1],
      });
      setCookies(array);
    });
  }, []);

  const handleGoogleLogin = useGoogleLogin({
    scope:
      'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    //flow: 'auth-code',
    onSuccess: response => {
      api
        .GOOGLE_LOGIN({ access_token: response.access_token, payload: cookies })
        .then(response => {
          if (response == 201) {
            apiv2.GET_ME().then(response => {
              dispatch(setMe(response.data));
              if (response.data.has_finished_signup) {
                let {
                  id,
                  email,
                  company: { id: copanyId },
                } = response.data;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'login',
                  user_id: id,
                  company_id: copanyId,
                  email: email,
                });
                navigate(DASHBOARD_PATH, { replace: true });
              } else {
                navigate(CREATE_ACCOUNT_PATH, { replace: true });
              }
            });
          } else {
            setFormData({
              ...formData,
              success: false,
              message: 'Something went wrong, please try again after sometime.',
            });
          }
        });
    },
    onError: () => {
      setFormData({
        ...formData,
        success: false,
        message: 'Something went wrong, please try again after sometime.',
      });
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    setFormData({ ...formData, message: '', success: false });
    localStorage.setItem('TOKEN_CALL_FROM', 'LOGIN_PAGE');
    return new Promise(r => {
      api
        .LOG_IN(formData)
        .then(loginResponse => {
          if (loginResponse === 200) {
            localStorage.removeItem('TOKEN_CALL_FROM');
            apiv2.GET_ME().then(response => {
              const currentUser = response.data;

              dispatch(setMe(currentUser));
              if (currentUser.has_finished_signup) {
                let {
                  id,
                  email,
                  company: { id: copanyId },
                } = currentUser;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'login',
                  user_id: id,
                  company_id: copanyId,
                  email: email,
                });

                const {
                  permission_level: { name: permissionLevelName },
                } = currentUser;
                const { trial_period_expired, payment_error, payment_method_attached } =
                  currentUser.notifications;
                  dispatch(setTriggerGetMe(!needRefresh))
                successLogin();

                if ((!payment_method_attached && trial_period_expired) || payment_error) {
                  if (permissionLevelName === PERMISSIONS.ADMIN) {
                    navigate(SETTING_USAGE_BILLING_PATH, { replace: true });
                  } else if (permissionLevelName === PERMISSIONS.READ_ONLY || permissionLevelName === PERMISSIONS.STANDARD) {
                    navigate(SETTING_USER_PATH, { replace: true });
                  }
                } else {
                  navigate(DASHBOARD_PATH, { replace: true });
                }
              } else {
                navigate(DASHBOARD_PATH, { replace: true });
              }
            });
          } else {
            let message = loginResponse?.detail
              ? loginResponse.detail
              : 'Something went wrong, please try again after sometime.';
            setFormData({ ...formData, success: false, message });
            //failLogin();
          }
        })
        .finally(() => r());
    });
  };

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleCheckbox = e =>
    setFormData({ ...formData, remember: e.target.checked });

  return (
    <main>
      <div className="auth-form-wrapper">
        <img className="logo" alt="Logo" src={logo} />
        <Modal
          type="login"
          data={formData}
          onGoogleLogin={handleGoogleLogin}
          onSubmit={handleSubmit}
          onChange={handleChange}
          onCheckbox={handleCheckbox}
        />
      </div>
    </main>
  );
};

export default LoginPage;
