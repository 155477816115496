import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import css from './Modals.module.css';
import question from '../../../assets/question.svg';

const Client = ({ mode, data, options, onSubmit, onChange, onCancel }) => {
  const {
    nameError,
    shortNameError,
    uniqueNameError,
    countryError,
    timezoneError,
    currencyError,
  } = useSelector(state => state.createClient);

  const [isHoverTimezone, setIsHoverTimezone] = useState(false);
  const [isHoverCurrency, setIsHoverCurrency] = useState(false);

  const handleOnMouseEnter = type =>
    type === 'timezone' ? setIsHoverTimezone(true) : setIsHoverCurrency(true);

  const handleOnMouseLeave = type =>
    type === 'timezone' ? setIsHoverTimezone(false) : setIsHoverCurrency(false);

  return (
    <>
      <h1 className={css.title}>
        {mode === 'create' ? 'Create Client' : 'Edit Client'}
      </h1>
      <form className={css.form}>
        <p className={css.formTitle}>Client Name</p>
        {nameError && <p className={css.error}>Please provide a valid name</p>}
        {shortNameError && (
          <p className={css.error}>Min length is 2 characters</p>
        )}
        {uniqueNameError && (
          <p className={css.error}>This name has already been taken</p>
        )}
        <Input
          name="name"
          type="text"
          placeholder="Enter Client Name"
          value={data.name}
          onChange={onChange}
          required
        />
        <p className={css.formTitle}>Country</p>
        {countryError && (
          <p className={css.error}>Please select a valid country</p>
        )}
        <Select
          name="country"
          placeHolder="Select Country Name"
          value={data.country}
          onChange={onChange}
          options={options.country}
          required
        />
        <div className={css.formTitleWrapper}>
          <p className={css.formTitle}>Time Zone</p>

          <div className={css.hoverImgWrapper}>
            <img
              src={question}
              alt="question"
              className={css.formIcon}
              onMouseEnter={() => handleOnMouseEnter('timezone')}
              onMouseLeave={() => handleOnMouseLeave('timezone')}
            />

            {isHoverTimezone && (
              <div className={css.hoverContainer}>
                <span className={css.hoverTitle}>
                  This aligns your budget data to the relevant time zone. You
                  will be able to edit this.
                </span>
              </div>
            )}
          </div>
        </div>
        {timezoneError && (
          <p className={css.error}>Please select a valid time zone</p>
        )}
        <Select
          name="timezone"
          placeHolder="Select Time Zone"
          styled="calendar"
          value={data.timezone}
          onChange={onChange}
          options={options.timezones?.[data.country?.label] ?? []}
          noOptionsMessage={() => 'Please select a country first'}
          required
        />
        <div className={css.formTitleWrapper}>
          <p className={css.formTitle}>Currency Symbol</p>
          <div className={css.hoverImgWrapper}>
            <img
              src={question}
              alt="question"
              className={css.formIcon}
              onMouseEnter={() => handleOnMouseEnter('currency')}
              onMouseLeave={() => handleOnMouseLeave('currency')}
            />

            {isHoverCurrency && (
              <div className={css.hoverContainer}>
                <span className={css.hoverTitle}>
                  Monetary values are fetched from your data sources. The symbol
                  set here is solely for visual representation purposes.
                </span>
              </div>
            )}
          </div>
        </div>
        {currencyError && (
          <p className={css.error}>Please select a valid currency</p>
        )}
        <Select
          name="currency"
          placeHolder="Select Currency Symbol"
          value={data.currency}
          onChange={onChange}
          options={options.currency}
          required
        />
        <div className={css.btnsWrapper}>
          <Button
            styled="submit"
            title={mode === 'create' ? 'Create Client' : 'Save Changes'}
            onClick={onSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default Client;
