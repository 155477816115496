import { React, useRef } from 'react';
import Card from '../../../../../components/reusable/Card';
import Select from '../../../../../components/reusable/Select/Select';
import TextArea from '../../../../../components/reusable/TextArea';
import {
  ALL_CAMPAIGNS_CONDITIONS,
  CONTAINS_OPTIONS,
} from '../../../../../contants/automatedRules';
import { useSelectPositionLeft } from '../../../../../hooks/useSelectPositionLeft';
import styles from './styles.module.css';

const CampaignName = ({ rule, setRule, error, setError, setConditionSelected }) => {
  const {
    conditionCampaignStatus,
    conditionCampaignNameRule,
    conditionCampaignNamePattern,
  } = rule;

  const wrapperSelectRef = useRef(null);
  const selectPosLeft = useSelectPositionLeft(wrapperSelectRef);

  const handleChangeCondCampaignStatus = option => {
    setRule(prev => ({
      ...prev,
      conditionCampaignStatus: option
    }));
  };

  const handleChangeCondCampaignNameRule = option => {
    setRule(prev => ({
      ...prev,
      conditionCampaignNameRule: option,
    }));
  };

  const handleChangeCondNamePattern = e => {
    setRule(prev => ({
      ...prev,
      conditionCampaignNamePattern: e.target.value,
    }));
  };

  const handleApplyCampaignNameCondition = () => {
    if(!conditionCampaignNamePattern?.trim()) {
      setError({
        ...error,
        conditionCampaignNamePattern: "Please enter at least one value"
      })

      return
    }

    setConditionSelected(null);
  };

  return (
    <Card className={`${styles.campaignName}`}>
      <h4>Campaign Name</h4>
      <div className={`${styles.containerSelect}`}>
        <div ref={wrapperSelectRef} className={`${styles.selectPosLeft}`}>
          <Select
            name="conditionCampaignStatus"
            placeHolder="Campaign status: all"
            stylePlaceholder={{
              margin: '0',
              fontSize: '1.4rem',
            }}
            styleSingleValue={{
              whiteSpace: 'nowrap',
              margin: '0',
              fontSize: '1.4rem',
            }}
            styleInput={{
              margin: '0',
            }}
            styleMenuPortal={{
              width: '28rem',
              left: selectPosLeft,
            }}
            options={ALL_CAMPAIGNS_CONDITIONS}
            value={conditionCampaignStatus}
            onChange={handleChangeCondCampaignStatus}
          />
        </div>

        <div className={`${styles.selectPosLeft}`}>
          <Select
            name="conditionCampaignNameRule"
            placeHolder="Contains"
            stylePlaceholder={{
              margin: '0',
              fontSize: '1.4rem',
            }}
            styleSingleValue={{
              whiteSpace: 'nowrap',
              margin: '0',
              fontSize: '1.4rem',
            }}
            styleInput={{
              margin: '0',
            }}
            styleMenuPortal={{
              width: '36rem',
              left: selectPosLeft,
            }}
            options={CONTAINS_OPTIONS}
            value={conditionCampaignNameRule}
            onChange={handleChangeCondCampaignNameRule}
          />
        </div>
        <div className={`${styles.containerValueString}`}>
          <span className={`${styles.labelValueString}`}>
            Any of following values
          </span>
          <TextArea
            className={`${styles.inputValue}`}
            name="value"
            type="text"
            placeholder="Value"
            onChange={handleChangeCondNamePattern}
            value={conditionCampaignNamePattern}
          />
          <span className={`${styles.labelValueString} ${styles.helpText}`}>
            Enter one value per line
          </span>
          {error?.conditionCampaignNamePattern && (
            <p className={styles.error}>{error?.conditionCampaignNamePattern}</p>
          )}
        </div>
        <div className={styles.wrapperBtn}>
          <button className={styles.applyBtn} onClick={handleApplyCampaignNameCondition}>Apply</button>
          </div>
      </div>
    </Card>
  );
};

export default CampaignName;
