import React, { useEffect, useRef, useState } from 'react';
import arrowDown from '../../../../../assets/arrowDown.svg';
import styles from './styles.module.css';

const Item = ({ item }) => {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.clientHeight + 8);
    }
  }, [item?.children]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={styles.collapseItem} onClick={handleToggle}>
        <div>{item?.label}</div>
        <div
          className={`${styles.iconExpand} ${
            isExpanded ? styles.expanded : ''
          }`}
          onClick={handleToggle}
        >
          <img src={arrowDown} alt="arrow_icon" />
        </div>
      </div>
      <div
        className={styles.contentParent}
        style={{ height: isExpanded ? contentHeight : 0 }}
      >
        <div ref={ref}>{item?.children}</div>
      </div>
    </>
  );
};

export default Item;
