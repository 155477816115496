import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBudgetsToRemove,
  setRemoveBudget,
} from '../../../features/dashboard/dashboardSlice';
import { successBudgetDelete } from '../../../helpers/notyf';
import Button from '../Button/Button';
import api from '../../../config/api';
import css from './Popups.module.css';

const RemoveBudget = () => {
  const { budgetsToRemove } = useSelector(state => state.dashboard);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setBudgetsToRemove([]));
    dispatch(setRemoveBudget(false));
  };

  const handleRemove = () => {
    budgetsToRemove.map(el =>
      api
        .DELETE_BUDGET(el)
        .then(res => res.status === 'OK' && successBudgetDelete()),
    );
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <h1 className={css.title2}>{`Remove ${budgetsToRemove.length} Budget${
        budgetsToRemove.length > 1 ? 's' : ''
      }?`}</h1>
      <p className={css.text}>
        Deleting budgets will remove them from your dashboard and they will no
        longer be tracked.
      </p>
      <div className={css.btnsWrapper}>
        <Button styled="close" title="Cancel" onClick={handleClose} />
        <Button styled="delete" title="Remove" onClick={handleRemove} />
      </div>
    </>
  );
};

export default RemoveBudget;
