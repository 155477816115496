import React from 'react';
import Button from '../Button/Button';
import Table from '../Tables/Table';
import css from './Popups.module.css';

const ConfirmCreateBudget = ({ onClose, onClick }) => (
  <>
    <h1 className={`${css.title2} ${css.sticky}`}>Selected Campaigns</h1>
    <Table type="confirmBudget" />
    {!!onClick && (
      <div className={css.btns}>
        <Button styled="cancel" title="Back" onClick={onClose} />
        <Button styled="submit" title="Confirm" onClick={onClick} />
      </div>
    )}
  </>
);

export default ConfirmCreateBudget;
