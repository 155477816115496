import axios from 'axios';
const URL = process.env.REACT_APP_URL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `token ${localStorage.getItem('ACCESS_TOKEN')}`,
  },
});

service.interceptors.request.use(
  request => {
    request.headers.Authorization = `token ${localStorage.getItem(
      'ACCESS_TOKEN',
    )}`;
    return request;
  },
  error => {
    return error;
  },
);

service.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      let tokenCallFrom = localStorage.getItem('TOKEN_CALL_FROM');
      if (tokenCallFrom !== 'LOGIN_PAGE') {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers.Authorization = `token ${token}`;
              return service.request(originalRequest);
            })
            .catch(err => Promise.reject(err));
        }

        const refreshToken = localStorage.getItem('REFRESH_TOKEN');
        if (refreshToken) {
          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise((resolve, reject) => {
            axios
              .post(`${URL}/auth/token/refresh/`, { refresh: refreshToken })
              .then(apiTokensResponse => {
                localStorage.setItem(
                  'ACCESS_TOKEN',
                  apiTokensResponse.data.access,
                );
                localStorage.setItem(
                  'REFRESH_TOKEN',
                  apiTokensResponse.data.refresh,
                );

                originalRequest.headers.Authorization = `token ${apiTokensResponse.data.access}`;
                processQueue(null, apiTokensResponse.data.access);
                resolve(service(originalRequest));
              })
              .catch(err => {
                localStorage.removeItem('ACCESS_TOKEN');
                localStorage.removeItem('REFRESH_TOKEN');
                window.location.replace('/login');
                processQueue(err, null);
                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
          });
        }
      }
    }

    return Promise.reject(error);
  },
);

export default service;
