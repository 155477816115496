import React, { useEffect, useState } from 'react';
import css from './NotificationModal.module.css';
import apiv2, { READ_NOTYF } from '../../config/apiv2';
import close from '../../assets/CloseIcon.svg';

export const NotificationsModal = ({ closeModal, setTrigger }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toRead = () => {};

  const textLength = text => {
    const res = text.length > 125 ? text.substring(0, 125) + '...' : text;
    return res;
  };

  useEffect(() => {
    setLoading(true);
    apiv2
      .GET_NOTIF()
      .then(res => setData(res.data))
      .finally(() => setLoading(false));
  }, []);

  const read = id => {
    setLoading(true);
    READ_NOTYF({ notification_id: id })
      .then(res => {
        setTrigger(prev => !prev);
        apiv2.GET_NOTIF().then(res => setData(res.data));
      })
      .finally(() => setLoading(false));
  };

  return loading ? (
    <div className={css.overlay}>
      <div className={css.containerForLoader}>
        <div className={css.loader}></div>
      </div>
    </div>
  ) : (
    <div className={css.overlay}>
      <div className={css.NotificationWrapper}>
        <div className={css.header}>
          <h2 className={css.mainTitle}>Notifications</h2>
          <img src={close} className={css.close} onClick={() => closeModal()} />
        </div>
        {data.map(elem => {
          return (
            <div className={css.item} onClick={() => toRead()}>
              <div className={css.title}>{elem.title}</div>
              <div className={css.text}>{textLength(elem.description)}</div>
              <a
                href={elem.url}
                target="_blanc"
                className={css.learn_more_link}
                onClick={() => read(elem.id)}
              >
                Learn more
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
