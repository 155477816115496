import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFormData,
  setAmountError,
  setEndDateError,
  setNameError,
  setStartDateError,
  setTypeError,
  setUniqueNameError,
} from '../../features/createBudget/createBudgetSlice';
import { parceBudget } from '../../helpers/parceBudget';
import Input from '../reusable/Input/Input';
import Select from '../reusable/Select/Select';
import css from './SetBudget.module.css';

const SetCustomBudget = ({ options }) => {
  const {
    formData,
    startDateError,
    endDateError,
    amountError,
    typeError,
    nameError,
    uniqueNameError,
  } = useSelector(state => state.createBudget);
  const daysDifferentValid = formData.end_date && formData.start_date ? 
    moment(formData.end_date).diff(moment(formData.start_date), 'days') >= 2 : true;

  const dispatch = useDispatch();

  const handleChange = (e, a) => {
    if (a?.name === 'type') {
      dispatch(setTypeError(false));
      dispatch(addFormData({ ...formData, [a.name]: e }));
    } else {
      if (e.target.name === 'name') {
        dispatch(setNameError(false));
        dispatch(setUniqueNameError(false));
      }
      if (e.target.name === 'amount') dispatch(setAmountError(''));
      const amount = e.target.value.replace(/\,/g, '');
      if (
        e.target.name === 'amount' &&
        amount &&
        (+amount === 0 || +amount > 1000000000 || isNaN(amount))
      )
        return;
      dispatch(
        addFormData({
          ...formData,
          [e.target.name]: e.target.name === 'amount' ? amount : e.target.value,
        }),
      );
    }
  };

  const handleStartDate = date => {
    dispatch(setStartDateError(false));
    dispatch(setEndDateError(false));
    dispatch(addFormData({ ...formData, start_date: date }));
  };

  const handleEndDate = date => {
    dispatch(setStartDateError(false));
    dispatch(setEndDateError(false));
    dispatch(addFormData({ ...formData, end_date: date }));
  };

  return (
    <>
      <h2 className={css.select}>Start Date</h2>
      {startDateError && (
        <p className={css.error}>Please select a valid date</p>
      )}

      {!daysDifferentValid && !startDateError && (
        <p className={css.error}>Date range must be at least 2 days apart.</p>
      )}
      <div className={css.dateContainer}>
        <DatePicker
          locale="en"
          className={css.inputDate}
          popperClassName={css.popperDatepicker}
          dayClassName={() => css.dayDatepicker}
          placeholderText="Select The Start Date"
          dateFormat="d MMMM yyyy"
          onChange={handleStartDate}
          selected={
            (formData.start_date && new Date(formData.start_date)) || ''
          }
          maxDate={(formData.end_date && new Date(formData.end_date)) || null}
        />
      </div>
      <h2 className={css.select}>End Date</h2>
      {endDateError && (
        <p className={css.error}>Please select a valid date</p>
      )}

      {!daysDifferentValid && !endDateError && (
        <p className={css.error}>Date range must be at least 2 days apart.</p>
      )}
      <div className={css.dateContainer}>
        <DatePicker
          locale="en"
          className={css.inputDate}
          popperClassName={css.popperDatepicker}
          dayClassName={() => css.dayDatepicker}
          placeholderText="Select The End Date"
          dateFormat="d MMMM yyyy"
          onChange={handleEndDate}
          selected={(formData.end_date && new Date(formData.end_date)) || ''}
          minDate={
            (formData.start_date && new Date(formData.start_date)) || null
          }
        />
      </div>
      <h2 className={css.select}>Budget Amount</h2>
      {amountError && <p className={css.error}>{amountError}</p>}
      <Input
        name="amount"
        styled="mb"
        placeholder="Enter Your Budget Amount"
        value={
          formData.amount && formData.amount >= 0
            ? parceBudget(formData.amount, '', 0)
            : ''
        }
        onChange={handleChange}
      />
      <h2 className={css.select}>Budget Type</h2>
      {typeError && <p className={css.error}>Please select the budget type</p>}
      <Select
        name="type"
        placeHolder="Select The Budget Type"
        options={options}
        value={formData.renew_type}
        onChange={handleChange}
      />
      <h2 className={css.select}>Budget Name</h2>
      {nameError && <p className={css.error}>Please provide a budget name</p>}
      {uniqueNameError && (
        <p className={css.error}>This name has already been taken</p>
      )}
      <Input
        name="name"
        styled="mb15"
        placeholder="Enter Your Budget Name"
        value={formData.name || ''}
        onChange={handleChange}
      />
    </>
  );
};

export default SetCustomBudget;
