import googleAdsLogo from '../assets/GoogleAdsLogo.png';
import facebookLogo from '../assets/facebookLogo.svg';
import tikTokLogo from '../assets/tikTokLogo.webp';
import linkInLogo from '../assets/linked_in_logo.webp';

export const STATUS = {
  PAUSED: 'paused',
  ACTIVE: 'active',
  ERROR: 'error',
};

export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const TIKTOK = 'tiktok';
export const LINKEDIN = 'linkedin';

export const TYPE_DATASOURCES = {
  [GOOGLE]: {
    type: GOOGLE,
    name: 'Google Ads',
    logo: googleAdsLogo,
  },
  [FACEBOOK]: {
    type: FACEBOOK,
    name: 'Facebook Ads',
    logo: facebookLogo,
  },
  [TIKTOK]: {
    type: TIKTOK,
    name: 'TikTok Ads',
    logo: tikTokLogo,
  },
  [LINKEDIN]: {
    type: LINKEDIN,
    name: 'LinkedIn Ads',
    logo: linkInLogo,
  },
};

export const CLIENT = 'clients';
export const CLIENT_METRIC = 'client_metrics';

export const INITIAL_METRICS = {
  [CLIENT]: {
    columns: [],
  },
  [CLIENT_METRIC]: {
    columns: [
      {
        key: 'client_metrics/client_name',
        name: 'Client',
        checked: false,
      },
      {
        key: 'client_metrics/spend',
        name: 'Spend',
        checked: false,
      },
      {
        key: 'client_metrics/budget/trend',
        name: 'Trend',
        checked: false,
      },
      {
        key: 'client_metrics/budget/remaining_budget',
        name: 'Remaining Budget',
        checked: false,
      },
      {
        key: 'client_metrics/budget/ideal_daily_spend',
        name: 'Ideal Daily Spend',
        checked: false,
      },
      {
        key: 'client_metrics/budget/yesterday_spend',
        name: "Yesterday's Spend",
        checked: false,
      },

      {
        key: 'client_metrics/budget/tree_days_avg_spend',
        name: '3 Day Avg. Spend',
        checked: false,
      },
      {
        key: 'client_metrics/budget/forecast_spend',
        name: 'Forecast Spend',
        checked: false,
      },
      {
        key: 'client_metrics/budget/lost_is_budget_percentage',
        name: 'Lost IS (Budget)',
        checked: false,
      },
      {
        key: 'client_metrics/performance/conversions',
        name: 'Conversions',
        checked: false,
      },
      {
        key: 'client_metrics/performance/cpa',
        name: 'CPA',
        checked: false,
      },
      {
        key: 'client_metrics/performance/revenue',
        name: 'Revenue',
        checked: false,
      },
      {
        key: 'client_metrics/performance/roas',
        name: 'ROAS',
        checked: false,
      },
      {
        key: 'client_metrics/performance/seven_days_cpa',
        name: '7 Day CPA',
        checked: false,
      },
      {
        key: 'client_metrics/performance/seven_days_roas',
        name: '7 Day ROAS',
        checked: false,
      },
      {
        key: 'client_metrics/performance/seven_days_cpa_change',
        name: '7 Day CPA change',
        checked: false,
      },
      {
        key: 'client_metrics/performance/seven_days_roas_change',
        name: '7 Day ROAS change',
        checked: false,
      },
      {
        key: 'client_metrics/performance/forecast_conversions',
        name: 'Forecast Conv.',
        checked: false,
      },
      {
        key: 'client_metrics/performance/forecast_revenue',
        name: 'Forecast Revenue',
        checked: false,
      },
    ],
  },
};
