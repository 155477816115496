import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../../../components/reusable/Card';
import Select from '../../../../../components/reusable/Select/Select';
import { ALL_CAMPAIGNS_CONDITIONS } from '../../../../../contants/automatedRules';
import { useSelectPositionLeft } from '../../../../../hooks/useSelectPositionLeft';
import styles from './styles.module.css';

const AllCampaignCondition = ({ className, rule, setRule }) => {
  const { conditionCampaignStatus } = rule;

  const wrapperSelectRef = useRef(null);
  const selectPosLeft = useSelectPositionLeft(wrapperSelectRef);

  const handleChangeStatus = option => {
    setRule(prev => ({
      ...prev,
      conditionCampaignStatus: option,
    }));
  };

  return (
    <Card className={`${styles.allCampaignCondition} ${className}`}>
      <h4>All campaigns</h4>
      <div ref={wrapperSelectRef} className={`${styles.wrapperSelect}`}>
        <Select
          name="campaignCondition"
          placeHolder="Campaign status: all"
          stylePlaceholder={{
            margin: '0',
            fontSize: '1.4rem',
          }}
          styleSingleValue={{
            whiteSpace: 'nowrap',
            margin: '0',
            fontSize: '1.4rem',
          }}
          styleInput={{
            margin: '0',
          }}
          styleMenuPortal={{
            width: '28rem',
            left: selectPosLeft,
          }}
          value={conditionCampaignStatus}
          options={ALL_CAMPAIGNS_CONDITIONS}
          onChange={handleChangeStatus}
        />
      </div>
    </Card>
  );
};

export default AllCampaignCondition;
