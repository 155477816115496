import moment from 'moment';
import api from '../config/api';
import {
  ALL_CAMPAIGNS_CONDITIONS,
  CONTAINS_OPTIONS,
  RULE_ACTION_CONDITIONS,
  RULE_ACTION_TYPES,
  RULE_NOTIFICATIONS,
} from '../contants/automatedRules';
import { CLIENT, CLIENT_METRIC, FACEBOOK, GOOGLE, LINKEDIN, TIKTOK } from '../contants/datasource';
import apiv2 from '../config/apiv2';

export const getMinuteDiffWithNow = time => {
  const timeNow = new Date().getTime();
  const minuteDiff = Math.round((timeNow - time) / 1000 / 60);

  return minuteDiff;
};

export const isOneBudgetCheck = (tab, tableData, filterElems) => {
  if (tab !== 'campaigns') return false;

  if (filterElems.length !== 0 && !hasDifferentBudgetIds(tableData))
    return true;

  return false;
};

export const hasDifferentBudgetIds = array => {
  const notHaveTotalRow = array.filter(
    campaign => campaign.row_type !== 'total',
  );
  if (notHaveTotalRow.length === 0) {
    return false;
  }

  const firstBudgetId = notHaveTotalRow[0].budget_id;

  return notHaveTotalRow.some(element => element.budget_id !== firstBudgetId);
};

export const checkBudgetsCreateBySameClient = data => {
  const notHaveTotalRow = data.filter(budget => budget.row_type !== 'total');
  if (notHaveTotalRow.length === 0) {
    return false;
  }

  const firstClientId = notHaveTotalRow[0].client_id;
  return notHaveTotalRow.every(element => element.client_id === firstClientId);
};

export const validateAutomatedRules = rule => {
  let error = {};

  if (rule.name === '' || !rule.name) {
    error.name = 'Please provide a valid name';
  }

  if (!rule.client?.value) {
    error.client = 'Please select a valid client';
  }

  if (!rule.budget?.value) {
    error.budget = 'Please select a valid budget';
  }

  if (!rule.actionType?.label) {
    error.actionType = 'Please select a valid action type';
  }

  if (!rule.notificationType?.label) {
    error.notificationType = 'Please select a valid notification type';
  }

  if (rule.notificationRecipients.length === 0) {
    error.notificationRecipients = 'Please provide a valid email';
  }

  const isInvalidInfoAllCampaignCond =
    rule.conditionType?.label === 'All campaigns' &&
    !rule.conditionCampaignStatus?.label;
  const isInvalidInfoCampaignNameCond =
    rule.conditionType?.label === 'Campaign name' &&
    (!rule.conditionCampaignNameRule?.label ||
    !rule.conditionCampaignStatus?.label ||
    !rule.conditionCampaignNamePattern?.trim())

  if (isInvalidInfoAllCampaignCond) {
    error.conditionType = 'Please select a valid condition for all campaigns';
  } else if (isInvalidInfoCampaignNameCond) {
    error.conditionType = 'Please provide a valid condition for campaign name';
  }

  if(rule.conditionType?.label === 'Campaign name' && !rule.conditionCampaignNamePattern?.trim()) {
    error.conditionCampaignNamePattern = 'Please enter at least one value';
  }

  if (!rule.conditionType?.label) {
    error.conditionType = 'Please select a valid condition';
  }

  return error;
};

export const mapperBodyAutomatedRule = rule => ({
  name: rule.name,
  client_id: rule.client.value,
  budget_id: rule.budget.value,
  status: 'E',
  action_type: rule.actionType.label,
  ...(rule.conditionCampaignStatus.label
    ? rule.conditionCampaignStatus.label === 'Enabled'
      ? { condition_campaign_status: 'E' }
      : { condition_campaign_status: 'A' }
    : {}),
  ...(rule.conditionCampaignNameRule
    ? { condition_campaign_name_rule: rule.conditionCampaignNameRule.label }
    : {}),
  ...(rule.conditionCampaignNamePattern
    ? {
        condition_campaign_name_pattern:
          rule.conditionCampaignNamePattern.trim(),
      }
    : {}),
  notification_type: rule.notificationType.label,
  notification_recipients: rule.notificationRecipients,
});

export const mapperResToAutomatedRule = data => {
  const {
    id,
    name,
    client,
    budget,
    notification_type,
    action_type,
    status,
    notification_recipients,
    condition_campaign_name_pattern,
    condition_campaign_name_rule,
    condition_campaign_status,
  } = data;

  let conditionType;
  let conditionObj = {};

  // if pattern empty, rule is All campaigns
  if (
    !condition_campaign_name_pattern
  ) {
    conditionType = RULE_ACTION_CONDITIONS[0];
  } else {
    conditionType = RULE_ACTION_CONDITIONS[1];
    conditionObj.conditionCampaignNameRule = CONTAINS_OPTIONS.find(
      containOption => containOption.label === condition_campaign_name_rule,
    );
  }

  if (condition_campaign_status === 'E') {
    conditionObj.conditionCampaignStatus = ALL_CAMPAIGNS_CONDITIONS[0];
  } else if (condition_campaign_status === 'A') {
    conditionObj.conditionCampaignStatus = ALL_CAMPAIGNS_CONDITIONS[1];
  }

  return {
    id,
    name,
    client: {
      value: client.id,
      label: client.name,
    },
    budget: {
      value: budget.id,
      label: budget.name,
    },
    notificationType: RULE_NOTIFICATIONS.find(
      notiOption => notiOption.label === notification_type,
    ),
    actionType: RULE_ACTION_TYPES.find(
      actionOption => actionOption.label === action_type,
    ),
    notificationRecipients: notification_recipients ?  notification_recipients.map(item => item?.email) : [],
    conditionCampaignNamePattern: condition_campaign_name_pattern,
    conditionType,
    ...conditionObj,
  };
};

export const checkBudgetHaveCampaignsFromAccount = campaigns => {
  const accountIds = campaigns?.map(campaign => campaign.account.id);
  const setAccountIds = [...new Set(accountIds)];

  return setAccountIds.length === 1;
};

export const OAuthHandleFactory = {
  [GOOGLE]: {
    getOAuthUrl: api.GET_GOOGLE_LINK,
    sendCode: api.SEND_GOOGLE_CODE,
  },
  [FACEBOOK]: {
    getOAuthUrl: api.GET_FACEBOOK_LINK,
    sendCode: api.SEND_FACEBOOK_CODE,
  },
  [TIKTOK]: {
    getOAuthUrl: api.GET_TIKTOK_LINK,
    sendCode: api.SEND_TIKTOK_CODE,
  },
  [LINKEDIN]: {
    getOAuthUrl: api.GET_LINKED_IN_LINK,
    sendCode: api.SEND_LINKED_IN_CODE,
  },
};

export const getNewViewOptions = (currentViewOptions, newOptions) => {
  const copyViewOptionsCurrent = [...currentViewOptions];
  const startIndex = copyViewOptionsCurrent.length - 2;
  copyViewOptionsCurrent.splice(
    startIndex,
    0,
    ...(Array.isArray(newOptions) ? newOptions : [newOptions]),
  );

  const uniqueViewsOptions = copyViewOptionsCurrent.reduce((acc, current) => {
    const viewOption = acc.find(item => item.value === current.value);
    if (!viewOption) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  
  return uniqueViewsOptions;
};

export const bindingColumnsCustomViewToModal = (
  data,
  clientColumns,
  metricColumns,
) => {
  data?.columns?.forEach(column => {
    const columnType = column.split('/')[0];

    if (columnType === CLIENT) {
      const index = clientColumns.findIndex(
        clientColumn => clientColumn.key === column,
      );
      if (index !== -1) {
        clientColumns[index].checked = true;
      }
    } else if (columnType === CLIENT_METRIC) {
      const index = metricColumns.findIndex(
        metricColumn => metricColumn.key === column,
      );
      if (index !== -1) {
        metricColumns[index].checked = true;
      }
    }
  });
};

export const getMessageErrorOfMetricCustomView = (arrColumns, clients, client_metrics) => {
  let metricError = null;
  if (!arrColumns.length) {
    metricError = 'Please select clients and metrics';
  } else if (!clients.length) {
    metricError = 'Please select clients';
  } else if (!client_metrics.length) {
    metricError = 'Please select metrics';
  }

  return metricError
}

export const addCustomViewToLocalStorage = customViewBody => {
  let currentCustomViews = localStorage.getItem('views');
  const currentTimestamp = moment().unix();
  customViewBody = {
    ...customViewBody,
    id: currentTimestamp,
    name: 'Custom View',
    type: "browser"
  };

  if (!currentCustomViews) currentCustomViews = [customViewBody];
  else currentCustomViews = [...JSON.parse(currentCustomViews), customViewBody];

  localStorage.setItem('views', JSON.stringify(currentCustomViews));

  return customViewBody;
};

export const removeCustomViewFromLocalStorage = (customViewId) => {
  const currentCustomViews = JSON.parse(localStorage.getItem('views'));
  const filteredCustomViews = currentCustomViews.filter(customView => customView.id !== customViewId)

  localStorage.setItem('views', JSON.stringify(filteredCustomViews));
}

export const getCustomViewsFromLocalStorage = () => {
  const currentCustomViews = localStorage.getItem('views');
  if(currentCustomViews)
    return JSON.parse(currentCustomViews)

  return []
}

export const getCustomViewFromLocalStorageById = (id) => {
  const customViews = getCustomViewsFromLocalStorage()
  const customView = customViews.find(customView => customView.id === id)

  if(customView) {
    customView.columns = customView.columns.split(";")
  }

  return customView
}

export const updateCustomViewFromLocalStorage = (customViewBody, id) => {
  const customViews = getCustomViewsFromLocalStorage()
  const index = customViews.findIndex(customView => customView.id === id)

  if(index !== -1) {
    customViews[index] = {
      ...customViews[index],
      columns: customViewBody.columns,
    }

    localStorage.setItem('views', JSON.stringify(customViews));

    return customViews[index]
  }
}

export const getViewOptions = async (currentViewOptions) => {
  // Load from server
  const res = await apiv2.GET_LIST_CUSTOM_VIEW();
  let data = res.data;

  // Load from browser
  const dataBrowser = getCustomViewsFromLocalStorage();

  data = [...data, ...dataBrowser]

  const newViewOptions = getNewViewOptions(
    currentViewOptions,
    data.map(customView => ({
      value: customView.id,
      label: JSON.stringify(customView),
    })),
  );

  return newViewOptions
}