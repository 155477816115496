import React, { useState, useEffect, useRef } from 'react';
import Button from '../Button/Button';
import css from './CustomModal.module.css';
import CloseIcon from '../../../assets/CloseIcon.svg';

export const CustomModal = ({
  title,
  subtitle,
  children,
  footer,
  disabled,
  maxWidth,
  width,
  left,
  cancelText = 'Cancel',
  okText = 'Ok',

  onClose,
  onOk,
}) => {
  const [animation, setAnimation] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 5);
  }, []);

  const handleClose = () => {
    setAnimation(false);
    setTimeout(() => {
      if (ref && ref.current) ref.current.style.display = 'none';
      if (onClose) onClose();
    }, 500);
  };

  return (
    <div
      className={`${css.customModal} ${animation ? css.animated : ''}`}
      ref={ref}
    >
      <div className={css.background} onClick={handleClose} />
      <form
        className={`${css.container} ${animation ? css.containerAnimated : ''}`}
        style={{ maxWidth: maxWidth || undefined ,
          width: width || undefined,
          ...{left: left || {}}
        }}
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className={css.header}>
          <div>
            <div className={css.title}>{title}</div>
            {subtitle && <div className={css.subtitle}>{subtitle}</div>}
          </div>
          <div className={css.closeBtn} onClick={handleClose}>
            <img alt="close icon" src={CloseIcon} />
          </div>
        </div>
        <div className={css.main}>{children}</div>

        <div className={css.footer}>
          {footer || (
            <>
              <div className={css.cancelBtn}>
                <Button
                  styled="cancel"
                  type="button"
                  title={cancelText}
                  onClick={e => {
                    e.preventDefault();
                    handleClose();
                  }}
                />
              </div>
              <div className={css.submitBtn}>
                <Button
                  styled="deleteAcc"
                  type="submit"
                  title={okText}
                  onClick={e => {
                    e.preventDefault();
                    if (onOk) onOk();
                  }}
                  disabled={disabled || false}
                />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
