import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setViewOptions } from '../features/dashboard/dashboardSlice';
import { getViewOptions } from '../helpers';

export const useViewOptions = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { viewOptions } = useSelector(state => state.dashboard);

  const fetchCustomView = async () => {
    setIsLoading(true);
    try {
      const newViewOptions = await getViewOptions(viewOptions)
      dispatch(setViewOptions(newViewOptions));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomView();
  }, []);

  return {
    isLoading,
    error,
  };
};
