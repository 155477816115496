export const VIEW_CHILD_TAB = {
  BUDGET_PACING: {
    value: 'budget-pacing',
    label: 'Budget Pacing',
  },
  PERFORMANCE: {
    value: 'performance',
    label: 'Performance',
  },
  MODIFY_COLUMNS: {
    value: "modify-columns",
    label: "Modify Columns"
  }
};

export const SORT_ORDER =  {
  ASCEND: "asc",
  DESCEND: "desc"
}

export const VIEW_CHILD_TAB_OPTIONS = Object.values(VIEW_CHILD_TAB)

