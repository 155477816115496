import React from 'react';
import Login from './Login';
import SignUp from './SignUp';
import CreateAccount from './CreateAccount';
import ResetPassword from './ResetPassword';
import ConfirmInvitation from './ConfirmInvitation';
import Client from './Client';
import Profile from './Profile';
import AccountSecurity from './AccountSecurity';
import DeleteUser from './DeleteUser';
import css from './Modals.module.css';

const Modal = ({
  type,
  mode,
  data,
  options,
  onGoogleLogin,
  onSubmit,
  onChange,
  onCheckbox,
  onCancel,
  errors,
}) => (
  <div
    className={
      type === 'login' ||
        type === 'signup' ||
        type === 'resetPassword' ||
        type === 'createAccount'
        ? type === 'createAccount'
          ? css.createWrapper
          : css.wrapper
        : type === 'user1' ||
          type === 'user2' ||
          type === 'user3' ||
          type === 'account1' ||
          type === 'account2'
          ? css.settingsWrapper
          : css.miniWrapper
    }
  >
    {type === 'login' && (
      <Login
        data={data}
        onGoogleLogin={onGoogleLogin}
        onSubmit={onSubmit}
        onChange={onChange}
        onCheckbox={onCheckbox}
      />
    )}
    {type === 'signup' && (
      <SignUp
        data={data}
        onGoogleLogin={onGoogleLogin}
        onSubmit={onSubmit}
        onChange={onChange}
      />
    )}
    {type === 'createAccount' && (
      <CreateAccount
        data={data}
        onSubmit={onSubmit}
        onChange={onChange}
      />
    )}
    {type === 'resetPassword' && (
      <ResetPassword
        data={data}
        onSubmit={onSubmit}
        onChange={onChange}
        onCheckbox={onCheckbox}
      />
    )}
    {type === 'confirm-invitation' && (
      <ConfirmInvitation
        data={data}
        onGoogleLogin={onGoogleLogin}
        onSubmit={onSubmit}
        onChange={onChange}
        errors={errors}
      />
    )}
    {type === 'client' && (
      <Client
        mode={mode}
        data={data}
        options={options}
        onSubmit={onSubmit}
        onChange={onChange}
        onCancel={onCancel}
      />
    )}
    {type === 'user1' && <Profile />}
    {(type === 'user2' || type === 'account1') && (
      <AccountSecurity type={type} />
    )}
    {(type === 'user3' || type === 'account2') && <DeleteUser type={type} />}
  </div>
);

export default Modal;
