export const countries = [
  { value: '840', label: 'United States' },
  { value: '826', label: 'United Kingdom' },
  { value: '124', label: 'Canada' },
  { value: '036', label: 'Australia' },
  { value: '554', label: 'New Zealand' },
  { value: '372', label: 'Ireland' },
  { value: '0', label: 'Other' },
];

export const timezones = {
  'United States': [
    { value: 353, label: 'GMT-05:00: New York' },
    { value: 85, label: 'GMT-06:00: Chicago' },
    { value: 108, label: 'GMT-07:00: Denver' },
    { value: 211, label: 'GMT-07:00: Phoenix' },
    { value: 412, label: 'GMT-08:00: Los Angeles' },
    { value: 529, label: 'GMT-09:00: Anchorage' },
    { value: 255, label: 'GMT-10:00: Honolulu' },
  ],
  'United Kingdom': [{ value: 594, label: 'GMT+00:00: UK' }],
  Canada: [
    { value: 156, label: "GMT-03:30: St John's" },
    { value: 148, label: 'GMT-04:00: Halifax' },
    { value: 514, label: 'GMT-05:00: Iqaluit' },
    { value: 33, label: 'GMT-05:00: Montreal' },
    { value: 294, label: 'GMT-05:00: Toronto' },
    { value: 457, label: 'GMT-06:00: Regina' },
    { value: 462, label: 'GMT-06:00: Winnipeg' },
    { value: 484, label: 'GMT-07:00: Dawson Creek' },
    { value: 309, label: 'GMT-07:00: Edmonton' },
    { value: 164, label: 'GMT-07:00: Whitehorse' },
    { value: 46, label: 'GMT-07:00: Yellowknife' },
    { value: 542, label: 'GMT-08:00: Vancouver' },
  ],
  Australia: [
    { value: 223, label: 'GMT+08:00: Perth' },
    { value: 525, label: 'GMT+08:45: Eucla' },
    { value: 124, label: 'GMT+09:30: Darwin' },
    { value: 516, label: 'GMT+10:00: Brisbane' },
    { value: 559, label: 'GMT+10:30: Adelaide' },
    { value: 288, label: 'GMT+11:00: Hobart' },
    { value: 260, label: 'GMT+11:00: Sydney / Melbourne' },
  ],
  'New Zealand': [
    { value: 461, label: 'GMT+13:00: New Zealand' },
    { value: 500, label: 'GMT+13:45: Chatham' },
  ],
  Ireland: [{ value: 594, label: 'GMT+00:00: Ireland' }],
  Other: [
    { value: 1, label: 'GMT-12:00: Marshall Islands' },
    { value: 232, label: 'GMT-11:00: Midway Island, Samoa (US)' },
    { value: 220, label: 'GMT-10:00: Hawaii (US)' },
    { value: 529, label: 'GMT-09:00: Anchorage (US), Juneau (US)' },
    { value: 542, label: 'GMT-08:00: Los Angeles (US), Vancouver (Canada)' },
    { value: 211, label: 'GMT-07:00: Phoenix (US), Calgary (Canada)' },
    { value: 85, label: 'GMT-06:00: Chicago (US), Winnipeg (Canada)' },
    { value: 353, label: 'GMT-05:00: New York (US), Toronto (Canada)' },
    { value: 364, label: 'GMT-04:00: Santiago (Chile), La Paz (Bolivia)' },
    { value: 396, label: 'GMT-03:00: Buenos Aires (Argentina)' },
    { value: 232, label: 'GMT-02:00: Mid-Atlantic' },
    { value: 459, label: 'GMT-01:00: Azores (Portugal)' },
    { value: 509, label: 'GMT+00:00: London (UK), Dublin (Ireland)' },
    { value: 486, label: 'GMT+01:00: Berlin (Germany), Paris (France)' },
    { value: 587, label: 'GMT+02:00: Athens (Greece)' },
    { value: 441, label: 'GMT+03:00: Moscow (Russia), Nairobi (Kenya)' },
    { value: 578, label: 'GMT+04:00: Abu Dhabi (UAE), Muscat (Oman)' },
    { value: 2, label: 'GMT+05:00: Islamabad (Pakistan)' },
    { value: 264, label: 'GMT+06:00: Almaty (Kazakhstan)' },
    { value: 104, label: 'GMT+07:00: Bangkok (Thailand), Hanoi (Vietnam)' },
    { value: 223, label: 'GMT+08:00: Beijing (China), Perth (Australia)' },
    { value: 541, label: 'GMT+09:00: Tokyo (Japan), Seoul (South Korea)' },
    {
      value: 60,
      label: 'GMT+10:00: Sydney (Australia), Melbourne (Australia)',
    },
    { value: 321, label: 'GMT+11:00: Magadan (Russia), New Caledonia' },
    { value: 461, label: 'GMT+12:00: Auckland (New Zealand)' },
  ],
};

export const currencySymbols = [
  { numeric: '840', name: 'Dollar $', alpha_3: 'USD' },
  { numeric: '978', name: 'Euro €', alpha_3: 'EUR' },
  { numeric: '238', name: 'Pound £', alpha_3: 'FKP' },
  { numeric: '392', name: 'Yen ¥', alpha_3: 'JPY' },
  { numeric: '756', name: 'Franc ₣', alpha_3: 'CHF' },
  { numeric: '356', name: 'Rupee ₹', alpha_3: 'INR' },
  { numeric: '012', name: 'Dinar د.ك', alpha_3: 'DZD' },
  { numeric: '0', name: 'Other (None)', alpha_3: '' },
];
