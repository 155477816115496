import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Alert from '../Alert/Alert';
import googleLogo from '../../../assets/googleLogo.svg';
import css from './Modals.module.css';

const Login = ({ data, onGoogleLogin, onSubmit, onChange, onCheckbox }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    setIsLoading(true);

    onSubmit(e).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Button styled="google" onClick={onGoogleLogin}>
        <div className={css.googleWrapper}>
          <img className={css.googleLogo} alt="Google Logo" src={googleLogo} />
          <span className={css.googleText}>Sign In With Google</span>
        </div>
      </Button>
      <div className={css.orWrapper}>
        <hr className={css.orHr} />
        <span className={css.orText}>OR</span>
        <hr className={css.orHr} />
      </div>
      <form className={css.form} onSubmit={handleSubmit}>
        <h1 className={css.title}>Sign In</h1>
        <p className={css.formTitle}>Email</p>
        <Input
          name="email"
          type="text"
          placeholder="Enter Email"
          value={data.email}
          onChange={onChange}
          required
        />
        <p className={css.formTitle}>Password</p>
        <Input
          name="password"
          type="password"
          placeholder="Enter Password"
          value={data.password}
          onChange={onChange}
          required
        />
        {data.message && (
          <Alert
            type={data.success ? 'success' : 'error'}
            message={data.message}
          />
        )}
        <Button
          type="submit"
          styled="submit"
          title="Sign In"
          disabled={isLoading}
        />
      </form>
      <div className={css.linksWrapper}>
        <label className={css.checkboxText} htmlFor="rememberMe">
          <input
            id="rememberMe"
            className={css.checkbox}
            type="checkbox"
            checked={data.remember || false}
            onChange={onCheckbox}
          />
          Remember me
        </label>
        <Link className={css.forgotLink} to="/reset-password">
          Forgot Password?
        </Link>
      </div>
      <p className={css.footerText}>
        {`Don’t have an account? `}
        <Link className={css.regLink} to="/signup">
          Sign Up
        </Link>
      </p>
    </>
  );
};

export default Login;
