import React from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import googleLogo from '../../../assets/googleLogo.svg';
import css from './Modals.module.css';

const ConfirmInvitation = ({
  data,
  onGoogleLogin,
  onSubmit,
  onChange,
  errors,
}) => (
  <>
    <Button styled="google" onClick={onGoogleLogin}>
      <div className={css.googleWrapper}>
        <img className={css.googleLogo} alt="Google Logo" src={googleLogo} />
        <span className={css.googleText}>Sign Up With Google</span>
      </div>
    </Button>
    <div className={css.orWrapper}>
      <hr className={css.orHr}></hr>
      <span className={css.orText}>OR</span>
      <hr className={css.orHr}></hr>
    </div>
    <form className={css.form} onSubmit={onSubmit}>
      <h1 className={css.title}>Sign Up</h1>
      <p className={css.formTitle}>First Name</p>
      <Input
        name="first_name"
        type="text"
        placeholder="First Name"
        value={data.first_name}
        onChange={onChange}
        required
      />
      {errors.first_name && <p className={css.error}>{errors.first_name}</p>}

      <p className={css.formTitle}>Last Name</p>
      <Input
        name="last_name"
        type="text"
        placeholder="Last Name"
        value={data.last_name}
        onChange={onChange}
        required
      />
      {errors.last_name && <p className={css.error}>{errors.last_name}</p>}

      <p className={css.formTitle}>Password</p>
      <Input
        name="password"
        type="password"
        placeholder="Enter Password"
        value={data.password}
        onChange={onChange}
        required
      />
      {errors.password && <p className={css.error}>{errors.password}</p>}

      <Button type="submit" styled="submit" title="Join Team" />
    </form>
  </>
);

export default ConfirmInvitation;
