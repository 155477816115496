import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFormData } from '../../../features/userSettings/userSettingsSlice';
import Button from '../Button/Button';
import Input from '../Input/Input';
import api from '../../../config/apiv2';
import css from './Modals.module.css';
import { setMe } from '../../../features/me/meSlice';
import { successUpdateAccount } from '../../../helpers/notyf';

const Profile = () => {
  const { formData } = useSelector(state => state.userSettings);
  const { first_name, last_name, email } = useSelector(state => state.me);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  useEffect(() => {
    dispatch(setFormData({ first_name, last_name, email }));
  }, [first_name, last_name, email]);

  const handleChange = e => {
    setErrors(p => ({ ...p, [e.target.name]: '' }));

    dispatch(setFormData({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    api
      .EDIT_ME(formData)
      .then(() =>
        api.GET_ME().then(res => {
          dispatch(setMe(res.data));
          successUpdateAccount();
        }),
      )
      .catch(e => {
        const errors = e?.response?.data;
        const parsed = Object.entries(errors);

        let fieldsError = {
          first_name: '',
          last_name: '',
          password: '',
          other: '',
        };

        parsed.forEach(e => {
          const [field, messages] = e;
          if (
            field === 'first_name' ||
            field === 'last_name' ||
            field === 'email'
          )
            fieldsError[field] = Array.isArray(messages)
              ? messages.join('\n')
              : messages[field].join('\n');
        });

        setErrors(fieldsError);
      });
  };

  return (
    <>
      <h1 className={css.title}>Profile</h1>
      <form className={css.form}>
        <div className={css.df}>
          <div className={css.width}>
            <p className={css.formTitle}>First Name</p>
            <Input
              name="first_name"
              type="text"
              placeholder="Enter your first name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            {errors.first_name && (
              <p className={css.error}>{errors.first_name}</p>
            )}
          </div>
          <div className={css.width}>
            <p className={css.formTitle}>Last Name</p>
            <Input
              name="last_name"
              type="text"
              placeholder="Enter your last name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          {errors.last_name && <p className={css.error}>{errors.last_name}</p>}
        </div>
        <p className={css.formTitle}>Email</p>
        <Input
          name="email"
          type="text"
          placeholder="Enter your email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {errors.email && <p className={css.error}>{errors.email}</p>}

        {/* <p className={css.formTitle}>Current Password</p>
        <Input
          name="password"
          type="password"
          placeholder="Enter your current password"
          value={formData.password}
          onChange={handleChange}
          required
        /> */}
        <Button styled="submit" title="Save Changes" onClick={handleSubmit} />
      </form>
    </>
  );
};

export default Profile;
