import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import css from './NotificationBanner.module.css';

export const NotificationBanner = ({ content, onClick }) => {
  const { navigateLink = '', main = '', sub = '' } = content;
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (navigateLink.startsWith('http')) {
      window.open(navigateLink, '_blank');
    } else {
      navigate(navigateLink);
    }

    onClick && onClick();
  };

  return (
    <div
      className={css.notif}
      onClick={navigateLink && handleOnClick}
      style={navigateLink ? { cursor: 'pointer' } : {}}
    >
      <div className={css.notifImage}>i</div>
      <div className={css.notifText}>
        <b style={{ fontWeight: '900' }}>{main}</b> {sub}
      </div>
    </div>
  );
};

NotificationBanner.propTypes = {
  content: PropTypes.shape({
    main: PropTypes.string,
    sub: PropTypes.string,
    navigateLink: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

NotificationBanner.defaultProps = {
  content: {
    main: '',
    sub: '',
    navigateLink: '',
  },
  onClick: () => {},
};
