import React from 'react';
import { usePagination } from '../../hooks/usePagination';
import css from './pagination.module.css';

const Pagination = ({
  className = '',
  pageSize,
  current,
  total,
  siblingCount = 1,
  isLoading,
  onChange,
}) => {
  const paginationRange = usePagination({
    pageSize,
    current,
    total,
    siblingCount,
  });

  if (
    current < 0 ||
    pageSize < 0 ||
    total < 0 ||
    siblingCount < 0 ||
    isNaN(current) ||
    isNaN(pageSize) ||
    isNaN(total) ||
    isNaN(siblingCount)
  ) {
    return <></>;
  }

  const lastPage = paginationRange[paginationRange.length - 1];
  const firstPage = 1;

  const handleClickNext = () => {
    if (current + 1 > lastPage) return;
    onChange(current + 1);
  };

  const handleClickPrevious = () => {
    if (current - 1 < firstPage) return;
    onChange(current - 1);
  };

  const handleClickChangePage = pageNumber => {
    onChange(pageNumber);
  };

  return (
    <ul className={`${css.paginationWrapper} ${className}`}>
      <li
        key="previous"
        className={`${css.paginationItem} ${current === 1 ? css.disabled : ''}`}
        onClick={handleClickPrevious}
      >
        <div>
          <i className="fa-solid fa-chevron-left"></i>
        </div>
      </li>

      {paginationRange?.map(pageNumber => {
        if (pageNumber === '...') {
          return (
            <li
              className={`${css.paginationItem} ${css.dots}`}
              key={pageNumber}
            >
              <div>
                <i className="fas fa-ellipsis-h"></i>
              </div>
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={`${css.paginationItem} ${
              pageNumber === current ? css.selected : ''
            }`}
            onClick={() => handleClickChangePage(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}

      <li
        key="next"
        className={`${css.paginationItem} ${
          lastPage === current ? css.disabled : ''
        }`}
        onClick={handleClickNext}
      >
        <div>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
      </li>
    </ul>
  );
};

export default Pagination;
