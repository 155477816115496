import React, { useEffect, useRef, useState } from 'react';
import styles from './ProgressBarForBilling.module.css';
import Tooltip from '../reusable/Tooltip';

const ProgressBarForBilling = ({ used, active_cycle_est_used }) => {
  const [progress, setProgress] = useState({
    used: '',
    active_cycle_est_used: '',
  });
  const [width, setWidth] = useState(0)

  const usedRef = useRef()
  const activeCycleEstUsed = useRef()

  useEffect(() => {
    let procentUsed = '';
    let procentUsage = '';
    if (used <= 50000) {
      const procentUsedBasic = (100 / 50000) * used;
      procentUsed = procentUsedBasic / 5;
    }
    if (used > 50000 && used <= 250000) {
      const procentUsedProfessional = (100 / 200000) * used - 20;
      procentUsed = procentUsedProfessional / 5 + 19;
    }
    if (used > 250000 && used <= 500000) {
      const procentUsedAgency = (100 / 250000) * used - 100;
      procentUsed = procentUsedAgency / 5 + 40;
    }
    if (used > 500000 && used <= 1000000) {
      const procentUsedEnterprise = (100 / 500000) * used - 100;
      procentUsed = procentUsedEnterprise / 5 + 60;
    }
    if (used > 1000000) {
      const procentUsedPlus = (100 / 1000000) * used - 100;
      procentUsed = procentUsedPlus / 5 + 80;
    }
    //
    if (active_cycle_est_used <= 50000) {
      const procentUsageBasic = (100 / 50000) * active_cycle_est_used;
      procentUsage = procentUsageBasic / 5;
    }
    if (active_cycle_est_used > 50000 && active_cycle_est_used <= 250000) {
      const procentUsageProfessional =
        (100 / 200000) * active_cycle_est_used - 20;
      procentUsage = procentUsageProfessional / 5 + 19;
    }
    if (active_cycle_est_used > 250000 && active_cycle_est_used <= 500000) {
      const procentUsageAgency = (100 / 250000) * active_cycle_est_used - 100;
      procentUsage = procentUsageAgency / 5 + 40;
    }
    if (active_cycle_est_used > 500000 && active_cycle_est_used <= 1000000) {
      const procentUsageEnterprise =
        (100 / 500000) * active_cycle_est_used - 100;
      procentUsage = procentUsageEnterprise / 5 + 60;
    }
    if (active_cycle_est_used > 1000000) {
      const procentUsagePlus = (100 / 1000000) * active_cycle_est_used - 100;
      procentUsage = procentUsagePlus / 5 + 80;
    }
    setProgress({ used: procentUsed, active_cycle_est_used: procentUsage });
  }, [used, active_cycle_est_used, ]);

  useEffect(() => {
    if (usedRef.current && activeCycleEstUsed.current) {
      const activeCycleEstUsedWidth =
        usedRef.current.offsetWidth +
        (activeCycleEstUsed.current.offsetWidth - usedRef.current.offsetWidth) /
          2;
      setWidth(activeCycleEstUsedWidth);
    }
  }, [usedRef, activeCycleEstUsed, progress.used, progress.active_cycle_est_used]);

  return (
    <div>
      <div className={styles.progressBar}>
        <Tooltip
          text={`$${Math.round(used)}`}
          className={styles.progressTooltip}
          style={{ width: `${progress?.used}%` }}
        >
          <div className={`${styles.progressBarLayer} ${styles.firstLayer}`} ref={usedRef}/>
        </Tooltip>

        <Tooltip
          text={`$${Math.round(active_cycle_est_used)}`}
          className={styles.progressTooltip}
          style={{ width: `${progress?.active_cycle_est_used}%` }}
          styleToolTipText={{
            left: `${width}px`,
          }}
        >
          <div className={`${styles.progressBarLayer} ${styles.secondLayer}`} ref={activeCycleEstUsed}/>
        </Tooltip>

        <div
          className={`${styles.progressBarLayer} ${styles.thirdLayer}`}
          style={{ width: `${100}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBarForBilling;
