import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  triggerGetMe: false
};

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setMe: (state, action) => {
      return {...state, ...action.payload};
    },
    setTriggerGetMe: (state, action) => {
      state.triggerGetMe = action.payload
      return state
    }
  },
});

export const { setMe, setTriggerGetMe } = meSlice.actions;

export default meSlice.reducer;
