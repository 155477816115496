import React, { useState } from 'react';
import Button from '../../components/reusable/Button/Button';
import Input from '../../components/reusable/Input/Input';
import checkMark from '../../assets/check.png';
import api from '../../config/apiv2';
import css from './Team.module.css';
import { successInviteUser, error, displayErrorMessage } from '../../helpers/notyf';

export const AddUser = ({ setIsAddUser, setReload }) => {
    const [email, setEmail] = useState('');
    const [selected, setSelected] = useState(0);
    const [errorText, setErrorText] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value)
    };

    const handleSelect = (elem) => {
        setSelected(elem)
    };

    const validate = () => {
        let isValid = true;

        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (selected === 0) {
            isValid = false;
            error({ message: 'Choose permissions level' })
        };

        if (email === '') {
            isValid = false;
            setErrorText('Email field is required');
        };

        if (!emailReg.test(String(email).toLowerCase())) {
            isValid = false;
            setErrorText('Email is invalid')
        } else {
            setErrorText('');
        };

        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            api.SEND_INVITATION({
                email,
                permission_level_id: selected
            })
                .then(() => {
                    successInviteUser();
                    setIsAddUser(false);
                    setReload(prev => !prev);
                })
                .catch((response) => {
                    displayErrorMessage(response)
                })
        }
    };

    return (
        <main className={css.main}>
            <div className={css.wrapper}>
                <form>
                    <div className={css.content}>
                        <div className={css.title}>
                            Invite others to access your dashboard
                        </div>
                        <div className={css.label} style={{ margin: '2rem 0 2rem 0' }}>
                        </div>
                        <div className={css.inputContainer}>
                            {errorText && <p className={css.error}>{errorText}</p>}
                            <Input
                                name="name"
                                type="email"
                                placeholder="Enter Email Address"
                                value={email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className={css.content} style={{ margin: '2rem 0 2rem 0' }}>
                        <div className={css.tableHead}>
                            <div className={css.title}>Select Account Access Level</div>
                            <div className={css.circleWrapper} onClick={() => handleSelect(3)}>
                                <div className={css.circleContainer}>
                                    {selected === 3 && <div className={css.circle} />}
                                </div>
                                <div>Read Only</div>
                            </div>
                            <div className={css.circleWrapper} onClick={() => handleSelect(2)}>
                                <div className={css.circleContainer}>
                                    {selected === 2 && <div className={css.circle} />}
                                </div>
                                <div>Standard</div>
                            </div>
                            <div className={css.circleWrapper} onClick={() => handleSelect(1)}>
                                <div className={css.circleContainer}>
                                    {selected === 1 && <div className={css.circle} />}
                                </div>
                                <div>Admin</div>
                            </div>
                        </div>

                        <div className={css.row}>
                            <div>View reporting dashboard</div>
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                        </div>
                        <div className={css.row}>
                            <div>Add data sources, clients and budgets</div>
                            <div className={css.flexCenter} />
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                        </div>
                        <div className={css.row}>
                            <div>Add and remove team members</div>
                            <div className={css.flexCenter} />
                            <div className={css.flexCenter} />
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                        </div>
                        <div className={css.row}>
                            <div>View and edit billing information</div>
                            <div className={css.flexCenter} />
                            <div className={css.flexCenter} />
                            <div className={css.flexCenter}>
                                <img src={checkMark} alt="check" className={css.checkMark} />
                            </div>
                        </div>
                    </div>

                    <div className={css.footer}>
                        <div style={{ marginRight: 20 }}>
                            <Button
                                type="button"
                                styled="cancelBtn"
                                title="Cancel"
                                onClick={() => setIsAddUser(false)}
                            />
                        </div>
                        <Button
                            type="submit"
                            styled="addClient"
                            title="Send Invitation"
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </main>
    );
};
