import React from 'react';
import closeIconLightWhite from '../../../assets/closeIconLightWhite.svg';
import styles from './styles.module.css';

const colors = ['primary'];

const Tag = ({
  className,
  children,
  color = 'primary',
  closeIcon = false,
  onClose,
}) => {

  const handleOnClose = () => {
    closeIcon && onClose && onClose();
  };

  return (
    <div className={`${styles.wrapperTag} ${styles[color]} ${className}`}>
      <span>{children}</span>
      {closeIcon && (
        <div className={`${styles.tagCloseIcon}`} onClick={handleOnClose}>
          <img src={closeIconLightWhite} alt="closeIcon" />
        </div>
      )}
    </div>
  );
};

export default Tag;
