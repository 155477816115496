import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FilterIcon from '../../../assets/FilterIcon.svg';
import facebookMiniLogo from '../../../assets/facebookMiniLogo.svg';
import googleAdsMiniLogo from '../../../assets/googleAdsMiniLogo.svg';
import linkedInLogo from '../../../assets/linked_in_logo.webp';
import remove from '../../../assets/remove.svg';
import statusBAD from '../../../assets/statusBAD.svg';
import statusOK from '../../../assets/statusOK.svg';
import tikTokLogo from '../../../assets/tikTokLogo.webp';
import { STATUS } from '../../../contants/datasource';
import {
  addCampaigns,
  changeChoosenAccounts,
  changeChoosenCampaigns,
  changeChoosenSources,
  changeSetCampaignTab,
  changeTableChoosenData,
} from '../../../features/createBudget/createBudgetSlice';
import Button from '../Button/Button';
import css from './Tables.module.css';

const DataSourcesTable = ({
  data,
  filter,
  type,
  isLoading = false,
  clearSearchData,
  setFilter,
  onChangeDataSource,
  handleDisconnect,
  handleAuth,
}) => {
  const {
    choosenSources,
    choosenAccounts,
    choosenCampaigns,
    tableChoosenData,
  } = useSelector(state => state.createBudget);
  const [tableData, setTableData] = useState([]);
  const [filterCampaign, setFilterCampaign] = useState(false);

  const ref = useRef(null);
  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterCampaign(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setTableData([]);

    if (type === 'accounts' && choosenSources.length > 0) {
      setTableData(data);
      return;
    } else if (type === 'campaigns' && choosenAccounts.length > 0) {
      setTableData(data);
      return;
    } else if (type === 'confirmBudget') {
      setTableData(choosenCampaigns);
      return;
    }

    setTableData(data);
  }, [choosenSources, choosenAccounts, choosenCampaigns, data, type]);

  const handleClick = (el, e) => {
    //clearSearchData?.();
    type !== 'campaigns' && clearSearchData?.();

    if (type === 'budgetData') {
      onChangeDataSource && onChangeDataSource();

      dispatch(changeSetCampaignTab('accounts'));
      dispatch(changeChoosenSources([el]));

      // Reset campaigns when choose new source
      const isValidChoosenSources = choosenSources[0]?.id;
      if (isValidChoosenSources) {
        if (choosenSources[0]?.id !== el?.id) {
          dispatch(changeChoosenAccounts([]));
          dispatch(addCampaigns([]));
        }
      } else if (choosenAccounts[0]?.id) {
        dispatch(changeChoosenAccounts([]));
        dispatch(addCampaigns([]));
      }
    } else if (type === 'accounts') {
      dispatch(changeSetCampaignTab('campaigns'));
      dispatch(changeChoosenAccounts([el]));
      setTableData([]);
    } else if (type === 'campaigns') {
      if (
        tableChoosenData.find(
          campaign => +campaign.campaign_id === +el.campaign_id,
        )
      ) {
        dispatch(
          changeTableChoosenData(
            tableChoosenData.filter(
              campaign => +campaign.campaign_id !== +el.campaign_id,
            ),
          ),
        );
      } else dispatch(changeTableChoosenData([...tableChoosenData, el]));
    }
  };

  const handleRemove = (id, e) =>
    dispatch(
      changeChoosenCampaigns(
        choosenCampaigns.filter(campaign => +campaign.id !== +id),
      ),
    );

  const handleCheckAll = () => {
    if (isAllChecked) {
      dispatch(
        changeTableChoosenData(
          choosenCampaigns.filter(
            el => !tableData.find(data => data.id === el.id),
          ),
        ),
      );
    } else {
        const addedUniqueCampaignIdSet = new Set();
        const newCheckedCampaigns = tableData.filter(campaign => {
          if (!addedUniqueCampaignIdSet.has(campaign.campaign_id) && !isChoosenCampaign(campaign)) {
            addedUniqueCampaignIdSet.add(campaign.campaign_id);
            return true;
          }
          return false;
        });

        dispatch(changeTableChoosenData(newCheckedCampaigns))
    }
  };

  const handleFilterCampaign = status => {
    setFilter(status.toLowerCase());
  };

  const renderNotificationWhenCreateBudget = () => {
    let message = '';
    if (
      choosenAccounts.length === 0 &&
      tableData.length === 0 &&
      type === 'campaigns' &&
      !isLoading &&
      !params.id &&
      isNaN(params.id)
    ) {
      message =
        'Please select an account from the previous tab to view its campaigns.';
    }

    if (message) {
      return (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center' }}>
            <span className={css.noty}>{message}</span>
          </td>
        </tr>
      );
    }

    return <></>;
  };

  const renderDataSourceLogo = type => {
    let alt = '';
    let src = '';

    switch (type) {
      case 'Google Ads':
        alt = 'Google Logo';
        src = googleAdsMiniLogo;
        break;
      case 'Facebook Ads':
        alt = 'Facebook Logo';
        src = facebookMiniLogo;
        break;
      case 'TikTok Ads':
        alt = 'TikTok Logo';
        src = tikTokLogo;
        break;
      case 'LinkedIn Ads':
        alt = 'LinkedIn Logo';
        src = linkedInLogo;
        break;
      default:
    }

    return (
      <img
        className={`${css.icon} ${css.logoDataSource}`}
        alt={alt}
        src={src}
      />
    );
  };

  const isChoosenCampaign = campaign =>
    choosenCampaigns?.find(_campaign => _campaign.campaign_id === campaign.campaign_id);

  const isDisabledCampaign = campaign =>
    isChoosenCampaign(campaign) ||
    tableChoosenData?.find(
      _campaign =>
        _campaign.campaign_id === campaign.campaign_id && _campaign.id !== campaign.id,
    );

  const isCheckedCampaign = campaign => 
    tableChoosenData?.find(
      _campaign =>
        _campaign.campaign_id === campaign.campaign_id
    ) || isChoosenCampaign(campaign)

  // in table choosen data or in selected campaign table
  const isAllChecked = tableData?.every(campaign => isCheckedCampaign(campaign))
  
  // in selected campaign table or in choosen data but exclude campaign is selected first
  const isAllDisabled = tableData?.every(campaign => isDisabledCampaign(campaign))

  return (
    <div
      className={`${
        type === 'budgetData'
          ? css.dataSourceWrapperBudgetData
          : css.dataSourceWrapper
      }`}
    >
      <table className={css.table}>
        <thead
          className={`${css.thead} ${css.dashboardThead} ${
            isLoading ? css.loadingBar : ''
          }`}
        >
          <tr className={css.trHead}>
            {type === 'campaigns' && (
              <th className={css.campaignsSelect}>
                <input
                  className={css.checkbox}
                  type="checkbox"
                  disabled={isAllDisabled}
                  checked={isAllChecked}
                  onChange={handleCheckAll}
                />
              </th>
            )}

            <th className={`${css.table_header} ${css.th1}`}>Source</th>
            {type === 'data' || type === 'budgetData' ? (
              <th className={`${css.table_header} ${css.th1}`}>Status</th>
            ) : (
              <></>
            )}
            {type === 'accounts' && (
              <th className={`${css.table_header} ${css.th2}`}>Account</th>
            )}
            {type !== 'confirmBudget' && type !== 'campaigns' && (
              <th
                className={`${css.table_header} ${
                  type === 'accounts'
                    ? css.th1
                    : type === 'campaigns'
                    ? css.th1
                    : css.th4
                }`}
              >
                Connected Account
              </th>
            )}
            {type === 'data' && (
              <th className={`${css.table_header} ${css.th3}`}>Resync</th>
            )}
            {type === 'campaigns' && (
              <>
                <th className={`${css.table_header} ${css.th1}`}>
                  Connected Account
                </th>
                <th className={`${css.table_header} ${css.th1}`}>Account</th>
                <th
                  className={`${css.table_header} ${css.th1}`}
                  onClick={() => {
                    setFilterCampaign(prev => !prev);
                  }}
                  ref={ref}
                >
                  <div className={css.tHeadCampaign}>
                    <img src={FilterIcon} alt="Filter Icon" />
                    <span>Campaign</span>
                    {filterCampaign && (
                      <div className={css.campaignFilterWrapper}>
                        <div className={css.campaignFilter}>
                          <div onClick={() => handleFilterCampaign('enabled')}>
                            <img
                              className={css.icon}
                              alt="Status Enabled"
                              src={statusOK}
                            />
                            <span>Enabled</span>
                          </div>
                          <div onClick={() => handleFilterCampaign('paused')}>
                            <img
                              className={css.icon}
                              src={statusBAD}
                              alt="Status Paused"
                            />
                            <span>Paused</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              </>
            )}
            {type === 'confirmBudget' && (
              <>
                <th className={`${css.table_header} ${css.th1}`}>Campaign</th>
                <th className={`${css.table_header} ${css.th1}`}>Actions</th>
              </>
            )}
            {type === 'data' && (
              <th className={`${css.table_header} ${css.th3}`}>Remove</th>
            )}
          </tr>
        </thead>
        <tbody>
          {renderNotificationWhenCreateBudget()}
          <tr>
            <td colSpan={type === 'campaigns' || type === 'data' ? 5 : 4}>
              <div>
                <table className={css.table2}>
                  <tbody>
                    {!isLoading &&
                      tableData.length > 0 &&
                      tableData.map(el => (
                        <tr
                          className={`${css.tr} ${
                            type === 'campaigns' && isDisabledCampaign(el)
                              ? `${css.choosen} ${css.disabled}`
                              : ''
                          }`}
                          key={el.id || el.source_id}
                          onClick={
                            type === 'campaigns' &&
                            choosenCampaigns.findIndex(
                              source => +source.id === +el.id,
                            ) !== -1
                              ? () => {}
                              : handleClick.bind(this, el)
                          }
                        >
                          {type === 'campaigns' && (
                            <td className={css.campaignsSelect}>
                              <input
                                className={css.checkbox}
                                type="checkbox"
                                checked={isCheckedCampaign(el)}
                                disabled={isDisabledCampaign(el)}
                                onChange={handleClick.bind(this, el)}
                              />
                            </td>
                          )}

                          <td className={css.td1}>
                            {renderDataSourceLogo(el.type)}
                          </td>

                          {type === 'data' || type === 'budgetData' ? (
                            <td className={css.td1}>
                              {el.status === 'active' ? (
                                <img
                                  className={css.icon}
                                  alt="Status OK"
                                  src={statusOK}
                                />
                              ) : (
                                <img
                                  className={css.icon}
                                  alt="Status BAD"
                                  src={statusBAD}
                                />
                              )}
                            </td>
                          ) : (
                            <></>
                          )}

                          {type === 'accounts' && (
                            <>
                              <td className={css.td2}>{el.name}</td>
                              <td className={css.td2}>{el.source?.email}</td>
                            </>
                          )}

                          {type !== 'confirmBudget' && type !== 'campaigns' && (
                            <td
                              className={
                                type === 'accounts'
                                  ? css.td1
                                  : type === 'campaigns'
                                  ? css.td1
                                  : css.td4
                              }
                            >
                              {el.email}
                            </td>
                          )}

                          {type === 'data' && (
                            <>
                              <td className={`${css.td3} ${css.tdResync}`}>
                                {el.status !== STATUS.ACTIVE ? (
                                  <Button
                                    styled="reconnect"
                                    title="Resync"
                                    onClick={handleAuth.bind(
                                      this,
                                      el.type?.split(' ')[0]?.toLowerCase(),
                                    )}
                                  />
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className={css.td3}>
                                <Button
                                  styled="disconnect"
                                  title="Disconnect"
                                  onClick={handleDisconnect.bind(this, el.id)}
                                />
                              </td>
                            </>
                          )}
                          {type === 'campaigns' && (
                            <>
                              <td className={css.td1}>
                                {el.account?.source?.email}
                              </td>

                              <td className={css.td1}>{el.account?.name}</td>
                              <td className={css.td1}>
                                <div className={css.tdImageWrapper}>
                                  {el.status === 'enabled' ? (
                                    <img
                                      className={css.icon}
                                      alt="Status OK"
                                      src={statusOK}
                                    />
                                  ) : (
                                    <img
                                      className={css.icon}
                                      alt="Status BAD"
                                      src={statusBAD}
                                    />
                                  )}
                                  <span className={css.tdImageSpan}>
                                    {el.name}
                                  </span>
                                </div>
                              </td>
                            </>
                          )}

                          {type === 'confirmBudget' && (
                            <>
                              <td className={css.td1}>
                                <div className={css.tdImageWrapper}>
                                  {el.status === 'enabled' ? (
                                    <img
                                      className={css.icon}
                                      alt="Status OK"
                                      src={statusOK}
                                    />
                                  ) : (
                                    <img
                                      className={css.icon}
                                      alt="Status BAD"
                                      src={statusBAD}
                                    />
                                  )}
                                  {el.name}
                                </div>
                              </td>
                              <td className={css.td1}>
                                <img
                                  className={css.icon}
                                  alt="Status OK"
                                  src={remove}
                                  onClick={handleRemove.bind(this, el.id)}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DataSourcesTable;
