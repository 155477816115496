import { React, useRef, useState } from 'react';
import Card from '../../../../../components/reusable/Card';
import Select from '../../../../../components/reusable/Select/Select';
import {
  RULE_ACTION_CONDITIONS,
  RULE_ACTION_TYPES,
} from '../../../../../contants/automatedRules';
import { useClickOutside } from '../../../../../hooks/useClickOutside';
import { useSelectPositionLeft } from '../../../../../hooks/useSelectPositionLeft';
import AllCampaignCondition from '../AllCampaignCondition';
import CampaignName from '../CampaignName';
import css from './../../../../../components/reusable/Modals/Modals.module.css';
import styles from './styles.module.css';
import { components } from 'react-select';

const customValueContainer =
  rule =>
  ({ children, ...props }) => {
    const tempChildren = [...children];
    
    if (props.hasValue) {
      const value = props.getValue()[0].label;
      const infoConditionInclude = getInfoConditionRule(value, rule);

      const innerText = `${value} ${
        infoConditionInclude.length
          ? `: ${infoConditionInclude.join('; ')}`
          : ''
      }`;

      tempChildren[0] = <span className={styles.valueSelect}>{innerText}</span>;
    }

    return (
      <components.ValueContainer {...props}>
        {props.hasValue ? tempChildren : children}
      </components.ValueContainer>
    );
  };

const getInfoConditionRule = (value, rule) => {
  const campaignStatus = rule.conditionCampaignStatus?.label;
  const campaignRule = rule.conditionCampaignNameRule?.label;
  const pattern = rule.conditionCampaignNamePattern
    ? rule.conditionCampaignNamePattern.trim()
    : rule.conditionCampaignNamePattern;
  let infoConditionInclude = [];
  if (
    (value === 'All campaigns' || value === 'Campaign name') &&
    campaignStatus
  ) {
    infoConditionInclude =
      campaignStatus === 'Enabled' ? ['enabled'] : ['enabled, paused'];

    if (value === 'Campaign name') {
      if (campaignRule && pattern)
        infoConditionInclude.push(`${campaignRule}: ${pattern}`);
    }
  }

  return infoConditionInclude;
};

const RuleAction = ({ rule, error, setError, setRule }) => {
  const { conditionType, actionType } = rule;

  const [conditionSelected, setConditionSelected] = useState(null);

  const ref = useRef(null);

  const wrapperSelectRef = useRef(null);
  const selectPosLeft = useSelectPositionLeft(wrapperSelectRef);

  useClickOutside(ref, e => {
    if (e.target.classList.contains('modal__option')) return;
    
    if(error?.conditionCampaignNamePattern) {
      setError({
        ...error,
        conditionCampaignNamePattern: null
      })
    }
    setConditionSelected(null);
  });

  const handleChangeCondition = option => {
    setConditionSelected(option.label);

    let resetConditionObj = {};

    if (option.label !== conditionType?.label) {
      if (option.label === 'All campaigns') {
        resetConditionObj = {
          conditionCampaignStatus: '',
          conditionCampaignNamePattern: '',
          conditionCampaignNameRule: '',
        };
      } else {
        resetConditionObj = {
          conditionCampaignStatus: '',
        };
      }
    }

    setRule(prev => ({
      ...prev,
      conditionType: option,
      ...resetConditionObj,
    }));
  };

  const handleChangeActionType = option => {
    setRule(prev => ({
      ...prev,
      actionType: option,
    }));
  };

  return (
    <Card title="Action">
      <div className={styles.wrapperBodyRuleAction}>
        <div ref={wrapperSelectRef}>
          <p className={styles.labelInput}>Action Type</p>
          {error?.actionType && (
            <p className={css.error}>{error?.actionType}</p>
          )}
          <Select
            name="actionType"
            placeHolder="Select action type"
            styleMenuPortal={{
              color: 'red',
              left: selectPosLeft,
            }}
            options={RULE_ACTION_TYPES}
            onChange={handleChangeActionType}
            value={actionType}
          />
        </div>

        <div>
          <p className={styles.labelInput}>Condition</p>
          {error?.conditionType && (
            <p className={css.error}>{error?.conditionType}</p>
          )}
          <div ref={ref} className={styles.selectConditionContainer}>
            <Select
              name="conditionType"
              placeHolder="Create condition"
              className={styles.selectCondition}
              styleMenuPortal={{
                left: selectPosLeft,
              }}
              styleInput={{
                margin: '0',
                minWidth: "0",
                height: "0"
              }}
              customValueContainer={customValueContainer(rule)}
              options={RULE_ACTION_CONDITIONS}
              onChange={handleChangeCondition}
              value={conditionType}
            />
            {conditionSelected === 'All campaigns' && (
              <AllCampaignCondition rule={rule} setRule={setRule} />
            )}
            {conditionSelected === 'Campaign name' && (
              <CampaignName
                rule={rule}
                error={error}
                setError={setError}
                setRule={setRule}
                setConditionSelected={setConditionSelected}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RuleAction;
