import ProgressBar from '@ramonak/react-progress-bar';
import React, { useEffect, useMemo, useState } from 'react';
import pause from '../../../../assets/pause.svg';
import stylesTable from '../../../../components/reusable/Tables/Tables.module.css';
import { parceBudget, parcePercentage } from '../../../../helpers/parceBudget';
import Table from '../../Table';
import { formater } from '../DashboardTableRows/functions';
import BudgetNameCell from '../BudgetPacingTable/BudgetNameCell';
import BudgetAmountCell from '../BudgetPacingTable/BudgetAmountCell';

const BudgetDashboardTable = ({
  data,
  type,
  budgetToEdit,
  error,
  budgetIdSelected,
  metrics,

  isLoading,
  isDefaultCurrentCycleView,
  isEditHover,
  isEdit,
  isAmountHover,
  isAmount,

  handleClickClient,
  handleClickBudget,
  handleChangeBudgetName,
  handleClickSaveBtn,
  handleCloseBudgetNameOrAmountModal,
  handleChangeBudgetNameOrAmount,

  handleOnMouseEnter,
  handleOnMouseLeave,
  handleOnAmountMouseEnter,
  handleOnAmountMouseLeave,

  handleOpenBudgetAmountModal,
  handleOpenBudgetNameModal,
}) => {
  const getColumns = useMemo(() => {
    // Define columns based on metrics
    const columns = [
      {
        title: 'Budget',
        dataIndex: 'budget_name',
        key: 'budget_name',
        fixed: 'left',
        sorter: true,
        className: `${stylesTable.tdNoWidth} ${stylesTable.pr}`,
        style: ({ row_type }, _) => ({
          ...(row_type === 'total'
            ? { cursor: 'not-allowed' }
            : { cursor: 'pointer' }),
        }),
        onMouseEnterDataCell: (render, _) => {
          render.row_type === 'total'
            ? null
            : handleOnMouseEnter(render.budget_id);
        },
        onMouseLeaveDataCell: ({ row_type }, _) => {
          row_type === 'total' ? null : handleOnMouseLeave();
        },
        render: (record, _) => {
          return (
            <BudgetNameCell
              record={record}
              budgetToEdit={budgetToEdit}
              error={error}
              budgetIdSelected={budgetIdSelected}
              isEdit={isEdit}
              isAmount={isAmount}
              isEditHover={isEditHover}
              isAmountHover={isAmountHover}
              handleChangeBudgetName={handleChangeBudgetName}
              handleCloseBudgetNameOrAmountModal={
                handleCloseBudgetNameOrAmountModal
              }
              handleClickSaveBtn={handleClickSaveBtn}
              handleOpenBudgetNameModal={handleOpenBudgetNameModal}
              handleClickBudget={handleClickBudget}
            />
          );
        },
      },
      {
        title: 'Client',
        dataIndex: 'client_name',
        key: 'client_name',
        fixed: 'left',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        style: { color: '#1649EC', cursor: 'pointer' },
        onClickDataCell: (render, _) => {
          if (handleClickClient) handleClickClient(render);
        },
      },
      {
        title: 'Budget Amount',
        dataIndex: 'budget_amount',
        key: 'budget_amount',
        sorter: true,
        className: `${stylesTable.td} ${stylesTable.pr}`,
        onMouseEnterDataCell: ({ row_type, budget_id }, _) => {
          row_type === 'total' ? null : handleOnAmountMouseEnter(budget_id);
        },
        onMouseLeaveDataCell: ({ row_type }, _) => {
          row_type === 'total' ? null : handleOnAmountMouseLeave();
        },
        render: (record, _) => {
          return (
            <BudgetAmountCell
              record={record}
              budgetToEdit={budgetToEdit}
              budgetIdSelected={budgetIdSelected}
              isEdit={isEdit}
              isAmount={isAmount}
              isEditHover={isEditHover}
              isAmountHover={isAmountHover}
              handleChangeBudgetAmount={handleChangeBudgetNameOrAmount}
              handleClickSaveBtn={handleClickSaveBtn}
              handleCloseBudgetNameOrAmountModal={
                handleCloseBudgetNameOrAmountModal
              }
              handleOpenBudgetAmountModal={handleOpenBudgetAmountModal}
            />
          );
        },
      },
      {
        title: 'Spend',
        dataIndex: 'spend',
        key: 'spend',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: (record, _) => {
          return isDefaultCurrentCycleView ? (
            <div className={stylesTable.progressBarContainer}>
              <ProgressBar
                completed={+record.spend}
                maxCompleted={+record.budget_amount}
                customLabel={parceBudget(record.spend, record.currency, 0)}
                borderRadius="0"
                height="3rem"
                bgColor={formater('primaryColor', +record.trend, type)}
                baseBgColor={formater('secondaryColor', +record.trend, type)}
                labelAlignment="left"
                labelColor="var(--white)"
                labelSize="1.2rem"
                labelClassName={stylesTable.progressBarLabel}
                width="13rem"
              />
              {record?.status === 'paused' && (
                <img className={stylesTable.pause} alt="pause" src={pause} />
              )}
            </div>
          ) : (
            <>{parceBudget(record.spend, record.currency, 0)}</>
          );
        },
      },
      {
        title: 'Trend',
        dataIndex: 'trend',
        key: 'trend',
        sorter: true,
        className: ({ trend, completed_status }, _) => {
          return `${stylesTable.td} ${formater(
            'trendColor',
            trend,
            completed_status ?? '',
          )}`;
        },
        render: ({ trend, completed_status }, _) => {
          return formater('trendName', trend, completed_status ?? '');
        },
      },
      {
        title: 'Remaining Budget',
        dataIndex: 'remaining_budget',
        key: 'remaining_budget',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ remaining_budget, currency }, _) => {
          return parceBudget(remaining_budget, currency);
        },
      },
      {
        title: 'Ideal Daily Spend',
        dataIndex: 'ideal_daily_spend',
        key: 'ideal_daily_spend',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ ideal_daily_spend, currency }, _) => {
          return parceBudget(ideal_daily_spend, currency);
        },
      },
      {
        title: "Yesterday's Spend",
        dataIndex: 'yesterday_spend',
        key: 'yesterday_spend',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ yesterday_spend, currency }, _) => {
          return parceBudget(yesterday_spend, currency);
        },
      },
      {
        title: '3 Day Avg. Spend',
        dataIndex: 'tree_days_avg_spend',
        key: 'tree_days_avg_spend',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ tree_days_avg_spend, currency }, _) => {
          return parceBudget(tree_days_avg_spend, currency);
        },
      },
      {
        title: 'Forecast Spend',
        dataIndex: 'forecast_spend',
        key: 'forecast_spend',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ forecast_spend, currency }, _) => {
          return parceBudget(forecast_spend, currency);
        },
      },
      {
        title: 'Lost IS (Budget)',
        dataIndex: 'lost_is_budget_percentage',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ lost_is_budget_percentage }, _) => {
          return parcePercentage(lost_is_budget_percentage);
        },
      },
      {
        title: 'Conversions',
        dataIndex: 'conversions',
        key: 'conversions',
        sorter: true,
        className: stylesTable.tdNoWidth,
        render: ({ conversions }, _) => {
          return parceBudget(conversions, '', 0);
        },
      },
      {
        title: 'CPA',
        dataIndex: 'cpa',
        key: 'cpa',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: ({ cpa, currency }, _) => {
          return parceBudget(cpa, currency);
        },
      },
      {
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: ({ revenue, currency }, _) => {
          return parceBudget(revenue, currency);
        },
      },
      {
        title: 'ROAS',
        dataIndex: 'roas',
        key: 'roas',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: ({ roas }, _) => {
          return parcePercentage(roas);
        },
      },
      {
        title: '7 Day CPA',
        dataIndex: 'seven_days_cpa',
        key: 'seven_days_cpa',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: ({ seven_days_cpa, currency }, _) => {
          return parceBudget(seven_days_cpa, currency);
        },
      },
      {
        title: '7 Day ROAS',
        dataIndex: 'seven_days_roas',
        key: 'seven_days_roas',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: ({ seven_days_roas }, _) => {
          return parcePercentage(seven_days_roas);
        },
      },
      {
        title: '7 Day CPA change',
        dataIndex: 'seven_days_cpa_change',
        sorter: true,
        className: ({ seven_days_cpa_change }, _) => {
          return `${stylesTable.tdNoWidth} ${formater(
            'cpa/roas_change',
            seven_days_cpa_change,
          )}`;
        },
        render: ({ seven_days_cpa_change }, _) => {
          return parcePercentage(seven_days_cpa_change);
        },
      },
      {
        title: '7 Day ROAS change',
        dataIndex: 'seven_days_roas_change',
        sorter: true,
        className: ({ seven_days_roas_change }, _) => {
          return `${stylesTable.tdNoWidth} ${formater(
            'roas_change',
            seven_days_roas_change,
          )}`;
        },
        render: ({ seven_days_roas_change }, _) => {
          return parcePercentage(seven_days_roas_change);
        },
      },
      {
        title: 'Forecast Conv.',
        dataIndex: 'forecast_conversions',
        sorter: true,
        className: stylesTable.tdNoWidth,
        render: ({ forecast_conversions, currency }, _) => {
          return parceBudget(forecast_conversions, currency);
        },
      },
      {
        title: 'Forecast Revenue',
        dataIndex: 'forecast_revenue',
        sorter: true,
        className: stylesTable.tdNoWidth,
        render: ({ forecast_revenue, currency }, _) => {
          return parceBudget(forecast_revenue, currency);
        },
      },
    ];

    const filteredColumns = columns.filter(column =>
      metrics.includes(column.dataIndex),
    );

    return filteredColumns;
  }, [metrics]);

  return (
    <Table
      columns={getColumns}
      dataSource={data}
      isLoading={isLoading}
      unSortLastElement
      rowClassNameProvider={record => {
        const baseClassName = stylesTable.tr;
        const additionalClassName =
          record?.row_type === 'total' ? stylesTable.trTotal : '';

        return `${baseClassName} ${additionalClassName}`;
      }}
    />
  );
};

export default BudgetDashboardTable;
