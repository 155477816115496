import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';

export const useSelectPositionLeft = ref => {
  const [posLeftOfMenuPortal, setPosLeftOfMenuPortal] = useState();

  const windowSize = useWindowSize();

  useEffect(() => {
    if (ref.current) {
      const boudingClientRect =
        ref.current.getBoundingClientRect();
      setPosLeftOfMenuPortal(boudingClientRect.left);
    }
  }, [ref, windowSize]);

  return `calc(${posLeftOfMenuPortal}px - 30rem)`;
};
