import React from 'react';
import arrowDown from '../../../assets/arrowDownBlack.svg';
import arrowUp from '../../../assets/arrowUpBlack.svg';
import cross from '../../../assets/cross.svg';
import Button from '../Button/Button';
import css from './Tabs.module.css';

const Tabs = ({
  tab,
  handleTabChange,
  type,
  handleShowHide,
  isShowing,
  handleClearFilters,
  handleAddNewBudget,
  getSelectedText,
  isShowingFilters,
  isShowAddOrRemoveCampaignBtn,
  isShowAddNewBudgetBtn,
  handleChange,
  hideTabs = false,
}) => {
  const formater = (tabTextStyle, tabText, activeTab) => {
    if (tabTextStyle) {
      if (type === 'budget') return css.tabText;
      if (type === 'campaign') return css.tabTextM;
      if (type === 'dashboard') return css.tabTextL;
      if (type === 'dashboard2') return css.tabTextXL;
      if (type === 'graph') return css.graphTabs;
    } else if (tabText) {
      if (type === 'budget')
        return [
          {
            value: 'monthly',
            label: 'Monthly',
          },
          {
            value: 'weekly',
            label: 'Weekly',
          },
          {
            value: 'custom',
            label: 'Custom',
          },
        ];
      if (type === 'campaign')
        return [
          {
            value: 'data',
            label: 'Data Sources',
          },
          {
            value: 'accounts',
            label: 'Accounts',
          },
          {
            value: 'campaigns',
            label: 'Campaigns',
          },
        ];
      if (type === 'dashboard')
        return [
          {
            value: 'clients',
            label: 'Clients',
          },
          {
            value: 'budgets',
            label: 'Budgets',
          },
          {
            value: 'campaigns',
            label: 'Campaigns',
          },
        ];
      if (type === 'dashboard2')
        return [
          {
            value: 'budget-pacing',
            label: 'Budget Pacing',
          },
          {
            value: 'performance',
            label: 'Performance',
          },
        ];
      if (type === 'graph')
        return [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ];
    } else if (activeTab) {
      if (type === 'budget') {
        if (activeTab === 1) return tab === 'monthly';
        if (activeTab === 2) return tab === 'weekly';
        if (activeTab === 3) return tab === 'custom';
      }
      if (type === 'campaign') {
        if (activeTab === 1) return tab === 'data';
        if (activeTab === 2) return tab === 'accounts';
        if (activeTab === 3) return tab === 'campaigns';
      }
      if (type === 'dashboard') {
        if (activeTab === 1) return tab === 'clients';
        if (activeTab === 2) return tab === 'budgets';
        if (activeTab === 3) return tab === 'campaigns';
      }
      if (type === 'dashboard2') {
        if (activeTab === 1) return tab === 'budget-pacing';
        if (activeTab === 2) return tab === 'performance';
      }
      if (type === 'graph') {
        if (activeTab === 1) return tab === 'day';
        if (activeTab === 2) return tab === 'week';
        if (activeTab === 3) return tab === 'month';
      }
    }
  };


  return (
    <div
      className={`${css.header} ${
        type === 'dashboard' || type === 'dashboard2' || type === 'graph'
          ? css.bn
          : ''
      } ${type === 'graph' ? css.jce : ''} ${
        type === 'dashboard' || type === 'dashboard2' ? css.noMargin : ''
      }`}
    >
      {!hideTabs && (
        <>
          <div
            className={`${css.tab} ${type === 'graph' ? css.graphTab : ''} ${
              formater(null, null, 1)
                ? type === 'graph'
                  ? ''
                  : css.activeB
                : ''
            }`}
            onClick={handleTabChange.bind(this, formater(null, true)[0].value)}
          >
            <span
              className={`${formater(true)} ${
                formater(null, null, 1)
                  ? type === 'budget' || type === 'graph'
                    ? css.blue
                    : css.active
                  : ''
              }`}
            >
              {formater(null, true)[0].label}
            </span>
          </div>
          <div
            className={`${css.tab} ${type === 'graph' ? css.graphTab : ''} ${
              formater(null, null, 2)
                ? type === 'graph'
                  ? ''
                  : css.activeB
                : ''
            }`}
            onClick={handleTabChange.bind(this, formater(null, true)[1].value)}
          >
            <span
              className={`${formater(true)} ${
                formater(null, null, 2)
                  ? type === 'budget' || type === 'graph'
                    ? css.blue
                    : css.active
                  : ''
              }`}
            >
              {formater(null, true)[1].label}
            </span>
          </div>
        </>
      )}

      {type !== 'dashboard2' && (
        <div
          className={`${css.tab} ${type === 'graph' ? css.graphTab : ''} ${
            formater(null, null, 3) ? (type === 'graph' ? '' : css.activeB) : ''
          }`}
          onClick={handleTabChange.bind(this, formater(null, true)[2].value)}
        >
          <span
            className={`${formater(true)} ${
              formater(null, null, 3)
                ? type === 'budget' || type === 'graph'
                  ? css.blue
                  : css.active
                : ''
            }`}
          >
            {formater(null, true)[2].label}
          </span>
        </div>
      )}

      {type === 'dashboard' && !!isShowingFilters && getSelectedText() && (
        <>
          <div className={css.selectedContainer}>
            <span className={css.selectedText}>{getSelectedText()}</span>

            <img src={cross} onClick={handleClearFilters} />
          </div>

          {isShowAddOrRemoveCampaignBtn && (
            <Button
              styled="addSource"
              onClick={handleChange}
              style={{
                width: 200,
                marginLeft: 13,
              }}
            >
              <div className={css.btnWrapper}>
                <span className={css.addText}>Add or Remove Campaigns</span>
              </div>
            </Button>
          )}

          {isShowAddNewBudgetBtn && (
            <Button
              styled="addSource"
              onClick={handleAddNewBudget}
              style={{
                marginLeft: 12,
              }}
            >
              <div className={css.btnWrapper}>
                <span className={css.addText}>Add New Budget</span>
              </div>
            </Button>
          )}
        </>
      )}

      {type === 'dashboard' && (
        <img
          src={isShowing ? arrowUp : arrowDown}
          onClick={handleShowHide}
          className={css.arrow}
        />
      )}
    </div>
  );
};

export default Tabs;
