import React, { useRef, useState } from 'react';
import arrowDown from '../../../../assets/arrowDown.svg';
import statusBAD from '../../../../assets/statusBAD.svg';
import statusOK from '../../../../assets/statusOK.svg';
import apiv2 from '../../../../config/apiv2';
import {
  RULE_ACTIONS,
  STATUS_RULES,
} from '../../../../contants/automatedRules';
import {
  error,
  removeSuccessAutomatedRule,
  successUpdateStatusAutomatedRule,
} from '../../../../helpers/notyf';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import RuleActionCombobox from './RuleActionCombobox';
import style from './styles.module.css';
import { CustomModal } from '../../Modals/CustomModal';

const RuleCell = ({
  record,
  idSelected,
  dataSource = [],
  setDataSource,
  onClickRuleStatus,
}) => {
  const { id, name, status } = record;

  const ref = useRef();
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  const isSelected = id === idSelected;

  useClickOutside(ref, () => {
    onClickRuleStatus && onClickRuleStatus(null);
  });

  const handleClickRuleStatus = () => {
    onClickRuleStatus && onClickRuleStatus(id);
  };

  const handleClickActionRule = async type => {
    const indexRowSelected = dataSource.findIndex(rule => rule.id === id);

    switch (type) {
      case RULE_ACTIONS.PAUSE:
      case RULE_ACTIONS.ENABLED:
        if (indexRowSelected !== -1) {
          await handleUpdateStatusRule(type, indexRowSelected);
        }
        break;
      case RULE_ACTIONS.REMOVE:
        setIsOpenRemoveModal(true);
        onClickRuleStatus(null);
        break;
      default:
        return;
    }
  };

  const handleUpdateStatusRule = async (type, indexRowSelected) => {
    try {
      const currentStatus = dataSource[indexRowSelected].status;
      const statusUpdate =
        type === RULE_ACTIONS.ENABLED
          ? STATUS_RULES.ENABLE
          : STATUS_RULES.PAUSE;

      onClickRuleStatus(null);
      if (currentStatus !== statusUpdate) {
        await apiv2.UPDATE_STATUS_AUTOMATED_RULE(id, {
          status: statusUpdate,
        });

        dataSource[indexRowSelected].status = statusUpdate;
        setDataSource([...dataSource]);
        successUpdateStatusAutomatedRule(statusUpdate);
      }
    } catch (err) {
      error(err);
    }
  };

  const handleRemoveAutomatedRule = async idRule => {
    try {
      const removedArr = dataSource.filter(rule => rule.id !== idRule);
      await apiv2.DELETE_AUTOMATED_RULE(idRule);

      setDataSource(removedArr);
      removeSuccessAutomatedRule();
    } catch (err) {
      error(err);
    } finally {
      setIsOpenRemoveModal(false);
    }
  };

  return (
    <>
      <div className={style.ruleCell}>
        <div
          ref={id === idSelected ? ref : null}
          className={style.ruleStatus}
          onClick={handleClickRuleStatus}
        >
          {status === STATUS_RULES.ENABLE ? (
            <img
              className={style.ruleStatusIcon}
              src={statusOK}
              alt="Status Enabled"
            />
          ) : (
            <img
              className={style.ruleStatusIcon}
              src={statusBAD}
              alt="Status Paused"
            />
          )}
          <div className={isSelected ? '' : style.dropDownIcon}>
            <img src={arrowDown} />
          </div>
          {isSelected && (
            <RuleActionCombobox
              className={style.ruleCbbWrapper}
              onChangeAction={handleClickActionRule}
            />
          )}
        </div>
        <span>{name}</span>
      </div>
      {isOpenRemoveModal && (
        <CustomModal
          title="Remove Automated Rule?"
          subtitle="Deleting an automated rule will permanently remove it from your dashboard and prevent it from running again. This action is irreversible."
          maxWidth={650}
          onClose={setIsOpenRemoveModal}
          okText="Remove"
          onOk={() => {
            handleRemoveAutomatedRule(id);
          }}
        />
      )}
    </>
  );
};

export default RuleCell;
