import React from 'react';
import Button from '../Button/Button';
import css from './Popups.module.css';
import close from '../../../assets/close.png'

const DeleteAccount = ({ type, onClose, onClick }) => (
  <>
    <div className={css.wrap}>
      <h1 className={css.title2}>
        {type === 'account' ? 'Delete Account?' : 'Delete User Account?'}
      </h1>
      <img src={close} className={css.closeBtn} onClick={onClose} />
    </div>
    <p className={css.text}>
      {`Are you sure you want to continue? Deleting ${type === 'account' ? 'an account' : 'a user account'
        } cannot be undone.`}
    </p>
    <div className={css.btnsWrapper}>
      <button className={css.close} onClick={onClose} >Cancel</button>
      <Button
        styled="delete"
        title={type === 'account' ? 'Delete Account' : 'Delete User Account'}
        onClick={onClick}
      />
    </div>
  </>
);

export default DeleteAccount;
