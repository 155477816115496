import { useEffect, useState } from 'react';
import apiv2 from '../config/apiv2';
import { RESOURCES } from '../contants/resources';

export const useTable = ({ resource }, [...deps]) => {
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDataSource = async ({ resource }) => {
    let data = [];
    let res;

    setIsLoading(true);
    try {
      switch (resource) {
        case RESOURCES.automated_rules:
          res = await apiv2.GET_AUTOMATED_RULES();
          data = res.data;
          break;
        default:
          return;
      }

      setDataSource(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataSource({ resource });
  }, [resource, ...deps]);

  return {
    dataSource,
    isLoading,
    error,
  };
};
