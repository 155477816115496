import React from 'react';
import styles from './styles.module.css';

const TextArea = ({
  type,
  placeholder,
  value,
  onChange,
  name,
  readOnly,
  required,
  className
}) => (
  <textarea
    name={name}
    type={type}
    className={`${styles.textarea} ${className}`}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    readOnly={readOnly}
    required={required}
  />
);

export default TextArea;
