import { createSlice } from '@reduxjs/toolkit';
import { VIEW_CHILD_TAB_OPTIONS } from '../../contants/dashboard';

const initialState = {
  tab: 'clients',
  tab2: 'budget-pacing',
  previousViewTabs: "",
  graphTab: 'day',
  graphData: {},
  tableData: [],
  //TODO: add arrays for every tab in dashboard
  isDatesPicker: false,
  startDate: '',
  endDate: '',
  currentDate: 'current_cycle',
  isAllChecked: false,
  removeBudget: false,
  removeClient: false,
  budgetsToRemove: [],
  clientsToRemove: [],
  filterElems: [],
  viewOptions: [...VIEW_CHILD_TAB_OPTIONS]
};

const createDashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
    changeTab2: (state, action) => {
      state.tab2 = action.payload;
    },
    changeTableData: (state, action) => {
      const { payload: data } = action;
      if (data.length) {
        const index = data.findIndex(item => item.row_type === 'total');
        
        if (index !== -1) {
          const element = data.splice(index, 1)[0];
          data.push(element);
        }
      }

      state.tableData = data;
    },
    changeGraphTab: (state, action) => {
      state.graphTab = action.payload;
    },
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    changeIsDatesPicker: (state, action) => {
      state.isDatesPicker = action.payload;
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    changeEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    changeIsAllChecked: (state, action) => {
      state.isAllChecked = action.payload;
    },
    changeCurrentDate: (state, action) => {
      state.currentDate = action.payload;
    },
    setRemoveBudget: (state, action) => {
      state.removeBudget = action.payload;
    },
    setRemoveClient: (state, action) => {
      state.removeClient = action.payload;
    },
    setBudgetsToRemove: (state, action) => {
      state.budgetsToRemove = action.payload;
    },
    setClientsToRemove: (state, action) => {
      state.clientsToRemove = action.payload;
    },
    setFilterElems: (state, action) => {
      state.filterElems = action.payload;
    },
    setPreviousTabs: (state, action) => {
      state.previousViewTabs = action.payload
    },
    setViewOptions: (state, action) => {
      state.viewOptions = action.payload
    }
  },
});

export const {
  changeTab,
  changeTab2,
  changeTableData,
  changeGraphTab,
  setGraphData,
  changeIsDatesPicker,
  changeStartDate,
  changeEndDate,
  changeIsAllChecked,
  changeCurrentDate,
  setRemoveBudget,
  setRemoveClient,
  setBudgetsToRemove,
  setClientsToRemove,
  setFilterElems,
  setPreviousTabs,
  setViewOptions
} = createDashboardSlice.actions;

export default createDashboardSlice.reducer;
