import { CLIENT, CLIENT_METRIC } from '../contants/datasource';

export const getMetricsByType = (customViewRaw, type) => {
  const customView = JSON.parse(customViewRaw);

  const arrowFunc = column =>
    type === CLIENT
      ? column.split('/')[0] === CLIENT
      : column.split('/')[0] !== CLIENT;

  const result = customView.columns.split(';').filter(arrowFunc);

  return { customView, result };
};

// columns: string split by ;
export const getMetricsOfAllSection = columns => {
  const arrColumns = columns ? columns.split(';') : [];
  const clients = arrColumns.filter(column => column.split('/')[0] === CLIENT);
  const client_metrics = arrColumns.filter(
    column => column.split('/')[0] === CLIENT_METRIC,
  );

  return { arrColumns, clients, client_metrics };
};

export const extractTypeCustomView = type => ({
  prefix: type.split('-')[0],
  customViewRaw: type.split('-')[1],
});

export const clientSort = (a, b) => {
  if (a.client_name < b.client_name) {
    return -1;
  }
  if (a.client_name > b.client_name) {
    return 1;
  }
  return 0;
};

export const budgetSort = (a, b) => {
  if (a.client_name < b.client_name) {
    return -1;
  }
  if (a.client_name > b.client_name) {
    return 1;
  }

  if (a.budget_name < b.budget_name) {
    return -1;
  }
  if (a.budget_name > b.budget_name) {
    return 1;
  }

  return 0;
};

export const campaignSort = (a, b) => {
  if (a.client_name < b.client_name) {
    return -1;
  }
  if (a.client_name > b.client_name) {
    return 1;
  }

  if (a.budget_name < b.budget_name) {
    return -1;
  }
  if (a.budget_name > b.budget_name) {
    return 1;
  }

  if (a.campaign_name < b.campaign_name) {
    return -1;
  }
  if (a.campaign_name > b.campaign_name) {
    return 1;
  }

  return 0;
};

export const resetScrollLeftDashboardTable = () => {
  const dashboard = document.getElementById('dashboardTableBody');
  dashboard.scrollLeft = 0;
};

export const formatUnixTimestamp = timestamp => {
  const date = new Date(timestamp * 1000);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);

  return formatter.format(date);
};

export const isReadOnlyOrPaymentValid = (notifications, isReadOnly) => {
  return isReadOnly || checkIfPaymentIsValid(notifications)
}

export const checkIfPaymentIsValid = notifications => {
  let isValid = true

  const addedPmAndSubscription =  notifications?.payment_method_attached && notifications?.subscription_status
  const subscriptionCanceledAndTrialExpired = notifications?.subscription_status == "canceled" && notifications?.trial_period_expired
  const paymentIssue = notifications?.payment_error && notifications?.payment_remaining_days < 0

  if (addedPmAndSubscription) {
    if (paymentIssue || subscriptionCanceledAndTrialExpired) {
      isValid = false
    }
  } else {
    isValid = !notifications?.trial_period_expired
  }

  return isValid;
};
