import React, { useLayoutEffect, useRef, useState } from 'react';

const TableDataCell = ({
  index,
  zIndex,
  className,
  fixed,
  style,
  width = "180px",
  minWidth = "180px",
  children = <></>,
  isLoading,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const ref = useRef();
  const [position, setPosition] = useState(0);

  useLayoutEffect(() => {
    if(fixed && ref.current) {
      const tHeads = Array.from(
        document.querySelectorAll('#dashboardTableBody table th'),
      );

      const sumWidth = Array.from(tHeads)
        .slice(0, index)
        .reduce((sum, currTh) => sum + currTh.clientWidth, 0);
      setPosition(sumWidth);
      // setPosition(ref.current.offsetLeft)
    }
  },[ref.current, isLoading])


  return (
    <td
      ref={ref}
      className={`${className}`}
      style={{
        width: width,
        minWidth: minWidth,
        ...style,
        ...(fixed ? { position: 'sticky', zIndex } : {}),
        [fixed]: position,
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </td>
  );
};

export default TableDataCell;
