import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRefresh: false,
};

const createRootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    toggleRefresh: (state, action) => {
      state.isRefresh = !state.isRefresh;
    },
  },
});

export const { toggleRefresh } = createRootSlice.actions;

export default createRootSlice.reducer;
