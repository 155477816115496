import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import edit from '../../../../assets/edit.svg';
import css from '../../../../components/reusable/Tables/Tables.module.css';
import { parceBudget } from '../../../../helpers/parceBudget';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import Button from '../../Button/Button';
import Input from '../../Input/Input';

const BudgetAmountCell = ({
  record,
  budgetToEdit,
  budgetIdSelected,

  isEdit,
  isAmount,
  isEditHover,
  isAmountHover,

  handleChangeBudgetAmount,
  handleCloseBudgetNameOrAmountModal,
  handleClickSaveBtn,
  handleOpenBudgetAmountModal,
}) => {
  const popupBudgetAmountRef = useRef(null);
  const rowParentRef = useRef(null);

  const [isOpenBudgetAmount, setIsOpenBudgetAmount] = useState(false);

  useClickOutside(popupBudgetAmountRef, () => {
    if (isOpenBudgetAmount) {
      if (rowParentRef.current) {
        rowParentRef.current.style.zIndex = 0;
      }

      handleCloseBudgetNameOrAmountModal();
    }
  });

  useEffect(() => {
    if (isEdit === false && budgetIdSelected === null) {
      if (rowParentRef.current) {
        rowParentRef.current.style.zIndex = 0;
      }

      setIsOpenBudgetAmount(false);
      return;
    }

    if (isEdit && isAmount && record.budget_id === budgetIdSelected) {
      setIsOpenBudgetAmount(true);
      return;
    }
  }, [isEdit, isAmount, record.budget_id, budgetIdSelected]);

  useLayoutEffect(() => {
    const posBottomParent = document
      .getElementById('dashboardTableBody')
      .getBoundingClientRect().bottom;
    if (popupBudgetAmountRef.current && posBottomParent && isOpenBudgetAmount) {
      const posBottomDropdown =
        popupBudgetAmountRef.current.getBoundingClientRect().bottom;
      if (posBottomDropdown - posBottomParent > 40) {
        popupBudgetAmountRef.current.style.top = '-18rem';
      }
    }
  }, [isOpenBudgetAmount]);

  return (
    <>
      <span>{parceBudget(record.budget_amount, record.currency)}</span>
      <>
        <img
          className={css.edit}
          style={{
            opacity: `${
              isEditHover === record.budget_id && isAmountHover ? 1 : 0
            }`,
          }}
          alt="edit"
          src={edit}
          onClick={e => {
            if (handleOpenBudgetAmountModal) {
              const rowParent = e.target.parentElement.parentElement;
              rowParent.style.zIndex = 1;
              rowParentRef.current = rowParent;

              handleOpenBudgetAmountModal(record.budget_id);
            }
          }}
        />
        {isOpenBudgetAmount && (
          <div className={css.editContainer} ref={popupBudgetAmountRef}>
            <h2 className={css.editTitle}>
              {isAmount ? 'Budget Amount' : 'Budget Name'}
            </h2>
            <Input
              name="amount"
              styled="edit"
              value={budgetToEdit?.amount || ''}
              onChange={handleChangeBudgetAmount}
            />
            <div className={css.editBtns}>
              <Button
                styled="edit"
                title="Cancel"
                onClick={handleCloseBudgetNameOrAmountModal}
              />
              <Button styled="edit" title="Save" onClick={handleClickSaveBtn} />
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default BudgetAmountCell;
