import css from '../Tables.module.css';

export const formater = (value, item, type) => {
  if (value === 'primaryColor') {
    if (
      item === 0 ||
      (item * 100 >= 1 && item * 100 <= 9) ||
      (item * 100 >= -9 && item * 100 <= -1)
    )
      return 'var(--green2)';
    if (
      (item * 100 >= 10 && item * 100 <= 15) ||
      (item * 100 >= -15 && item * 100 <= -10)
    )
      return 'var(--green3)';
    if (
      (item * 100 >= 16 && item * 100 <= 25) ||
      (item * 100 >= -25 && item * 100 <= -16)
    )
      return 'var(--orange)';
    if (item * 100 >= 26 || item * 100 <= -26) return 'var(--red)';
  }
  if (value === 'secondaryColor') {
    if (
      item === 0 ||
      (item * 100 >= 1 && item * 100 <= 9) ||
      (item * 100 >= -9 && item * 100 <= -1)
    )
      return 'var(--green2Opcty)';
    if (
      (item * 100 >= 10 && item * 100 <= 15) ||
      (item * 100 >= -15 && item * 100 <= -10)
    )
      return 'var(--green3Opcty)';
    if (
      (item * 100 >= 16 && item * 100 <= 25) ||
      (item * 100 >= -25 && item * 100 <= -16)
    )
      return 'var(--orangeOpcty)';
    if (item * 100 >= 26 || item * 100 <= -26) return 'var(--redOpcty)';
  }
  if (value === 'trendName') {
    if (type === 'Completed') return 'Completed';
    if (+item === 0) return 'On Target';
    if (item * 100 >= 1) return `Over ${(item * 100).toFixed()}%`;
    if (item * 100 <= -1) return `Under ${(item * 100).toFixed()}%`;
  }
  if (value === 'trendColor') {
    if (type === 'Сompleted') return css.green;
    if (
      +item === 0 ||
      (item * 100 >= 1 && item * 100 <= 9) ||
      (item * 100 >= -9 && item * 100 <= -1)
    )
      return css.green;
    if (
      (item * 100 >= 10 && item * 100 <= 15) ||
      (item * 100 >= -15 && item * 100 <= -10)
    )
      return css.lightGreen;
    if (
      (item * 100 >= 16 && item * 100 <= 25) ||
      (item * 100 >= -25 && item * 100 <= -16)
    )
      return css.orange;
    if (item * 100 >= 26 || item * 100 <= -26) return css.red;
  }
  if (value === 'cpa/roas_change') {
    if (+item >= 0 && +item < 6) return css.orange;
    if (+item >= 6) return css.red;
    if (+item < 0) return css.green;
  }
  if (value === 'roas_change') {
    if (+item >= 0) return css.green;
    if (+item <= -6) return css.red;
    if (+item > -6 && +item < 0) return css.orange;
  }
  if (value === 'key') {
    if (type === 'clients-budget-pacing') return item.client_id;
    if (type === 'budgets-budget-pacing') return item.budget_id;
    if (type === 'campaigns-performance')
      return {
        campaign_id: item?.campaign_id,
        budget_id: item?.budget_id,
      };
  }
};

export const sortData = (tableData, sortKey, reverse, type) => {
  if (!sortKey || !tableData.length) return tableData;

  const lastElement = tableData[tableData.length - 1];
  const dataToSort = tableData.slice(0, tableData.length - 1);
  
  let sortedData = dataToSort.sort((a, b) => {
    return a[sortKey] > b[sortKey] ? 1 : -1;
  });

  if (reverse) sortedData.reverse();
  if (type === "string") sortedData.reverse();

  return [...sortedData, lastElement];
};

export const sortDataInTable = (
  tableData,
  sortKey,
  reverse,
  unSortLastElement,
  funcSorter
) => {
  if (!sortKey || !tableData.length) return tableData;

  let dataToSort = [...tableData];
  const dataType = typeof tableData[0][sortKey];

  // check data type
  if (unSortLastElement) {
    dataToSort = tableData.slice(0, tableData.length - 1);
  }
  
  // get function sort 
  const funcSort = typeof funcSorter === 'function' ? funcSorter : (a, b) => {
    return a[sortKey] > b[sortKey] ? 1 : -1;
  }

  let sortedData = dataToSort.sort(funcSort);
  if (reverse) sortedData.reverse();
  if (dataType === 'string') sortedData.reverse();

  if (unSortLastElement) {
    const lastElement = tableData[tableData.length - 1];
    return [...sortedData, lastElement];
  }

  return [...sortedData];
};