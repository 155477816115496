import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFilterElems } from '../../features/dashboard/dashboardSlice';
import Button from '../reusable/Button/Button';
import css from './TableMenu.module.css';
import { setStatusUpdateBudget, setUpdatedBudgetOrCampaignId } from '../../features/createBudget/createBudgetSlice';

const TableMenu = ({
  setRemoveCampaignsModal,
  setRemoveClientsModal,
  setRemoveBudgetsModal,
}) => {
  const { tableData, tab, tab2 } = useSelector(state => state.dashboard);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleEdit = () => {
    
    if (tab === 'clients') {
      const id = tableData.find(el => el.isChecked).client_id;
      navigate(`/create-client/${id}`);
    }
    if (tab === 'budgets') {
      dispatch(setStatusUpdateBudget(true))
      const id = tableData.find(el => el.isChecked).budget_id;
      dispatch(setUpdatedBudgetOrCampaignId(id))
      navigate(`/create-budget/budget/${id}`);
    }
    if (tab === 'campaigns') {
      const id = tableData.find(el => el.isChecked).budget_id;
      dispatch(setUpdatedBudgetOrCampaignId(id))
      navigate(`/create-budget/campaigns/${id}`);
    }
  };

  const handleRemoveModal = () => {
    if (tab === 'budgets') setRemoveBudgetsModal(true);
    if (tab === 'clients') setRemoveClientsModal(true);
    if (tab === 'campaigns') setRemoveCampaignsModal(true);
  };

  const handleFilterBySelection = () => {
    dispatch(setFilterElems(tableData.filter(el => el.isChecked)))
    // setFilterElems(tableData.filter(el => el.isChecked));
  };

  const getIsShown = () => {
    let isShow = true;

    const checked = tableData.filter(el => el.isChecked);

    if (tab !== 'campaigns') {
      isShow = tableData.filter(el => el.isChecked).length < 2;
    } else {
      checked.forEach(el => {
        if (el.budget_id !== checked[0].budget_id) {
          isShow = false;
        }
      });
    }

    return isShow;
  };

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <span className={css.text}>{`${
          tableData.filter(el => el.isChecked && el.row_type !== "total").length
        } Selected`}</span>
      </div>
      <Button
        styled="tableMenu"
        title="Filter By Selection"
        onClick={handleFilterBySelection}
      />
      {getIsShown() && (
        <Button styled="tableMenu" title="Edit" onClick={handleEdit} />
      )}
      <Button styled="tableMenuBN" title="Remove" onClick={handleRemoveModal} />
    </div>
  );
};

export default TableMenu;
