import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../config/api';
import { CREATE_BUDGET_AT_CLIENT_STEP_PATH } from '../../contants';
import { PAGE_SIZES } from '../../contants/pageSizes';
import {
  addAccounts,
  addCampaigns,
  addFormData,
  addSources,
  changeChoosenAccounts,
  changeChoosenCampaigns,
  changeChoosenSources,
  changeSetCampaignTab,
  changeTableChoosenData,
  resetState,
} from '../../features/createBudget/createBudgetSlice';
import { notyfError } from '../../helpers/notyf';
import { useDebounce } from '../../hooks/useDebounce';
import DataSources from '../../pages/DataSources/DataSources';
import Pagination from '../Pagination';
import Button from '../reusable/Button/Button';
import ConfirmCreateBudget from '../reusable/Popups/ConfirmCreateBudget';
import Select from '../reusable/Select/Select';
import Table from '../reusable/Tables/Table';
import Tabs from '../reusable/Tabs/Tabs';
import css from './SelectCampaigns.module.css';

const SelectCampaigns = ({
  handleConfirm,
  isNewDataSource,
  isAddOrRemoveCampaigns,
}) => {
  const tableRef = useRef(null);

  const params = useParams();
  const {
    choosenSources,
    choosenAccounts,
    choosenCampaigns,
    formData,
    setCampaignsTab,
    sources,
    accounts,
    campaigns,
    tableChoosenData,
    isUpdateBudget,
    isProcessSyncDataSource,
    isActiveCampaignsFetchTrigger,
  } = useSelector(state => state.createBudget);

  const [isLoading, setIsLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showError, setShowError] = useState(false);
  const [statusCampaign, setStatusCampaign] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [accountIds, setAccountIds] = useState([]);

  const [dataPaginationCampaignTab, setDataPaginationCampaignTab] = useReducer(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
    }),
    {
      currentPage: 1,
      totalItems: 0,
    },
  );

  const [dataPaginationAccountTab, setDataPaginationAccountTab] = useReducer(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
    }),
    {
      currentPage: 1,
      totalItems: 0,
    },
  );

  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(searchTerm, 700);

  const dispatch = useDispatch();
  const sourcesNames = ['google'];

  useEffect(() => {
    if (params.id && !isNaN(params.id)) {
      setIsLoading(true);
      api
        .GET_BUDGET(params.id)
        .then(data => {
          let dataToSet = {
            ...formData,
            id: +data.id,
            amount: +data.amount,
            name: data.name,
            google_budget: data.google_budget,
            type: data.type,
            client: { value: data.client.id, label: data.client.name },
            renew_type: data.renew_type,
          };

          const accounts = data?.campaigns.map(campaign => campaign?.account);
          const uniqueAccounts = accounts?.filter(
            (account, index, self) =>
              index ===
              self.findIndex(_account => _account?.id === account?.id),
          );

          // show sync manual button
          if (uniqueAccounts.length === 1) {
            dispatch(changeChoosenAccounts(uniqueAccounts));
            dispatch(changeChoosenSources([uniqueAccounts[0]?.source]));
          }

          setAccountIds(uniqueAccounts.map(account => account?.id));
          setIsLoading(false);

          if (!isUpdateBudget) {
            dispatch(addFormData({ ...dataToSet }));
          }
          dispatch(changeChoosenCampaigns(data.campaigns));
        })
        .catch(err => {
          const { response } = err;
          if (response?.status === 404) {
            notyfError("Budget isn't existed");
            navigate(CREATE_BUDGET_AT_CLIENT_STEP_PATH, { replace: true });
          }
        });
    }
  }, [params.id]);

  useEffect(() => {
    sources.length === 0 &&
      Promise.all(
        sourcesNames.map(source => {
          if (source === 'google') {
            return api.GET_GOOGLE_DATA_SOURCES().then(res => {
              if (res.status === 'OK') return res;
            });
          }
        }),
      ).then(res => {
        const concated = res
          .filter(el => !!el)
          .reduce(
            (previousValue, currentValue) => previousValue.concat(currentValue),
            [],
          );

        dispatch(addSources(concated));
      });

    if (!isAddOrRemoveCampaigns) dispatch(changeSetCampaignTab('data'));

    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    setDataPaginationAccountTab({
      ...dataPaginationAccountTab,
      currentPage: 1,
    });
    setDataPaginationCampaignTab({
      ...dataPaginationCampaignTab,
      currentPage: 1,
    });
  }, [pageSize]);

  useEffect(() => {
    // Reset page size and current tab when choose new source or campaign or switch tab;

    setPageSize(PAGE_SIZES[0]);
    setDataPaginationAccountTab({
      ...dataPaginationAccountTab,
      currentPage: 1,
    });
    setDataPaginationCampaignTab({
      ...dataPaginationCampaignTab,
      currentPage: 1,
    });
  }, [
    choosenSources,
    choosenCampaigns,
    setCampaignsTab,
    statusCampaign,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const isFetchingAccountData = setCampaignsTab === 'campaigns';
    if (isFetchingAccountData || isProcessSyncDataSource) return;

    setIsLoading(true);
    const idDataSource = choosenSources.length ? choosenSources[0].id : '';

    Promise.all(
      sourcesNames.map(account => {
        if (account === 'google') {
          return api
            .GET_ACCOUNTS(idDataSource, {
              page: dataPaginationAccountTab.currentPage,
              size: pageSize.value,
              search: debouncedSearchTerm,
            })
            .then(res => {
              const { results, count } = res;

              dataPaginationAccountTab.totalItems = count;
              let data = results.map(el => ({
                ...el,
                ...(idDataSource ? { data_source_id: idDataSource } : {}),
              }));

              return data;
            })
            .catch(error => console.log({ error }));
        }
      }),
    )
      .then(res => {
        const concated = res[0]
          .filter(el => !!el)
          .reduce(
            (previousValue, currentValue) => previousValue.concat(currentValue),
            [],
          );

        setSearchResults(concated);
        dispatch(addAccounts(concated));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    choosenSources,
    dataPaginationAccountTab,
    setCampaignsTab,
    debouncedSearchTerm,
    isProcessSyncDataSource,
  ]);

  useEffect(() => {
    const idAccount = choosenAccounts.length ? choosenAccounts[0].id : '';
    const idFetchingCampaigns =
      setCampaignsTab === 'accounts' || (!idAccount && !accountIds.length);

    if (idFetchingCampaigns) return;
    setIsLoading(true);
    Promise.all(
      sourcesNames.map(campaigns => {
        if (campaigns === 'google') {
          const params = {
            page: dataPaginationCampaignTab.currentPage,
            size: pageSize.value,
            ...(debouncedSearchTerm !== '' && { search: debouncedSearchTerm }),
            ...(statusCampaign !== '' && { status_filter: statusCampaign }),
            ...(accountIds.length &&
              !idAccount && { accounts_filter: accountIds.join(',') }),
          };

          return api
            .GET_CAMPAIGNS(idAccount, params)
            .then(res => {
              const { results, count } = res;
              dataPaginationCampaignTab.totalItems = count;

              return results;
            })
            .catch(error => []);
        }
      }),
    )
      .then(res => {
        const concated = res[0].reduce(
          (previousValue, currentValue) => previousValue.concat(currentValue),
          [],
        );

        setSearchResults(concated);
        dispatch(addCampaigns(concated));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    choosenSources,
    choosenAccounts,
    statusCampaign,
    dataPaginationCampaignTab,
    setCampaignsTab,
    debouncedSearchTerm,
    isActiveCampaignsFetchTrigger,
    isProcessSyncDataSource
  ]);

  const handleTabChange = (tab, e) => {
    setSearchTerm('');
    setSearchResults([]);
    setStatusCampaign('');
    dispatch(changeSetCampaignTab(tab));
  };

  const handleSelectCampaigns = () => {
    dispatch(
      changeChoosenCampaigns([
        ...new Map(
          [...choosenCampaigns, ...tableChoosenData].map(item => [
            item.id,
            item,
          ]),
        ).values(),
      ]),
    );
    setSearchTerm('');

    dispatch(changeTableChoosenData([]));
  };

  const handleSearch = e => {
    setSearchTerm(e?.target?.value ?? '');
  };

  const handleConfirmError = () => {
    setShowError(true);
  };

  const handleChangeSelectPageSize = option => {
    setPageSize(option);
  };

  useEffect(() => {
    showError && setShowError(false);
  }, [choosenCampaigns]);

  useEffect(() => {
    tableRef && tableRef.current.scrollTo(0, 0);
  }, [setCampaignsTab]);

  const getDataPagination = () => {
    let totalItems = null;
    let currentPage = null;

    if (setCampaignsTab === 'accounts') {
      totalItems = dataPaginationAccountTab.totalItems;
      currentPage = dataPaginationAccountTab.currentPage;
    } else if (setCampaignsTab === 'campaigns') {
      totalItems = dataPaginationCampaignTab.totalItems;
      currentPage = dataPaginationCampaignTab.currentPage;
    }

    return { totalItems, currentPage };
  };

  const handlePageChange = page => {
    if (setCampaignsTab === 'accounts') {
      setDataPaginationAccountTab({
        ...dataPaginationAccountTab,
        currentPage: page,
      });
    } else if (setCampaignsTab === 'campaigns') {
      setDataPaginationCampaignTab({
        ...dataPaginationCampaignTab,
        currentPage: page,
      });
    }
  };

  const isShowPaginationComponent = setCampaignsTab !== 'data';
  const { totalItems, currentPage } = getDataPagination();

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <div className={css.tabWrapper}>
          <Tabs
            tab={setCampaignsTab}
            handleTabChange={handleTabChange}
            type="campaign"
          />
        </div>

        {setCampaignsTab === 'data' ? (
          <DataSources type="budget" handleSearch={handleSearch} />
        ) : (
          <div className={css.accountsTableWrapper}>
            <Table
              handleSelectCampaigns={handleSelectCampaigns}
              isLoading={isLoading}
              data={
                debouncedSearchTerm.length > 0 || statusCampaign !== ''
                  ? searchResults
                  : setCampaignsTab === 'accounts'
                  ? accounts
                  : campaigns
              }
              type={setCampaignsTab}
              handleSearch={handleSearch}
              search={searchTerm}
              filter={statusCampaign}
              setFilter={setStatusCampaign}
            />
          </div>
        )}
        {isShowPaginationComponent && (
          <div className={`${css.paginationContainer}`}>
            <Select
              options={PAGE_SIZES}
              value={pageSize}
              defaultValue={pageSize}
              placeHolder={pageSize.value}
              className={`${css.selectPageSize}`}
              styleSingleValue={{
                margin: '0 .8rem',
              }}
              stylePlaceholder={{
                margin: '0 .8rem',
              }}
              styleInput={{
                margin: '0 .8rem',
              }}
              styleMenu={{
                width: '90%',
                right: '320%',
                position: 'absolute',
                maxHeight: '100px',
                overflowY: 'auto',
              }}
              onChange={handleChangeSelectPageSize}
            />
            <Pagination
              pageSize={pageSize.value}
              current={currentPage}
              total={totalItems}
              onChange={handlePageChange}
              className={css.pagination}
            />
          </div>
        )}
      </div>

      <div className={css.selected}>
        <div className={css.selectedWrapper}>
          <div className={css.confirmTableWrapper} ref={tableRef}>
            <ConfirmCreateBudget />
          </div>
        </div>
        <div className={css.btn}>
          {showError && (
            <p className={css.error}>Please add some campaigns first</p>
          )}
          <Button
            styled="addClient"
            title={params?.id ? 'Update' : 'Create'}
            onClick={
              choosenCampaigns?.length ? handleConfirm : handleConfirmError
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SelectCampaigns;
