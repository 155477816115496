import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createClientReducer from '../features/createClient/createClientSlice';
import createBudgetReducer from '../features/createBudget/createBudgetSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import userSettingsReduser from '../features/userSettings/userSettingsSlice';
import dataSourceReducer from '../features/dataSource/dataSourceSlice';
import meReducer from '../features/me/meSlice';
import rootReducer from '../features/root/rootSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const reducer = combineReducers({
  me: meReducer,
  createClient: createClientReducer,
  createBudget: createBudgetReducer,
  dataSource: dataSourceReducer,
  dashboard: dashboardReducer,
  userSettings: userSettingsReduser,
  root: rootReducer,
})

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["createBudget"]
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);