import React, { useMemo } from 'react';
import facebookMiniLogo from '../../../../assets/facebookMiniLogo.svg';
import googleAdsMiniLogo from '../../../../assets/googleAdsMiniLogo.svg';
import linkedInLogo from '../../../../assets/linked_in_logo.webp';
import statusBAD from '../../../../assets/statusBAD.svg';
import statusOK from '../../../../assets/statusOK.svg';
import tikTokLogo from '../../../../assets/tikTokLogo.webp';
import stylesTable from '../../../../components/reusable/Tables/Tables.module.css';
import { parceBudget, parcePercentage } from '../../../../helpers/parceBudget';
import { formatNumber } from '../../../../utils/formatNumber';
import Table from '../../Table';
import { formater } from '../DashboardTableRows/functions';
import styles from './styles.module.css';

const CampaignPerformanceTable = ({
  data,
  type,
  metrics,
  isLoading,
  isAllChecked,

  handleClickClient,
  handleClickBudget,
  handleCheckAll,
  handleCheckOne,
}) => {
  const getColumns = useMemo(() => {
    // Define columns based on metrics
    const columns = [
      {
        title: (
          <input
            className={stylesTable.checkbox}
            type="checkbox"
            checked={isAllChecked}
            onChange={handleCheckAll}
          />
        ),
        classNameHeaderCell: stylesTable.thInput,
        className: stylesTable.td,
        width: '50px',
        minWidth: '50px',
        fixed: 'left',
        enableColumnResizing: false,
        render: (record, _) => {
          return (
            record.row_type !== 'total' && (
              <input
                className={stylesTable.checkbox}
                type="checkbox"
                checked={record.isChecked}
                onChange={() => {
                  if (handleCheckOne)
                    handleCheckOne(formater('key', record, type));
                }}
              />
            )
          );
        },
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        fixed: 'left',
        sorter: true,
        className: `${stylesTable.td}`,
        style: ({ row_type }, _) => ({
          ...(row_type === 'total'
            ? { cursor: 'not-allowed' }
            : { cursor: 'pointer' }),
        }),
        render: ({ source, row_type }, _) => {
          let renderContent = <></>;

          if (row_type === 'total') renderContent = 'Total';
          if (source === 'G')
            renderContent = (
              <div className={stylesTable.flexCenter}>
                <img src={googleAdsMiniLogo} alt="google_logo" />
              </div>
            );
          if (source === 'F')
            renderContent = (
              <div className={stylesTable.flexCenter}>
                <img src={facebookMiniLogo} alt="facebook_logo" />
              </div>
            );

          if (source === 'T')
            renderContent = (
              <div className={stylesTable.flexCenter}>
                <img
                  src={tikTokLogo}
                  alt="tiktok_logo"
                  className={styles.sourceImg}
                />
              </div>
            );

          if (source === 'L')
            renderContent = (
              <div className={stylesTable.flexCenter}>
                <img
                  src={linkedInLogo}
                  alt="linked_in_logo"
                  className={styles.sourceImg}
                />
              </div>
            );

          return renderContent;
        },
      },
      {
        title: 'Campaign',
        dataIndex: 'campaign_name',
        key: 'campaign_name',
        fixed: 'left',
        sorter: true,
        className: `${stylesTable.tdNoWidth}`,
        render: ({ status, campaign_name }, _) => {
          return (
            <div className={styles.campaignNameCell}>
              <div className={styles.campaignNameCellIcon}>
                {status === 'E' && <img src={statusOK} />}
                {status === 'P' && <img src={statusBAD} />}
              </div>
              <span className={stylesTable.tdImageSpan}>{campaign_name}</span>
            </div>
          );
        },
      },
      {
        title: 'Client',
        dataIndex: 'client_name',
        key: 'client_name',
        fixed: 'left',
        sorter: true,
        className: `${stylesTable.td}`,
        style: { color: '#1649EC', cursor: 'pointer' },
        onClickDataCell: (render, _) => {
          if (handleClickClient) handleClickClient(render);
        },
      },
      {
        title: 'Budget',
        dataIndex: 'budget_name',
        key: 'budget_name',
        fixed: 'left',
        sorter: true,
        className: `${stylesTable.td}`,
        style: { color: '#1649EC', cursor: 'pointer' },
        onClickDataCell: (render, _) => {
          if (handleClickBudget) handleClickBudget(render);
        },
      },
      {
        title: 'Impressions',
        dataIndex: 'impressions',
        key: 'impressions',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ impressions }, _) => {
          return formatNumber(impressions);
        },
      },
      {
        title: 'Clicks',
        dataIndex: 'clicks',
        key: 'clicks',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ clicks }, _) => {
          return formatNumber(clicks);
        },
      },
      {
        title: 'Spend',
        dataIndex: 'spend',
        key: 'spend',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ spend, currency }, _) => {
          return parceBudget(spend, currency);
        },
      },
      {
        title: 'Conversions',
        dataIndex: 'conversions',
        key: 'conversions',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ conversions }, _) => {
          return formatNumber((+conversions)?.toFixed(0));
        },
      },
      {
        title: 'CPA',
        dataIndex: 'cpa',
        key: 'cpa',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ cpa, currency }, _) => {
          return parceBudget(cpa, currency);
        },
      },
      {
        title: 'Revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ revenue, currency }, _) => {
          return parceBudget(revenue, currency);
        },
      },
      {
        title: 'ROAS',
        dataIndex: 'roas',
        key: 'roas',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ roas }, _) => {
          return parcePercentage(roas);
        },
      },
      {
        title: '7 Day CPA Change',
        dataIndex: 'seven_days_cpa_change',
        sorter: true,
        className: ({ seven_days_cpa_change }, _) => {
          return `${stylesTable.td} ${formater(
            'cpa/roas_change',
            seven_days_cpa_change,
          )}`;
        },
        render: ({ seven_days_cpa_change }, _) => {
          return parcePercentage(seven_days_cpa_change);
        },
      },
      {
        title: '7 Day ROAS Change',
        dataIndex: 'seven_days_roas_change',
        sorter: true,
        className: ({ seven_days_roas_change }, _) => {
          return `${stylesTable.td} ${formater(
            'roas_change',
            seven_days_roas_change,
          )}`;
        },
        render: ({ seven_days_roas_change }, _) => {
          return parcePercentage(seven_days_roas_change);
        },
      },
      {
        title: 'All Conversions',
        dataIndex: 'all_conversions',
        sorter: true,
        className: `${stylesTable.td}`,
        render: ({ all_conversions }, _) => {
          return (+all_conversions)?.toFixed(0);
        },
      },
    ];

    if (metrics.length) {
      const filteredColumns = columns.filter(column =>
        metrics.includes(column.dataIndex),
      );

      return filteredColumns;
    }

    return columns;
  }, [metrics]);

  return (
    <Table
      columns={getColumns}
      dataSource={data}
      isLoading={isLoading}
      unSortLastElement
      rowClassNameProvider={record => {
        const baseClassName = stylesTable.tr;
        const additionalClassName =
          record?.row_type === 'total' ? stylesTable.trTotal : '';

        return `${baseClassName} ${additionalClassName}`;
      }}
    />
  );
};

export default CampaignPerformanceTable;
