import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import pause from '../../../../assets/pause.svg';
import stylesTable from '../../../../components/reusable/Tables/Tables.module.css';
import { parceBudget, parcePercentage } from '../../../../helpers/parceBudget';
import Table from '../../Table';
import { formater } from '../DashboardTableRows/functions';
import BudgetAmountCell from './BudgetAmountCell';
import BudgetNameCell from './BudgetNameCell';

const BudgetPacingTable = ({
  data,
  type,
  budgetToEdit,
  error,
  budgetIdSelected,

  isLoading,
  isDefaultCurrentCycleView,
  isAllChecked,
  isEditHover,
  isEdit,
  isAmountHover,
  isAmount,

  handleClickClient,
  handleClickBudget,
  handleCheckAll,
  handleCheckOne,
  handleOnAmountMouseEnter,
  handleOnAmountMouseLeave,
  handleOnMouseEnter,
  handleOnMouseLeave,

  handleChangeBudgetNameOrAmount,
  handleClickSaveBtn,
  handleCloseBudgetNameOrAmountModal,
  handleOpenBudgetAmountModal,
  handleOpenBudgetNameModal,
}) => {
  const columns = [
    {
      title: (
        <input
          className={stylesTable.checkbox}
          type="checkbox"
          checked={isAllChecked}
          onChange={handleCheckAll}
        />
      ),
      width: '50px',
      minWidth: '50px',
      enableColumnResizing: false,
      fixed: 'left',
      render: (record, _) => {
        return (
          record.row_type !== 'total' && (
            <input
              className={stylesTable.checkbox}
              type="checkbox"
              checked={record.isChecked}
              onChange={() => {
                if (handleCheckOne)
                  handleCheckOne(formater('key', record, type));
              }}
            />
          )
        );
      },
    },
    {
      title: 'Budget',
      dataIndex: 'budget_name',
      key: 'budget_name',
      fixed: 'left',
      width: '160px',
      minWidth: '160px',
      sorter: true,
      className: `${stylesTable.tdNoWidth} ${stylesTable.pr}`,
      style: ({ row_type }, _) => ({
        ...(row_type === 'total'
          ? { cursor: 'not-allowed' }
          : { cursor: 'pointer' }),
      }),
      onMouseEnterDataCell: (render, _) => {
        render.row_type === 'total'
          ? null
          : handleOnMouseEnter(render.budget_id);
      },
      onMouseLeaveDataCell: ({ row_type }, _) => {
        row_type === 'total' ? null : handleOnMouseLeave();
      },
      render: (record, _) => {
        return (
          <BudgetNameCell
            record={record}
            budgetToEdit={budgetToEdit}
            error={error}
            budgetIdSelected={budgetIdSelected}
            isEdit={isEdit}
            isAmount={isAmount}
            isEditHover={isEditHover}
            isAmountHover={isAmountHover}
            handleChangeBudgetName={handleChangeBudgetNameOrAmount}
            handleOpenBudgetNameModal={handleOpenBudgetNameModal}
            handleCloseBudgetNameOrAmountModal={
              handleCloseBudgetNameOrAmountModal
            }
            handleClickSaveBtn={handleClickSaveBtn}
            handleClickBudget={handleClickBudget}
          />
        );
      },
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      fixed: 'left',
      width: '160px',
      minWidth: '160px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      style: { color: '#1649EC', cursor: 'pointer' },
      onClickDataCell: (render, _) => {
        if (handleClickClient) handleClickClient(render);
      },
    },
    {
      title: 'Budget Amount',
      dataIndex: 'budget_amount',
      key: 'budget_amount',
      width: '160px',
      minWidth: '160px',
      fixed: "left",
      sorter: true,
      className: `${stylesTable.tdNoWidth} ${stylesTable.pr}`,
      onMouseEnterDataCell: ({ row_type, budget_id }, _) => {
        row_type === 'total' ? null : handleOnAmountMouseEnter(budget_id);
      },
      onMouseLeaveDataCell: ({ row_type }, _) => {
        row_type === 'total' ? null : handleOnAmountMouseLeave();
      },
      render: (record, _) => {
        return (
          <BudgetAmountCell
            record={record}
            budgetToEdit={budgetToEdit}
            budgetIdSelected={budgetIdSelected}
            isEdit={isEdit}
            isAmount={isAmount}
            isEditHover={isEditHover}
            isAmountHover={isAmountHover}
            handleChangeBudgetAmount={handleChangeBudgetNameOrAmount}
            handleClickSaveBtn={handleClickSaveBtn}
            handleCloseBudgetNameOrAmountModal={
              handleCloseBudgetNameOrAmountModal
            }
            handleOpenBudgetAmountModal={handleOpenBudgetAmountModal}
          />
        );
      },
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      sorter: true,
      width: '140px',
      minWidth: '140px',
      className: `${stylesTable.tdNoWidth}`,
      render: (record, _) => {
        return isDefaultCurrentCycleView ? (
          <div className={stylesTable.progressBarContainer}>
            <ProgressBar
              completed={+record.spend}
              maxCompleted={+record.budget_amount}
              customLabel={parceBudget(record.spend, record.currency, 0)}
              borderRadius="0"
              height="3rem"
              bgColor={formater('primaryColor', +record.trend, type)}
              baseBgColor={formater('secondaryColor', +record.trend, type)}
              labelAlignment="left"
              labelColor="var(--white)"
              labelSize="1.2rem"
              labelClassName={stylesTable.progressBarLabel}
              width="13rem"
            />
            {record?.status === 'paused' && (
              <img className={stylesTable.pause} alt="pause" src={pause} />
            )}
          </div>
        ) : (
          <>{parceBudget(record.spend, record.currency, 0)}</>
        );
      },
    },
    {
      title: 'Trend',
      dataIndex: 'trend',
      key: 'trend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: ({ trend, completed_status }, _) => {
        return `${stylesTable.tdNoWidth} ${formater(
          'trendColor',
          trend,
          completed_status ?? '',
        )}`;
      },
      render: ({ trend, completed_status }, _) => {
        return formater('trendName', trend, completed_status ?? '');
      },
    },
    {
      title: 'Remaining Budget',
      dataIndex: 'remaining_budget',
      key: 'remaining_budget',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      render: ({ remaining_budget, currency }, _) => {
        return parceBudget(remaining_budget, currency);
      },
    },
    {
      title: 'Ideal Daily Spend',
      dataIndex: 'ideal_daily_spend',
      key: 'ideal_daily_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      render: ({ ideal_daily_spend, currency }, _) => {
        return parceBudget(ideal_daily_spend, currency);
      },
    },
    {
      title: "Yesterday's Spend",
      dataIndex: 'yesterday_spend',
      key: 'yesterday_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      render: ({ yesterday_spend, currency }, _) => {
        return parceBudget(yesterday_spend, currency);
      },
    },
    {
      title: '3 Day Avg. Spend',
      dataIndex: 'tree_days_avg_spend',
      key: 'tree_days_avg_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      render: ({ tree_days_avg_spend, currency }, _) => {
        return parceBudget(tree_days_avg_spend, currency);
      },
    },
    {
      title: 'Forecast Spend',
      dataIndex: 'forecast_spend',
      key: 'forecast_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      render: ({ forecast_spend, currency }, _) => {
        return parceBudget(forecast_spend, currency);
      },
    },
    {
      title: 'Lost IS (Budget)',
      dataIndex: 'lost_is_budget_percentage',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.tdNoWidth}`,
      render: ({ lost_is_budget_percentage }, _) => {
        return parcePercentage(lost_is_budget_percentage);
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      isLoading={isLoading}
      haveTotalRow
      unSortLastElement
      rowClassNameProvider={record => {
        const baseClassName = stylesTable.tr;
        const additionalClassName =
          record?.row_type === 'total' ? stylesTable.trTotal : '';

        return `${baseClassName} ${additionalClassName}`;
      }}
    />
  );
};

export default BudgetPacingTable;
