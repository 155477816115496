import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nameError: false,
  shortNameError: false,
  uniqueNameError: false,
  countryError: false,
  timezoneError: false,
  currencyError: false,
};

const createClientSlice = createSlice({
  name: 'createClient',
  initialState,
  reducers: {
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setShortNameError: (state, action) => {
      state.shortNameError = action.payload;
    },
    setUniqueNameError: (state, action) => {
      state.uniqueNameError = action.payload;
    },
    setCountryError: (state, action) => {
      state.countryError = action.payload;
    },
    setTimezoneError: (state, action) => {
      state.timezoneError = action.payload;
    },
    setCurrencyError: (state, action) => {
      state.currencyError = action.payload;
    },
    resetStateError: () => initialState,
  },
});

export const {
  setNameError,
  setShortNameError,
  setUniqueNameError,
  setCountryError,
  setTimezoneError,
  setCurrencyError,
  resetStateError,
} = createClientSlice.actions;

export default createClientSlice.reducer;
