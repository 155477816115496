import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/reusable/Button/Button';
import Popup from '../../components/reusable/Popups/Popup';
import TeamTable from '../../components/reusable/Tables/TeamTable';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import { PERMISSIONS } from '../../contants/permissions';
import {
  displayErrorMessage,
  failChangeOwnAccessLevelOfAdmin,
  notyf,
  successPermissionsChanged,
  successRemoveAccess,
} from '../../helpers/notyf';
import { AddUser } from './AddUser';
import css from './Team.module.css';

const Team = () => {
  const navigate = useNavigate();

  const [isAddUser, setIsAddUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [isRemoveAccess, setIsRemoveAccess] = useState(false);
  const [toRemoveAccess, setToRemoveAccess] = useState(null);
  const [reload, setReload] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditHover, setIsEditHover] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const currUser = useSelector(state => state.me);
  const isAdmin = currUser.permission_level?.name === PERMISSIONS.ADMIN;

  useEffect(() => {
    setIsLoading(true)

    apiv2
      .GET_TEAM()
      .then(res => {
        setUsersList(res.data);
        setIsLoading(false);
      })
      .catch(err => {});
  }, [reload]);

  const handleRemoveAccess = id => {
    setToRemoveAccess(id);
    setIsRemoveAccess(true);
  };

  const handleClose = () => {
    setIsRemoveAccess(false);
    setToRemoveAccess(null);
  };

  const handleSubmitRemoveAccess = () => {
    const quantityOfUserIsAdmin = usersList.reduce((acc, curr) => {
      if (curr.permission_level?.name === PERMISSIONS.ADMIN && currUser.id !== curr.id)
        return acc + 1;
      return acc;
    }, 0);

    const isCurrentUser = toRemoveAccess === currUser.id;
    if (quantityOfUserIsAdmin === 0 && isAdmin && isCurrentUser) {
      failChangeOwnAccessLevelOfAdmin();
      setIsRemoveAccess(false);
      return;
    }

    if (isCurrentUser) {
      api.LOG_OUT().then(res => {
        if (res.status === 'OK') {
          localStorage.removeItem('ACCESS_TOKEN');
          localStorage.removeItem('REFRESH_TOKEN');
        }
      });
    }

    apiv2
      .REMOVE_ACCESS(toRemoveAccess)
      .then(res => {
        successRemoveAccess();
        setIsRemoveAccess(false);
        setToRemoveAccess(null);

        if (!isCurrentUser) {
          setReload(prev => !prev);
          return;
        }

        navigate('/login', { replace: true });
      })
      .catch(res => {
        displayErrorMessage(res);
      });
  };

  const onEditConfirm = permission_level_id => {
    apiv2
      .CHANGE_PERMISSIONS(editId, { permission_level_id })
      .then(res => {
        successPermissionsChanged();
        setIsEdit(false);
        setIsEditHover(null);
        setEditId(null);
        setReload(prev => !prev);
      })
      .catch(res => {
        const { message } = res.response.data;
        if (message === 'admin cant change his own permission') {
          failChangeOwnAccessLevelOfAdmin();
          return;
        }

        notyf.error(message);
      });
  };

  if (isAddUser)
    return (
      <div className={css.mainWrapper}>
        <AddUser setIsAddUser={setIsAddUser} setReload={setReload} />
      </div>
    );

  return (
    <div className={css.mainWrapper}>
      {isRemoveAccess && (
        <Popup
          type="removeAccess"
          onClose={handleClose}
          onClick={handleSubmitRemoveAccess}
        />
      )}

      <div className={css.wrapper}>
        <Button
          styled="addClient"
          title="+ Add New User"
          onClick={() => setIsAddUser(true)}
        />
        <div className={css.content} style={{ marginTop: 20 }}>
          <TeamTable
            usersList={usersList}
            handleRemoveAccess={handleRemoveAccess}
            onEditConfirm={onEditConfirm}
            setIsEditHover={setIsEditHover}
            setIsEdit={setIsEdit}
            setEditId={setEditId}
            isEditHover={isEditHover}
            isEdit={isEdit}
            isLoading={isLoading}
            editId={editId}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
