import service from './service';
import axios from 'axios';

const URL = process.env.REACT_APP_URL;

const GET_ME = () => {
  return service.get(`${URL}/auth/me/`);
};

const EDIT_ME = formData => {
  return service.patch(`${URL}/auth/me/`, formData);
};

const RESET_PASSWORD = formData => {
  return service.patch(`${URL}/auth/me/reset-password`, formData);
};

const DELETE_ME = () => {
  return service.delete(`${URL}/auth/me/`);
};

const GET_TEAM = () => {
  return service.get(`${URL}/auth/`);
};

const GET_PERMISSIONS = () => {
  return service.get(`${URL}/auth/permissions/`);
};

const SEND_INVITATION = formData => {
  return service.post(`${URL}/auth/invite/`, formData);
};

const CONFIRM_INVITATION = (formData, token) => {
  return axios.put(`${URL}/auth/invite/${token}`, formData);
};

const REMOVE_ACCESS = id => {
  return service.delete(`${URL}/auth/${id}/`);
};

const CHANGE_PERMISSIONS = (id, formData) => {
  return service.patch(`${URL}/auth/${id}/permissions/`, formData);
};

const COMPANIES_BILLING_POST = data => {
  return service.post(`${URL}/companies/billing/`, data);
};

const COMPANIES_BILLING_GET = () => {
  return service.get(`${URL}/companies/billing/`);
};

const INVOICES = () => {
  return service.get(`${URL}/companies/billing/invoices/`);
};

const GET_NOTIF = () => {
  return service.get(`${URL}/companies/notifications/`);
};

const GET_SOURCES_DATA = () => {
  // return service.get(`${URL}/sources/sync/`);
};
const GET_SOURCES_ACCOUNT = id => {
  return service.get(`${URL}/sources/sync/${id}/`);
};
const GET_SOURCES_COMPAIGNS = (id, accId) => {
  return service.get(`${URL}/sources/sync/${id}/${accId}/`);
};

const GET_HISTORY_SOURCES = () => {
  return service.get(`${URL}/sources/sync/history/`);
};
const GET_HISTORY_ACCOUNTS = id => {
  return service.get(`${URL}/sources/sync/history/${id}/`);
};
const GET_HISTORY_COMPAIGNS = (id, accId) => {
  return service.get(`${URL}/sources/sync/history/${id}/${accId}/`);
};

export const READ_NOTYF = data => {
  return service.post(`${URL}/companies/notifications/read`, data);
};

const SYNC_BUDGET_STATUS = id => {
  return service.get(`${URL}/budgets/${id}/sync_status`);
};

const GET_AUTOMATED_RULES = () => {
  return service.get(`${URL}/budgets/rules/`);
};

const GET_AUTOMATED_RULE = id => {
  return service.get(`${URL}/budgets/rules/${id}/`);
};

const CREATE_AUTOMATED_RULE = ruleCreateBody => {
  return service.post(`${URL}/budgets/rules/`, ruleCreateBody);
};

const UPDATE_AUTOMATED_RULE = (id, ruleUpdateBody) => {
  return service.put(`${URL}/budgets/rules/${id}/`, ruleUpdateBody);
};

const DELETE_AUTOMATED_RULE = id => {
  return service.delete(`${URL}/budgets/rules/${id}/`);
};

const UPDATE_STATUS_AUTOMATED_RULE = (id, statusObj) => {
  return service.patch(`${URL}/budgets/rules/${id}/status`, statusObj);
};

const CREATE_CUSTOM_VIEW = customViewBody => {
  return service.post(`${URL}/auth/custom-views/`, customViewBody);
};

const GET_LIST_CUSTOM_VIEW = () => {
  return service.get(`${URL}/auth/custom-views/`);
};

const GET_CUSTOM_VIEW = id => {
  return service.get(`${URL}/auth/custom-views/${id}/`);
};

const DELETE_CUSTOM_VIEW = id => {
  return service.delete(`${URL}/auth/custom-views/${id}/`);
};

const GET_CLIENT_HAVE_BUDGET = () => {
  return service.get(`${URL}/clients/has-budget/`);
};

const UPDATE_CUSTOM_VIEW = (customViewBody, id) => {
  return service.put(`${URL}/auth/custom-views/${id}/`, customViewBody);
};

const CHECK_STATUS_FIRST_SYNC_OF_DATA_SOURCE = sourceId => {
  return service.get(`${URL}/sources/${sourceId}/sync_status`);
};

const GET_ACTIVITY_SYNC_HISTORY_LOG = activitySyncHistoryLogId => {
  return service.get(
    `${URL}/sources/activity_sync_history_logs/${activitySyncHistoryLogId}/`,
  );
};

const INVOICE_UPCOMING = companyId => {
  return service.get(`${URL}/companies/billing/${companyId}/invoice_upcoming/`);
};

const CANCEL_CONFIRMATION = companyId => {
  return service.delete(
    `${URL}/companies/billing/${companyId}/cancel_confirmation/`,
  );
};

const RENEW_SUBSCRIPTION = body => {
  return service.post(`${URL}/companies/billing/renew_subscription/`, body);
};

const apiv2 = {
  GET_ME,
  GET_TEAM,
  GET_PERMISSIONS,
  SEND_INVITATION,
  CONFIRM_INVITATION,
  REMOVE_ACCESS,
  CHANGE_PERMISSIONS,
  EDIT_ME,
  RESET_PASSWORD,
  DELETE_ME,
  COMPANIES_BILLING_POST,
  COMPANIES_BILLING_GET,
  INVOICES,
  GET_NOTIF,
  GET_SOURCES_DATA,
  GET_SOURCES_ACCOUNT,
  GET_SOURCES_COMPAIGNS,
  GET_HISTORY_SOURCES,
  GET_HISTORY_ACCOUNTS,
  GET_HISTORY_COMPAIGNS,
  READ_NOTYF,
  SYNC_BUDGET_STATUS,
  GET_AUTOMATED_RULES,
  GET_AUTOMATED_RULE,
  CREATE_AUTOMATED_RULE,
  UPDATE_AUTOMATED_RULE,
  DELETE_AUTOMATED_RULE,
  UPDATE_STATUS_AUTOMATED_RULE,
  CREATE_CUSTOM_VIEW,
  GET_LIST_CUSTOM_VIEW,
  DELETE_CUSTOM_VIEW,
  GET_CLIENT_HAVE_BUDGET,
  GET_CUSTOM_VIEW,
  UPDATE_CUSTOM_VIEW,
  CHECK_STATUS_FIRST_SYNC_OF_DATA_SOURCE,
  GET_ACTIVITY_SYNC_HISTORY_LOG,
  INVOICE_UPCOMING,
  CANCEL_CONFIRMATION,
  RENEW_SUBSCRIPTION,
};

export default apiv2;
