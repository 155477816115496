import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
    first_name: '',
    last_name: '',
    email: '',
  },
  changePassData: {
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  },
  accountDetails: {
    name: '',
    id: '',
  },
  nameError: false,
  shortNameError: false,
  uniqueNameError: false,
  isDelete: false,
  isPayment: false,
  cardData: [],
  ruleManagerState: false
};

const createUserSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setCardData: (state, action) => {
      state.cardData = action.payload;
    },

    setChangePassData: (state, action) => {
      state.changePassData = action.payload;
    },
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setShortNameError: (state, action) => {
      state.shortNameError = action.payload;
    },
    setUniqueNameError: (state, action) => {
      state.uniqueNameError = action.payload;
    },
    setIsDelete: (state, action) => {
      state.isDelete = action.payload;
    },
    setIsPayment: (state, action) => {
      state.isPayment = action.payload;
    },
    setRuleManagerState: (state, action) => {
      state.ruleManagerState = action.payload;
    }
  },
});

export const {
  setFormData,
  setChangePassData,
  setAccountDetails,
  setNameError,
  setShortNameError,
  setUniqueNameError,
  setIsDelete,
  setIsPayment,
  setCardData,
  setRuleManagerState
} = createUserSettingsSlice.actions;

export default createUserSettingsSlice.reducer;
