import React from 'react';
import Button from '../Button/Button';
import css from './Popups.module.css';

const Disconnect = ({ onClose, onClick }) => (
  <>
    <h1 className={css.title2}>Remove User Access</h1>
    <p className={css.text}>
      Removing a user profile will mean that they no longer have access to this
      dashboard.
      <br />
      <br />
      Any data sources connected to this profile will not be disconnected.
      Despite this, the user can revoke the API connection through their
      relevant platform login if they choose to, so we would recommend
      reconnecting the data source through a user profile that has access to the
      platform.
      <br />
      <br />
      You cannot delete a user profile if you are the only admin. Please add
      another admin if you would like to remove the only admin’s access.
    </p>
    <div className={css.btnsWrapper}>
      <Button styled="close" title="Cancel" onClick={onClose} />
      <Button styled="delete" title="Remove Access" onClick={onClick} />
    </div>
  </>
);

export default Disconnect;
