import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';

const LabelSection = ({ title = "", name = '', checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = event => {
    setIsChecked(!isChecked);
    if (onChange) onChange(event);
  };

  return (
    <div className={styles.labelSection}>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={isChecked}
        onChange={handleChange}
      />
      <label htmlFor={name}>{title}</label>
    </div>
  );
};

export default LabelSection;
