import { useMemo } from "react";

const DOTS = "..."

const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
  };

export const usePagination = ({
    pageSize,
    current,
    total,
    siblingCount,
}) => {
    const paginationRange = useMemo(() => {
    
    const totalPage = Math.ceil(total/pageSize);

    // firstPage, lastPage, 2*DOT, current page;
    const totalPageNumbers = siblingCount + 5;

    // Case 1: If number of pages less than the page numbers we want to show in our 
    if(totalPageNumbers >= totalPage) {
        return range(1, totalPage)
    }

    const leftSiblingIndex = Math.max(current - siblingCount, 1)
    const rightSiblingIndex = Math.min(current + siblingCount, totalPage)

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = totalPage - rightSiblingIndex > 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPage;

    // Case 2: Right dots to be shown
    if(!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = 3 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount)

        return [...leftRange, DOTS, totalPage]
    }

    // Case 3: Left dots to be shown
    if(shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = 3 + 2 * siblingCount;
        const rightRange = range(totalPage - rightItemCount + 1, totalPage)

        return [firstPageIndex, DOTS, ...rightRange]
    }

    // Case 4: Both left and right dots to be shown
    if(shouldShowRightDots && shouldShowLeftDots ) {
        const middleRange =  range(leftSiblingIndex, rightSiblingIndex)

        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }

    }, [total, pageSize, siblingCount, current])

    return paginationRange
};
