import React from 'react';

import { useWindowSize } from '../../hooks/useWindowSize';
import airtomicLogoSidebarSignUp from './../../assets/airtomicLogoSidebarSignUp.webp';
import backgroundSignUp from './../../assets/backgroundSideBarSignUp2.webp';
import capterra from './../../assets/captera.webp';
import lineDash from './../../assets/lineDash.webp';
import logoCapterra from './../../assets/logoCapterra.webp';
import logoCapterra2 from './../../assets/logoCapterra_2.webp';
import stars from './../../assets/stars.webp';
import SignUpForm from './components/SignupForm';
import css from './SignupPage.module.css';

const SignupPageV2 = () => {
  const windowSize = useWindowSize();
  document.title = 'Airtomic: Sign Up';

  return (
    <div className={css.wrapperSignUpPage}>
      <div className={css.wrapperBanner}>
        <div>
          <div>
            <div className={css.airtomicLogo}>
              <img src={airtomicLogoSidebarSignUp} alt="airtomic-logo" />
            </div>

            <div className={css.background}>
              <img src={backgroundSignUp} alt="airtomic-logo" />
            </div>
          </div>

          {windowSize.width < 769 && (
            // <Suspense fallback={<div>Loading...</div>}>
              <SignUpForm />
            // </Suspense>
          )}

          <div className={css.wrapperContent}>
            <div className={css.stars}>
              <img src={stars} alt="stars" />
            </div>

            <div className={css.capterraContainer}>
              <div>
                <div className={css.logoCapterra}>
                  <img src={logoCapterra} alt="logoCapterra" />
                </div>
                <div className={css.capterra}>
                  <img src={capterra} alt="capterra" />
                </div>
              </div>
              <div className={css.lineSplit}></div>
              <div className={css.logoCapterra2}>
                <img src={logoCapterra2} alt="logo-capterra" />
              </div>
            </div>

            <div className={css.lineDash}>
              <img src={lineDash} />
            </div>

            <p className={css.reviewText}>
              “Airtomic has completely transformed my PPC campaign management.
              It has become my go-to tool, making a remarkable difference in how
              I handle my PPC campaigns and day-to-day account management. One
              of the standout features is the efficient budget pacing.”
            </p>

            <div className={css.promotionText}>
              <h4>Petra</h4>
              <span>Performance Marketing Manager</span>
            </div>

            <div>
            <div className={css.dashboardBackground}></div>
              </div>
          </div>
        </div>
      </div>

      {windowSize.width > 768 && (
        // <Suspense fallback={<div>Loading...</div>}>
          <SignUpForm />
        // </Suspense>
      )}
    </div>
  );
};

export default SignupPageV2;
