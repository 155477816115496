import React from 'react';
import css from './ProgressBar.module.css';

const ProgressBar = ({ step = 1 }) => {
  return (
    <div
      className={css.wrapper}
      style={{ width: `calc(${step}*20% + 13%*${step - 1})` }}
    />
  );
};

export default ProgressBar;
