import React from 'react';
import Table from '../../components/reusable/Tables/Table';
import css from './UserSettings.module.css';

const Invoices = ({ invoices, isLoading = false }) => (
  <>
    <h2 className={css.title}>Invoices</h2>
    <div className={css.content}>
      <Table type="invoices" data={invoices} isLoading={isLoading} />
    </div>
  </>
);

export default Invoices;
