import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../../../components/reusable/Card';
import Select from '../../../../../components/reusable/Select/Select';
import TagsInput from '../../../../../components/reusable/TagsInput';
import { RULE_NOTIFICATIONS } from '../../../../../contants/automatedRules';
import { useSelectPositionLeft } from '../../../../../hooks/useSelectPositionLeft';
import css from './../../../../../components/reusable/Modals/Modals.module.css';
import styles from './styles.module.css';
import apiv2 from '../../../../../config/apiv2';
import { useClickOutside } from '../../../../../hooks/useClickOutside';

const RuleNotification = ({ rule, error, setRule }) => {
  const { notificationType, notificationRecipients } = rule;
  
  const ref = useRef(null);
  const wrapperSelectRef = useRef(null);
  const selectPosLeft = useSelectPositionLeft(wrapperSelectRef);

  const [emailOptions, setEmailOptions] = useState([]);
  const [isShowMenu, setIsShowMenu] = useState(false);

  const haveOptionNotSelected = emailOptions.filter(
    option => option.isShow,
  ).length;

  useClickOutside(ref, () => {
    setIsShowMenu(false);
  });

  useEffect(() => {
    fetchAndSetEmailRecipients();
  }, []);

  const fetchAndSetEmailRecipients = () => {
    apiv2
      .GET_TEAM()
      .then(res => {
        const emailOpts = res.data.map(user => {
          const existedInNotiRecipients = notificationRecipients.find(
            email => email === user.email,
          );

          return {
            email: user.email,
            isShow: !existedInNotiRecipients,
          };
        });

        setEmailOptions(emailOpts);
      })
      .catch(err => err);
  };

  const handleChangeNotificationType = option => {
    setRule(prev => ({
      ...prev,
      notificationType: option,
    }));
  };

  const handleChangeEmailRecipients = values => {
    addBackEmailOptionList(values);

    setRule(prev => ({
      ...prev,
      notificationRecipients: values,
    }));
  };

  const handleClickItem = e => {
    const email = e.target.innerText;

    setRule(prev => ({
      ...prev,
      notificationRecipients: [...notificationRecipients, email],
    }));

    const optionIndex = emailOptions.findIndex(
      option => option.email === email,
    );
    if (optionIndex !== -1) {
      emailOptions[optionIndex].isShow = false;
      setEmailOptions([...emailOptions]);
    }
  };

  const addBackEmailOptionList = values => {
    const removedEmail = notificationRecipients.filter(
      item => !values.includes(item),
    )[0];
    const removedEmailIndex = emailOptions.findIndex(
      option => option.email === removedEmail,
    );

    if (removedEmailIndex !== -1) {
      emailOptions[removedEmailIndex].isShow = true;
    }
  };

  return (
    <Card title="Notifications">
      <div className={styles.wrapperBodyNotifications}>
        <div>
          <p className={styles.labelInput}>Notifications Type</p>
          {error?.notificationType && (
            <p className={css.error}>{error?.notificationType}</p>
          )}
          <div ref={wrapperSelectRef}>
            <Select
              name="notificationType"
              placeHolder="Select notification conditions"
              styleMenuPortal={{
                left: selectPosLeft,
              }}
              options={RULE_NOTIFICATIONS}
              onChange={handleChangeNotificationType}
              value={notificationType}
            />
          </div>
        </div>

        <div>
          <p className={styles.labelInput}>Email Recipients</p>
          {error?.notificationRecipients && (
            <p className={css.error}>{error?.notificationRecipients}</p>
          )}
          <div
            ref={ref}
            className={`${styles.tagInputWrapper}`}
            onClick={() => setIsShowMenu(prev => !prev)}
          >
            <TagsInput
              classNameInput={`${styles.cursorPointer}`}
              readOnly
              placeholder="Add email recipients"
              value={notificationRecipients}
              onChange={handleChangeEmailRecipients}
            />
            {isShowMenu && haveOptionNotSelected ? (
              <ul>
                {emailOptions?.map(
                  (option, index) =>
                    option.isShow && (
                      <li onClick={handleClickItem}>{option.email}</li>
                    ),
                )}
              </ul>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RuleNotification;
