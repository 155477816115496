export const parceBudget = (budget, currency, toFixed) => {
  const priceWithComma = (+budget)
    .toFixed(toFixed ?? 2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return currency ? currency + priceWithComma : priceWithComma;
};

export const parcePercentage = budget => {
  const priceWithComma = (+budget)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return priceWithComma + '%';
};

export const formatBudgetAmount = (value, currency) => {
  const integerPart = Math.floor(value);
  const decimalPart = +value.toString().replace(/^[^\.]+/,'0')

  const formattedIntegerPart = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const roundedDecimalPart = (Math.floor(decimalPart * 100) / 100).toFixed(2).toString();
  const reminderRoundedDecimalPart = roundedDecimalPart.toString().slice(1);

  const formattedValue = formattedIntegerPart + reminderRoundedDecimalPart
  
  return currency ? currency + formattedValue : formattedValue
}
