import React from 'react';
import styles from './styles.module.css';

const Tooltip = ({
  children = <></>,
  text = '',
  style,
  position,
  className,
  styleToolTipText,
}) => {
  return (
    <div className={`${styles.toolTipWrapper} ${className}`} style={style}>
      {children}
      <div className={`${styles.toolTipText}`} style={styleToolTipText}>{text}</div>
    </div>
  );
};

export default Tooltip;
