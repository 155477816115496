import moment from 'moment';
import React from 'react';
import Button from '../Button/Button';
import css from './Tables.module.css';

const InvoicesTable = ({ data, isLoading }) => {
  return (
    <div className={css.dataSourceWrapper}>
      <table className={css.table}>
        <thead
          className={`${css.thead} ${css.dashboardThead} ${
            isLoading ? css.loadingBar : ''
          }`}
        >
          <tr className={css.trHead}>
            <th className={css.th}>Invoice Date</th>
            <th className={css.th}>Amount Paid</th>
            <th className={css.th}>Status</th>
            <th className={css.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>
              <div className={`${css.tbody} ${css.invoicesBody}`}>
                <table className={css.table2}>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map(el => (
                        <tr key={el.id}>
                          <td className={css.tdP}>
                            {moment(el.date).format('MMMM DD, YYYY')}
                          </td>
                          <td className={css.tdP}>
                            {el.amount.toFixed(2)} USD
                          </td>
                          <td
                            className={`${css.tdP} ${
                              el.status === 'Paid' ? css.green : css.red
                            }`}
                          >
                            {el.status === 'Paid' ? 'Paid' : 'Pending'}
                          </td>
                          <td className={css.tdP}>
                            <Button
                              styled="download"
                              onClick={() => {
                                window.open(el.pdf_link);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
