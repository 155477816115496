import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import css from './Tables.module.css';
import Button from '../Button/Button';
import edit from '../../../assets/edit.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '../../../contants/permissions';

const TableRow = ({
  el,
  isEditHover,
  isEdit,
  editId,
  handleRemoveAccess,
  onEditConfirm,
  setIsEditHover,
  setIsEdit,
  setEditId,
}) => {
  const ref = useRef();
  const selectRef = useRef();
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  
  const currUser = useSelector(state => state.me)
  const permission_level = currUser.permission_level?.name
  const isCurrentUser = el.id === currUser.id
  const isAdmin = permission_level === "Admin"
  const isShowIconEdit = !isCurrentUser && isAdmin && el.permission_level

  useEffect(() => {
    if (isEdit && el.id === editId) {
      setIsOpenSelect(true);
      return;
    }
  }, [isEdit, el.id, editId]);

  useLayoutEffect(() => {
    const posBottomParent = document.getElementById("dashboardBodyTeamTable").getBoundingClientRect().bottom
    if (selectRef.current && posBottomParent && isOpenSelect) {
      const posBottomDropdown = selectRef.current.getBoundingClientRect().bottom;
      if (posBottomDropdown - posBottomParent > 20) {
        selectRef.current.style.top = '-18rem';
      }
    }
  }, [isOpenSelect]);

  const handleOnMouseEnter = (id, e) => setIsEditHover(id);

  const handleOnMouseLeave = () => {
    setIsEditHover(null);
  };

  const handleEdit = id => {
    setIsEdit(true);
    setEditId(id);

    const handleClick = e => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        setIsEdit(false);
        setEditId(null);
        setIsOpenSelect(false)
        document.removeEventListener('click', handleClick);
      }
    };

    document.addEventListener('click', handleClick);
  };

  return (
    <tr className={css.tr}>
      <td className={`${css.td} ${css.textLeft}`}>{`${el.email}${isCurrentUser ? " - You" : ""}` }</td>
      <td
        className={`${css.td} ${css.pr} ${css.textLeft}`}
        onMouseEnter={handleOnMouseEnter.bind(this, el.id)}
        onMouseLeave={handleOnMouseLeave}
        ref={ref}
      >
        <div style={{ display: 'flex' }}>
          <span style={{ color: '#1649EC', whiteSpace: 'nowrap' }}>
            {el.permission_level ? el.permission_level.name : 'Invited'}
          </span>
          {isShowIconEdit && (
            <img
              className={css.edit}
              style={{
                opacity: `${isEditHover === el.id ? 1 : 0}`,
                minWidth: 12,
              }}
              alt="edit"
              src={edit}
              onClick={handleEdit.bind(this, el.id)}
            />
          )}
        </div>
        {isOpenSelect && (
          <div className={css.selectContainer} ref={selectRef}>
            <div className={css.selectElem} onClick={() => onEditConfirm(3)}>
              Read Only
            </div>
            <div className={css.selectElem} onClick={() => onEditConfirm(2)}>
              Standard
            </div>
            <div className={css.selectElem} onClick={() => onEditConfirm(1)}>
              Admin
            </div>
          </div>
        )}
      </td>
      <td className={`${css.td} ${css.textLeft}`}>{el.auth_method?.label}</td>
      <td className={`${css.td} ${css.textLeft}`}>
        {el.last_login ? moment(el.last_login).format('DD, MMMM, YYYY') : ''}
      </td>
      <td className={`${css.td} ${css.textLeft}`}>
        {el.date_joined ? moment(el.date_joined).format('DD, MMMM, YYYY') : ''}
      </td>
      {permission_level === PERMISSIONS.ADMIN && (
        <td className={`${css.td} ${css.textLeft}`}>
          <Button
            styled="disconnect"
            title="Remove Access"
            onClick={handleRemoveAccess.bind(this, el.id)}
          />
        </td>
      )}
    </tr>
  );
};

const TeamTable = ({
  usersList = [],
  handleRemoveAccess,
  onEditConfirm,
  setIsEditHover,
  setIsEdit,
  setEditId,
  isEditHover,
  isEdit,
  isLoading,
  editId,
}) => {
  const permission_level = useSelector(
    state => state.me.permission_level?.name,
  );

  return (
    <div className={css.dashboardBodyTeam} id="dashboardBodyTeamTable">
      <table className={css.table}>
        <thead className={`${css.thead} ${css.dashboardThead} ${isLoading ? css.loadingBar : ""}`}>
          <tr className={css.trHead}>
            <th className={`${css.th} ${css.textLeft}`}>User</th>
            <th className={`${css.th} ${css.textLeft}`}>Access level</th>
            <th className={`${css.th} ${css.textLeft}`}>
              Authentication method
            </th>
            <th className={`${css.th} ${css.textLeft}`}>Last signed in</th>
            <th className={`${css.th} ${css.textLeft}`}>Added on</th>
            {permission_level === PERMISSIONS.ADMIN && (
              <th className={`${css.th} ${css.textLeft}`}>Action</th>
            )}
          </tr>
        </thead>
        {usersList.length > 0 &&
          usersList.map(el => (
            <TableRow
              el={el}
              isEditHover={isEditHover}
              isEdit={isEdit}
              editId={editId}
              handleRemoveAccess={handleRemoveAccess}
              onEditConfirm={onEditConfirm}
              setIsEditHover={setIsEditHover}
              setIsEdit={setIsEdit}
              setEditId={setEditId}
            />
          ))}
      </table>
    </div>
  );
};

export default TeamTable;
