import React, { useState } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Alert from '../Alert/Alert';
import css from './Modals.module.css';

const CreateAccount = ({ data, onSubmit, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = e => {
    setIsLoading(true);
    onSubmit(e).then(() => {
      setIsLoading(false);
    }).catch(e => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {data.showWelcomeBox && (
        <div className="create-sucess-wrapepr">
          <span className="close">
            <i className="fas fa-times" />
          </span>
          <h1>Welcome!</h1>
          <p>Thanks for signing up to Airtomic. </p>

          <p>
            We don’t have a responsive design for mobile devices (yet) so we
            would highly recommend using a computer to setup and view your
            dashboard.
          </p>
          <p>
            <a href="https://docs.airtomic.co/overview/getting-started" target='_blank' rel="noreferrer">Click here</a> for our guide on how to get started.
          </p>

          <Button
            type="submit"
            styled="submit"
            title="Continue"
            onClick={handleSubmit}
          />
        </div>
      )}
      {!data.showWelcomeBox && (
        <form className={css.form} onSubmit={handleSubmit}>
          <h1 className={css.title}>Create Account</h1>
          <h3 className="sub-title">
            The information below is for internal use only and not linked to
            billing
          </h3>

          <p className={css.formTitle}>Your Company Name</p>
          <Input
            name="companyName"
            type="text"
            placeholder="Enter Company Name"
            value={data.name}
            onChange={onChange}
            maxLength={60}
            required
          />
          <p className={css.formTitle}>Average Monthly Ad Spend</p>
          <div className='select-wrapper'>
            <span className='arrow fas fa-chevron-down' />
            <select name="avgMonthlyAdSpend" className="form-control" onChange={onChange} required>
              <option value="">Select</option>
              <option value="BK50">Below $50,000</option>
              <option value="K50-K250">$50,001 - $250,000</option>
              <option value="K250-K500">$250,001 - $500,000</option>
              <option value="K500-M1">$500,001 - $1,000,000</option>
              <option value="OM1">Over $1,000,000</option>
            </select>
          </div>

          {
            data.message && <Alert
              type={(data.success) ? 'success' : 'error'}
              message={data.message}
            />
          }

          <Button
            type="submit"
            styled="submit"
            title="Create Account"
            disabled={isLoading}
          />
        </form>
      )}
    </>
  );
};

export default CreateAccount;
