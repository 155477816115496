import React, { useEffect, useState } from 'react';
import Tag from '../Tag';
import styles from './styles.module.css';

const TagsInput = ({
  name,
  className,
  classNameInput,
  placeholder,
  value,
  readOnly,
  onChange,
  onClick,
  onCloseTag
}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value);
    }
  }, [value]);

  const handleOnKeyDown = e => {
    if (e.key !== 'Enter') return;
    const value = e.target.value;

    if (!value.trim()) return;

    const newTags = [...tags, value];
    setTags([...tags, value]);
    onChange && onChange(newTags);

    e.target.value = '';
  };

  const handleRemoveTag = index => {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    onChange && onChange(updatedTags);
  };

  return (
    <div
      className={`${styles.tagsInputContainer} ${className}`}
      onClick={onClick}
    >
      {tags.map((tag, index) => (
        <Tag
          key={index /*eslint-disable-line react/no-array-index-key */}
          closeIcon
          onClose={() => handleRemoveTag(index)}
        >
          {tag}
        </Tag>
      ))}
      <input
        readOnly={readOnly}
        type="text"
        className={`${classNameInput}`}
        placeholder={placeholder}
        onKeyUp={handleOnKeyDown}
      />
    </div>
  );
};

export default TagsInput;
