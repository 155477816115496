import React from 'react';
import css from './Popups.module.css';
import close from '../../../assets/close.png';
import Button from '../Button/Button';

const CustomPopup = ({
  title = '',
  children,
  isOpen = false,
  onOk = () => {},
  onCancel = () => {},
}) => {
  if (!isOpen) return null;

  return (
    <div className={css.overlay} onClick={onCancel}>
      <div
        className={` ${css.wrapper}`}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className={css.wrap}>
          <h1 className={css.title2}>{title}</h1>
          <img src={close} className={css.closeBtn} onClick={onCancel} />
        </div>

        <div>{children}</div>

        <div className={css.btnsWrapper}>
          <button className={css.close} onClick={onCancel}>
            Cancel
          </button>
          <Button
            styled="delete"
            title="Cancel subscription"
            onClick={onOk}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;
