import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import { NotificationBanner } from './components/NotificationBanner/NotificationBanner';
import Sidebar from './components/Sidebar/Sidebar';
import apiv2 from './config/apiv2';
import {
  CREATE_ACCOUNT_PATH,
  CREATE_BUDGET_PATH,
  CREATE_CLIENT_PATH,
  CREATE_DATA_SOURCE_PATH,
  PROTECTED_ROUTES,
  SETTING_USAGE_BILLING_PATH,
  SETTING_USER_PATH,
} from './contants';
import { PERMISSIONS } from './contants/permissions';
import { setIsOpenNewDataSourceModal } from './features/dataSource/dataSourceSlice';
import { setMe } from './features/me/meSlice';
import { AppRoutes } from './routes/appRoutes';

const routeWithoutHeader = [
  '/signup',
  '/signupv2',
  '/login',
  '/reset-password',
  '/confirm-invitation/',
  '/create-account',
];

const App = () => {
  const [validAccess, setValidAccess] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [showNotifAccount, setShowNotifAccount] = useState(false);
  const [billingNotification, setBillingNotification] = useState({
    boldText: '',
    text: '',
    navigateLink: '',
    onClickCallback: () => {},
  });
  const [accountNotification, setAccountNotification] = useState({
    boldText: '',
    text: '',
    navigateLink: '',
    onClickCallback: () => {},
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { me: currentUser } = useSelector(state => state);

  const isPossibleRoute =
    location.pathname && !routeWithoutHeader.includes(location.pathname);
  const canShowHeaderAndSidebar = PROTECTED_ROUTES.find(route =>
    location.pathname.includes(route),
  );

  const getIsLoadMain = !!currentUser?.id;
  const isLoadMainAndPossibleRoute = isPossibleRoute && getIsLoadMain;
  const isNeedShowNotifDataSource =
    !currentUser?.has_data_sources && isLoadMainAndPossibleRoute;
  const isNeedShowNotifClient =
    !currentUser?.has_clients && isLoadMainAndPossibleRoute;
  const isNeedShowNotifBudget =
    !currentUser?.has_budgets && isLoadMainAndPossibleRoute;
  const isNeedShowNotiRequired2StepVerification =
    currentUser?.accounts_is_required_2step_verification?.length > 0 &&
    isLoadMainAndPossibleRoute;
  const needRefresh = currentUser.triggerGetMe;

  const notification = showNotifAccount
    ? accountNotification
    : billingNotification;

  useEffect(() => {
    if (!location) return;
    if (location.pathname === '/login') {
      setShowNotif(false);
      setBillingNotification({
        boldText: '',
        text: '',
      });
    }
  }, [location]);

  useEffect(() => {
    const isNeedNotif =
      isNeedShowNotifDataSource ||
      isNeedShowNotifClient ||
      isNeedShowNotifBudget ||
      isNeedShowNotiRequired2StepVerification;
    if (isLoadMainAndPossibleRoute && !showNotif && isNeedNotif) {
      setShowNotifAccount(true);
      if (isNeedShowNotifDataSource) {
        setAccountNotification({
          boldText: '',
          text: 'To get started click here to add a data source',
          navigateLink: CREATE_DATA_SOURCE_PATH,
          onClickCallback: () => dispatch(setIsOpenNewDataSourceModal(true)),
        });
      } else if (isNeedShowNotiRequired2StepVerification) {
        setAccountNotification({
          boldText: 'Please enable 2 step verification on Google account: ',
          text: `${currentUser?.accounts_is_required_2step_verification[0]?.email} to allow API access`,
          navigateLink: 'https://support.google.com/accounts/answer/185839',
        });
      } else if (isNeedShowNotifClient && !isNeedShowNotifDataSource) {
        setAccountNotification({
          boldText: 'Step 2: ',
          text: 'click here to create a Client',
          navigateLink: CREATE_CLIENT_PATH,
        });
      } else if (isNeedShowNotifBudget && !isNeedShowNotifClient) {
        setAccountNotification({
          boldText: 'Final Step: ',
          text: 'Click here to create a Budget',
          navigateLink: `${CREATE_BUDGET_PATH}/client`,
        });
      }
    } else {
      setShowNotifAccount(false);
      setAccountNotification({
        boldText: '',
        text: '',
      });
    }
  }, [
    isNeedShowNotifDataSource,
    isNeedShowNotifClient,
    isNeedShowNotifBudget,
    isLoadMainAndPossibleRoute,
    getIsLoadMain,
    location,
  ]);

  useEffect(() => {
    (async () => {
      if (location.pathname.includes('confirm-invitation')) return;
      if (localStorage.getItem('ACCESS_TOKEN')) {
        apiv2.GET_ME().then(res => {
          dispatch(setMe(res.data));

          const { notifications, company = null } = res.data;
          let needCheckPaymentFailed = false;
          let needCheckTrialExpired = false;

          let mainWarning = '';
          let instructionalWarning = '';
          let isValid = true;
          let pageNeedNavigate = '';

          if (!company) {
            isValid = false;
            !location.pathname.includes(SETTING_USER_PATH) &&
              (pageNeedNavigate = CREATE_ACCOUNT_PATH);
          } else {
            const subscriptionIsCanceled =
              notifications?.subscription_status === 'canceled';
            const userAddedPMAndActiveSubscription =
              !subscriptionIsCanceled && notifications?.payment_method_attached;

            needCheckPaymentFailed = userAddedPMAndActiveSubscription;
            needCheckTrialExpired = !notifications?.payment_method_attached;

            const isTrialPeriodInLastWeek =
              typeof notifications?.trial_remaining_days === 'number' &&
              notifications.trial_remaining_days > 0 &&
              notifications.trial_remaining_days <= 7;

            const subscriptionCanceledAndTrialExpired =
              subscriptionIsCanceled && notifications.trial_period_expired;

            if (
              needCheckPaymentFailed ||
              needCheckTrialExpired ||
              isTrialPeriodInLastWeek ||
              subscriptionIsCanceled
            ) {
              const permissionLevel = res.data.permission_level.name;

              const isPaymentDueWithinDaysGrace =
                notifications?.payment_remaining_days &&
                notifications.payment_remaining_days > 0 &&
                notifications.payment_remaining_days <= 7;

              if (permissionLevel === PERMISSIONS.ADMIN) {
                if (needCheckPaymentFailed && notifications.payment_error) {
                  if (isPaymentDueWithinDaysGrace) {
                    mainWarning =
                      'Make a payment towards your overdue balance.';
                    instructionalWarning = `You will not be able to view your dashboard in ${notifications.payment_remaining_days} days if this isn't resolved.`;
                  } else {
                    mainWarning =
                      'Make a payment towards your overdue balance.';

                    isValid = false;
                    pageNeedNavigate = SETTING_USAGE_BILLING_PATH;
                  }
                } else if (
                  needCheckTrialExpired &&
                  (notifications?.trial_period_expired ||
                    isTrialPeriodInLastWeek)
                ) {
                  if (notifications?.trial_period_expired) {
                    mainWarning = 'Free trial has expired.';
                  } else {
                    mainWarning = `Free trial expires in ${
                      notifications.trial_remaining_days > 0
                        ? `${notifications.trial_remaining_days} days`
                        : 'today'
                    }.`;
                  }

                  instructionalWarning = `Click here to add a payment method.`;

                  isValid = false;
                  pageNeedNavigate = SETTING_USAGE_BILLING_PATH;
                } else if (subscriptionCanceledAndTrialExpired) {
                  isValid = false;
                  instructionalWarning = `Your subscription has been cancelled by an admin. Please re-enable this in the settings to regain access to your dashboard.`;
                  pageNeedNavigate = SETTING_USAGE_BILLING_PATH;
                }
              } else if (
                permissionLevel === PERMISSIONS.READ_ONLY ||
                permissionLevel === PERMISSIONS.STANDARD
              ) {
                if (needCheckPaymentFailed && notifications.payment_error) {
                  mainWarning = 'Overdue balance.';
                  instructionalWarning = `Contact your account to admin regain access to your dashboard.`;

                  if (!isPaymentDueWithinDaysGrace) {
                    isValid = false;
                  }
                } else if (
                  needCheckTrialExpired &&
                  notifications?.trial_period_expired
                ) {
                  isValid = false;
                  mainWarning = 'Free trial has expired.';
                  instructionalWarning = `Contact your account to admin regain access to your dashboard`;
                } else if (subscriptionCanceledAndTrialExpired) {
                  isValid = false;
                  instructionalWarning = `Your subscription has been cancelled by an admin. Please re-enable this in the settings to regain access to your dashboard.`;
                }

                pageNeedNavigate = SETTING_USER_PATH;
              }
            }
          }

          pageNeedNavigate && navigate(pageNeedNavigate);
          setValidAccess(isValid);
          setShowNotif(mainWarning || instructionalWarning);
          setBillingNotification({
            boldText: mainWarning,
            text: instructionalWarning,
            navigateLink:
              instructionalWarning === 'Click here to add a payment method.'
                ? SETTING_USAGE_BILLING_PATH
                : '',
          });
        });
      }
    })();
  }, [needRefresh]);

  const handleClickNotificationBanner = () => {
    notification.onClickCallback?.();
  };

  return (
    <>
      {(showNotif || showNotifAccount) && (
        <NotificationBanner
          content={{
            main: notification.boldText,
            sub: notification.text,
            navigateLink: notification.navigateLink,
          }}
          onClick={handleClickNotificationBanner}
        />
      )}
      {canShowHeaderAndSidebar && (
        <>
          <Header />
          <Sidebar />
        </>
      )}
      <AppRoutes validAccess={validAccess} />
    </>
  );
};

export default App;
