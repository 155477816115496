import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setChangePassData,
  setAccountDetails,
} from '../../../features/userSettings/userSettingsSlice';
import Button from '../Button/Button';
import Input from '../Input/Input';
import css from './Modals.module.css';
import apiv2 from '../../../config/apiv2';
import {
  successResetPassword,
  successChangeCompanyName,
} from '../../../helpers/notyf';
import api from '../../../config/api';

const AccountSecurity = ({ type }) => {
  const { accountDetails, changePassData } = useSelector(
    state => state.userSettings,
  );

  const [errors, setErrors] = useState({
    old_password: '',
    new_password: '',
    new_password_confirm: '',
    name: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    type === 'account1' &&
      api.GET_COMPANY().then(r => {
        const { id, name } = r.data;
        dispatch(
          setAccountDetails({
            id,
            name,
          }),
        );
      });
  }, [dispatch]);

  const handleChange = e => {
    setErrors(p => ({ ...p, [e.target.name]: '' }));

    type === 'account1'
      ? dispatch(
          setAccountDetails({
            ...accountDetails,
            [e.target.name]: e.target.value,
          }),
        )
      : dispatch(
          setChangePassData({
            ...changePassData,
            [e.target.name]: e.target.value,
          }),
        );
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (type === 'account1') {
      api
        .EDIT_COMPANY_NAME({ name: accountDetails.name })
        .then(() => {
          setErrors({
            name: '',
          });

          successChangeCompanyName();
        })
        .catch(e => {
          const errors = e?.response?.data;
          const parsed = Object.entries(errors);
          let fieldsError = {
            name: '',
          };
          parsed.forEach(e => {
            const [field, messages] = e;
            if (field === 'name')
              fieldsError[field] = Array.isArray(messages)
                ? messages.join('\n')
                : messages[field].join('\n');
          });
          setErrors(fieldsError);
        });
    } else {
      apiv2
        .RESET_PASSWORD(changePassData)
        .then(() => {
          setErrors({
            old_password: '',
            new_password: '',
            new_password_confirm: '',
          });

          successResetPassword();
        })
        .catch(e => {
          const errors = e?.response?.data;
          const parsed = Object.entries(errors);
          let fieldsError = {
            old_password: '',
            new_password: '',
            new_password_confirm: '',
          };
          parsed.forEach(e => {
            const [field, messages] = e;
            if (
              field === 'old_password' ||
              field === 'new_password' ||
              field === 'new_password_confirm'
            )
              fieldsError[field] = Array.isArray(messages)
                ? messages.join('\n')
                : messages[field].join('\n');
          });
          setErrors(fieldsError);
        });
    }
  };

  return (
    <>
      <h1 className={css.title}>
        {type === 'account1' ? 'Account Details' : 'Account Security'}
      </h1>
      <form className={css.form}>
        <p className={css.formTitle}>
          {type === 'account1' ? 'Company Name' : 'Current Password'}
        </p>
        <Input
          name={type === 'account1' ? 'name' : 'old_password'}
          type={type === 'account1' ? 'text' : 'password'}
          placeholder={
            type === 'account1'
              ? 'Enter company name'
              : 'Enter your current password'
          }
          value={
            type === 'account1'
              ? accountDetails.name
              : changePassData.current_password
          }
          onChange={handleChange}
          required
        />
        {errors.name && <p className={css.error}>{errors.name}</p>}
        {errors.old_password && (
          <p className={css.error}>{errors.old_password}</p>
        )}

        <p className={css.formTitle}>
          {type === 'account1' ? 'Company ID' : 'New Password'}
        </p>
        {type === 'account1' && (
          <p className={css.warnText}>
            Your Company ID is a unique number used to identify your account. It
            can't be changed.
          </p>
        )}
        <Input
          name={type === 'account1' ? 'id' : 'new_password'}
          type={type === 'account1' ? 'text' : 'password'}
          placeholder={
            type === 'account1' ? 'Company ID' : 'Enter a new password'
          }
          value={
            type === 'account1'
              ? accountDetails.id
              : changePassData.new_password
          }
          onChange={handleChange}
          required
          readOnly={type === 'account1'}
        />

        {errors.new_password && (
          <p className={css.error}>{errors.new_password}</p>
        )}

        {type !== 'account1' && (
          <>
            <p className={css.formTitle}>Confirm Password</p>
            <Input
              name="new_password_confirm"
              type="password"
              placeholder="Enter your new password again"
              value={changePassData.confirm_password}
              onChange={handleChange}
              required
            />
            {errors.new_password_confirm && (
              <p className={css.error}>{errors.new_password_confirm}</p>
            )}
          </>
        )}
        <Button
          styled="submit"
          title={type === 'account1' ? 'Save Changes' : 'Change Password'}
          onClick={handleSubmit}
        />
      </form>
    </>
  );
};

export default AccountSecurity;
