import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import apiv2 from '../../../../../config/apiv2';
import { setViewOptions } from '../../../../../features/dashboard/dashboardSlice';
import {
  addCustomViewToLocalStorage,
  getMessageErrorOfMetricCustomView,
  getNewViewOptions,
  removeCustomViewFromLocalStorage,
  updateCustomViewFromLocalStorage,
} from '../../../../../helpers';
import { error, notyf } from '../../../../../helpers/notyf';
import { getMetricsOfAllSection } from '../../../../../utils';
import Button from '../../../Button/Button';
import stylesTable from './../../../Tables/Tables.module.css';
import styles from './styles.module.css';

const Footer = ({
  onCancel,
  onChangeNameCustomView,
  errorCustomView,
  setError,
  selectedMetrics,
  nameCustomView,
  customViewOptionSelected,
}) => {
  const [isCheckedSave, setIsCheckedSave] = useState(false);

  const dispatch = useDispatch();
  const { viewOptions } = useSelector(state => state.dashboard);

  const isUpdatedMode = customViewOptionSelected;
  const customViewOptionSelectedObj = customViewOptionSelected
    ? JSON.parse(customViewOptionSelected.label)
    : null;

  useEffect(() => {
    if (
      customViewOptionSelected &&
      customViewOptionSelectedObj?.type !== 'browser'
    )
      setIsCheckedSave(true);
  }, [customViewOptionSelected]);

  const handleCancel = () => {
    onCancel();
  };

  const handleCheckSave = () => {
    setIsCheckedSave(!isCheckedSave);
  };

  const handleChangeNameCustomView = e => {
    if (errorCustomView.name) {
      setError(prev => ({
        ...prev,
        name: '',
      }));
    }

    if (onChangeNameCustomView) onChangeNameCustomView(e);
  };

  const handleSaveAndApplyColumnSet = async () => {
    try {
      const customViewBody = {
        name: nameCustomView,
        columns: selectedMetrics.map(column => column.key).join(';'),
      };

      if (!validateCustomView(customViewBody)) return;

      if (isUpdatedMode) {
        const id = customViewOptionSelected.value;
        await updateCustomView(customViewBody, id);
      } else {
        await createCustomView(customViewBody);
      }
    } catch (err) {
      catchErrorWhenSaveAndApplyCustomView(err);
    }
  };

  const updateCustomView = async (customViewBody, id) => {
    let data;
    if (isCheckedSave) {
      if (customViewOptionSelectedObj?.type === 'browser') {
        const res = await apiv2.CREATE_CUSTOM_VIEW(customViewBody);
        data = res.data;
        removeCustomViewFromLocalStorage(id);
      } else {
        const res = await apiv2.UPDATE_CUSTOM_VIEW(customViewBody, id);
        data = res.data;
      }
    } else {
      data = updateCustomViewFromLocalStorage(customViewBody, id);
    }

    const currenViewOptions = [...viewOptions];
    const index = currenViewOptions.findIndex(
      viewOption => viewOption.value === id,
    );
    const shouldSaveCustomViewFromBrowser = isCheckedSave && customViewOptionSelectedObj?.type === 'browser'
    
    if (index !== -1) {
      if (shouldSaveCustomViewFromBrowser) {
        currenViewOptions[index] = {
          value: data.id,
          label: JSON.stringify(data),
        };
      } else {
        currenViewOptions[index] = {
          ...currenViewOptions[index],
          label: JSON.stringify(data),
        };
      }

      dispatch(setViewOptions(currenViewOptions));
    }

    onCancel();
    if (shouldSaveCustomViewFromBrowser) {
      notyf.success('Save custom view successfully!');
    } else {
      notyf.success('Updated custom view successfully!');
    }
  };

  const createCustomView = async customViewBody => {
    let data = null;
    let message = '';

    if (isCheckedSave) {
      const res = await apiv2.CREATE_CUSTOM_VIEW(customViewBody);
      data = res.data;
      message = 'Save custom view successfully!';
    } else {
      data = addCustomViewToLocalStorage(customViewBody);
      message = 'Apply custom view successfully!';
    }

    const newViewOptions = getNewViewOptions(viewOptions, {
      value: data.id,
      label: JSON.stringify(data),
    });

    dispatch(setViewOptions(newViewOptions));
    onCancel();
    notyf.success(message);
  };

  const catchErrorWhenSaveAndApplyCustomView = err => {
    const data = err?.response?.data;

    if (data?.name) {
      setError(prev => ({
        ...prev,
        name: 'This name has already been taken',
      }));
    } else {
      error(err);
    }
  };

  const validateCustomView = customViewBody => {
    let isValidCustomView = true;
    const { name, columns } = customViewBody;
    const { arrColumns, clients, client_metrics } =
      getMetricsOfAllSection(columns);

    const metricError = getMessageErrorOfMetricCustomView(
      arrColumns,
      clients,
      client_metrics,
    );
    if (metricError) {
      setError(prev => ({
        ...prev,
        metric: metricError,
      }));

      isValidCustomView = false;
    }

    if (isCheckedSave) {
      if (name?.trim() === '') {
        setError(prev => ({
          ...prev,
          name: 'Please provide invalid name',
        }));

        isValidCustomView = false;
      }
    }

    return isValidCustomView;
  };

  return (
    <div className={styles.footerContainer}>
      <div className={styles.leftFooter}>
        <div>
          <input
            className={stylesTable.checkbox}
            type="checkbox"
            checked={isCheckedSave}
            onChange={handleCheckSave}
          />
          {isCheckedSave ? (
            <div className={styles.nameColumn}>
              <span>Name your column set</span>
              <input
                type="text"
                onChange={handleChangeNameCustomView}
                value={nameCustomView}
              />
              {errorCustomView.name && (
                <span className={styles.errorText}>{errorCustomView.name}</span>
              )}
            </div>
          ) : (
            <span onClick={handleCheckSave}>Save your column set</span>
          )}
        </div>
      </div>
      <div>
        {errorCustomView.metric && (
          <span className={`${styles.errorText} ${styles.metric}`}>
            {errorCustomView.metric}
          </span>
        )}
        <div className={styles.rightFooter}>
          <Button
            styled="cancel"
            title={`Cancel`}
            onClick={handleCancel}
            className={styles.actionBtn}
          />
          <Button
            styled="submit"
            onClick={handleSaveAndApplyColumnSet}
            title={`${isCheckedSave ? 'Save & Apply' : 'Apply'}`}
            className={styles.actionBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
