import React from 'react';
import css from './Input.module.css';

const Input = ({
  type,
  placeholder,
  value,
  onChange,
  name,
  readOnly,
  styled,
  required,
  style,
  className,
  classNameInput
}) => (
  <input
    name={name}
    type={type}
    className={`${css.input} ${css[styled]} ${css[className]} ${classNameInput}`}
    value={value}
    style={style}
    placeholder={placeholder}
    onChange={onChange}
    readOnly={readOnly}
    required={required}
  />
);

export default Input;
