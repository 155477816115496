import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../../../components/reusable/Card';
import Input from '../../../../../components/reusable/Input/Input';
import Select from '../../../../../components/reusable/Select/Select';
import api from '../../../../../config/api';
import { useSelectPositionLeft } from '../../../../../hooks/useSelectPositionLeft';
import styles from './styles.module.css';
import css from './../../../../../components/reusable/Modals/Modals.module.css';
import { checkBudgetHaveCampaignsFromAccount } from '../../../../../helpers';

const RuleDetails = ({ rule, error, setRule, setBudgetsFromOnlyAccount }) => {
  const { client = null, budget = null, name = '' } = rule;

  const [clientOptions, setClientOptions] = useState([]);
  const [budgetOptions, setBudgetOptions] = useState([]);
  const [budgets, setBudgets] = useState([]);

  const wrapperSelectRef = useRef(null);
  const selectPosLeft = useSelectPositionLeft(wrapperSelectRef);

  useEffect(() => {
    if (budget?.label) {
      const selectedBudget = budgets.find(item => item.id === budget?.value);
      const isFromOneAccount = checkBudgetHaveCampaignsFromAccount(
        selectedBudget?.campaigns,
      );
      setBudgetsFromOnlyAccount(isFromOneAccount);
    }
  }, [budget, budgets]);

  useEffect(() => {
    fetchAndSetClientOptions();
  }, []);

  useEffect(() => {
    if (client?.value) {
      fetchAndSetBudgetOptions();
    }
  }, [client?.value]);

  const fetchAndSetClientOptions = () => {
    api
      .GET_ALL_CLIENTS()
      .then(res => {
        setClientOptions(
          res.map(client => ({
            value: client.id,
            label: client.name,
          })),
        );
      })
      .catch(err => err);
  };

  const fetchAndSetBudgetOptions = () => {
    api
      .GET_BUDGETS_OF_CLIENT(client.value)
      .then(res => {
        setBudgetOptions(
          res.data.map(budget => ({
            value: budget.id,
            label: budget.name,
          })),
        );

        setBudgets(res.data);
      })
      .catch(err => err);
  };

  const handleChangeClient = option => {
    setRule(prev => ({
      ...prev,
      client: option,
      ...(budget?.value ? { budget: null } : {}),
    }));
  };

  const handleChangeBudget = option => {
    setRule(prev => ({
      ...prev,
      budget: option,
    }));
  };

  const handleChangeName = e => {
    setRule(prev => ({
      ...prev,
      name: e.target.value,
    }));
  };

  return (
    <Card title="Rule Details">
      <div className={styles.wrapperBodyRuleDetails}>
        <div>
          <p className={styles.labelInput}>Name</p>
          {error?.name && <p className={css.error}>{error?.name}</p>}
          <Input
            name="name"
            type="text"
            placeholder="Name your rule"
            value={name}
            onChange={handleChangeName}
            required
          />
        </div>

        <div>
          <p className={styles.labelInput}>Select Client</p>
          {error?.client && <p className={css.error}>{error?.client}</p>}
          <div ref={wrapperSelectRef}>
            <Select
              name="client"
              placeHolder="Select a Client"
              styleMenuPortal={{
                left: selectPosLeft,
              }}
              options={clientOptions}
              onChange={handleChangeClient}
              value={client}
            />
          </div>
        </div>

        <div>
          <p className={styles.labelInput}>Select Budget</p>
          {error?.budget && <p className={css.error}>{error?.budget}</p>}
          <div>
            <Select
              disabled={!client?.value}
              name="budget"
              placeHolder="Select a Budget"
              styleMenuPortal={{
                left: selectPosLeft,
              }}
              options={budgetOptions}
              onChange={handleChangeBudget}
              value={budget}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RuleDetails;
