import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactCalendar from 'react-calendar';
import moment from 'moment';
import {
  changeIsDatesPicker,
  changeStartDate,
  changeEndDate,
  changeTab2,
  changeCurrentDate,
} from '../../features/dashboard/dashboardSlice';
import Button from '../reusable/Button/Button';
import css from './DatesPicker.module.css';
import 'react-calendar/dist/Calendar.css';
import api from '../../config/api';

moment.updateLocale('en', { week: { dow: 1 } });
const formatShortWeekday = (locale, date) =>
  ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()];

const DatesPicker = () => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [currentCountry, setCurrentCountry] = useState('');

  const { tab2, isDatesPicker, startDate, endDate, currentDate } = useSelector(
    state => state.dashboard,
  );

  const dispatch = useDispatch();

  const options = [
    {
      value: 'current_cycle',
      label: 'Current Cycle',
    },
    {
      value: 'yesterday',
      label: 'Yesterday',
    },
    {
      value: 'this_week',
      label: 'This week',
    },
    {
      value: 'last_week',
      label: 'Last week',
    },
    {
      value: 'last_seven_days',
      label: 'Last 7 days',
    },
    {
      value: 'last_fourteen_days',
      label: 'Last 14 days',
    },
    {
      value: 'last_thirty_days',
      label: 'Last 30 days',
    },
    {
      value: 'this_month',
      label: 'This month',
    },
    {
      value: 'last_month',
      label: 'Last month',
    },
  ];

  useEffect(() => {
    if (startDate && endDate) {
      setStart(new Date(startDate));
      setEnd(new Date(endDate));
    }
  }, [isDatesPicker]);

  const handleDatesPicker = () => dispatch(changeIsDatesPicker(true));

  const handleClose = () => dispatch(changeIsDatesPicker(false));

  const handleOption = e => {
    dispatch(changeCurrentDate(e.target.id));

    switch (e.target.id) {
      case 'current_cycle':
        setStart(null);
        setEnd(null);
        dispatch(changeStartDate(''));
        dispatch(changeEndDate(''));

        break;

      case 'yesterday':
        dispatch(
          changeStartDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );
        dispatch(
          changeEndDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );

        break;
      case 'this_week':
        dispatch(
          changeStartDate(moment().startOf('week').format('DD MMM YYYY')),
        );
        dispatch(
          changeEndDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );

        break;
      case 'last_week':
        dispatch(
          changeStartDate(
            moment().startOf('week').subtract(1, 'week').format('DD MMM YYYY'),
          ),
        );
        dispatch(
          changeEndDate(
            moment().endOf('week').subtract(1, 'week').format('DD MMM YYYY'),
          ),
        );

        break;
      case 'last_seven_days':
        dispatch(
          changeStartDate(moment().subtract(7, 'day').format('DD MMM YYYY')),
        );
        dispatch(
          changeEndDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );

        break;
      case 'last_fourteen_days':
        dispatch(
          changeStartDate(moment().subtract(14, 'day').format('DD MMM YYYY')),
        );
        dispatch(
          changeEndDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );

        break;
      case 'last_thirty_days':
        dispatch(
          changeStartDate(moment().subtract(30, 'day').format('DD MMM YYYY')),
        );
        dispatch(
          changeEndDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );

        break;
      case 'this_month':
        dispatch(
          changeStartDate(moment().startOf('month').format('DD MMM YYYY')),
        );
        dispatch(
          changeEndDate(moment().subtract(1, 'day').format('DD MMM YYYY')),
        );

        break;
      case 'last_month':
        dispatch(
          changeStartDate(
            moment()
              .startOf('month')
              .subtract(1, 'month')
              .format('DD MMM YYYY'),
          ),
        );
        dispatch(
          changeEndDate(
            moment().endOf('month').subtract(1, 'month').format('DD MMM YYYY'),
          ),
        );

        break;
      default:
        break;
    }
    dispatch(changeTab2('performance'));
    handleClose();
  };

  const handleApply = () => {
    dispatch(changeCurrentDate(`${start}-${end}`))
    dispatch(changeStartDate(moment(start).format('DD MMM YYYY')));
    dispatch(changeEndDate(moment(end).format('DD MMM YYYY')));
    dispatch(changeTab2('performance'));
    handleClose();
  };

  const startTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD MMM YYYY');
    let incomeMonth = moment(date).format('MM');
    let currMonth = moment(activeStartDate).format('MM');

    return `${
      moment(start).format('DD MMM YYYY') === day
        ? css.selectedTile
        : incomeMonth !== currMonth
        ? css.neighbore
        : ''
    } ${css.tile} ${date > activeStartDate ? css.disabledTile : ''}`;
  };

  const endTileContentStyles = ({ activeStartDate, date, view }) => {
    let day = moment(date).format('DD MMM YYYY');
    let incomeMonth = moment(date).format('MM');
    let currMonth = moment(activeStartDate).format('MM');

    return `${
      moment(end).format('DD MMM YYYY') === day
        ? css.selectedTile
        : incomeMonth !== currMonth
        ? css.neighbore
        : ''
    } ${css.tile} ${
      date > activeStartDate || start > date ? css.disabledTile : ''
    }`;
  };

  const onStartChange = (value, e) => setStart(value);

  const onEndChange = (value, e) => setEnd(value);

  useEffect(() => {
    (async () => {
      try {
        const location = await api.GET_CURRENT_LOCATION();

        setCurrentCountry(location?.country_code ?? '');
      } catch (e) {
        if (e) {
          setCurrentCountry('');
        }
      }
    })();
  }, []);

  const toCalendarType = () => {
    return currentCountry === 'US' || currentCountry === 'CA'
      ? 'US'
      : 'ISO 8601';
  };

  return (
    <>
      {isDatesPicker && (
        <>
          <div className={css.overlay} onClick={handleClose} />
          <div className={css.wrapper} onClick={e => e.stopPropagation()}>
            <ul className={css.datesOptions} onClick={handleOption}>
              {options.map(el => (
                <li
                  key={el.value}
                  id={el.value}
                  className={`${css.datesOption} ${
                    el.value === currentDate ? css.dateOptionActive : ''
                  }`}
                >
                  {el.label}
                </li>
              ))}
            </ul>
            <ReactCalendar
              className={`${css.monthView} ${css.disabledTile}`}
              tileClassName={startTileContentStyles}
              locale="en"
              value={start || moment().toDate()}
              view="month"
              calendarType={toCalendarType()}
              formatShortWeekday={formatShortWeekday}
              maxDate={end || new Date(moment().subtract(1, 'day'))}
              onChange={onStartChange}
            />
            <ReactCalendar
              className={css.monthView}
              tileClassName={endTileContentStyles}
              locale="en"
              value={end || moment().toDate()}
              view="month"
              calendarType={toCalendarType()}
              formatShortWeekday={formatShortWeekday}
              minDate={start}
              maxDate={new Date(moment().subtract(1, 'day'))}
              onChange={onEndChange}
            />
            <div className={css.btnsWrapper}>
              <Button
                styled="datesPickerCancel"
                title="Cancel"
                onClick={handleClose}
              />
              <Button
                disabled={!start || !end}
                styled="datesPickerApply"
                title="Apply"
                onClick={handleApply}
              />
            </div>
          </div>
        </>
      )}
      <Button
        styled="datesPicker"
        title={
          startDate && endDate
            ? `${startDate} - ${endDate}`
            : 'Current Budget Cycle'
        }
        onClick={handleDatesPicker}
      />
    </>
  );
};

export default DatesPicker;
