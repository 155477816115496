import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import ProgressBarForBilling from '../../components/ProgressBarForBilling/ProgressBarForBilling';
import { useClickOutside } from '../../hooks/useClickOutside';
import css from './UserSettings.module.css';

const Usage = ({ setTooltip, tooltip, isTrialPeriod }) => {
  const companiesBilling = useSelector(state => state.userSettings.cardData);
  const { payment_method_attached, subscription_status } = useSelector(
    state => state.me.notifications,
  );

  const ref = useRef();
  useClickOutside(ref, () => setTooltip(false));

  const calculateEstPlan = () => {
    if (companiesBilling && companiesBilling?.active_cycle_est_used > 25000)
      return Math.round(companiesBilling.active_cycle_est_used * 0.0004);
    else return 0;
  };

  return (
    <>
      <div className={css.container}>
        <h2 className={css.title}>Usage</h2>
        <div className={css.content}>
          <div className={css.wrap}>
            <div className={css.subTitle} ref={ref}>
              Total Monthly Client Spend (USD){' '}
              <div
                onClick={() => setTooltip(true)}
                className={css.questionMark}
              >
                ?
              </div>
              {tooltip && (
                <div className={css.tooltip}>
                  <p>
                    Airtomic is completely free if you manage less than $25K USD
                    spend/month. Accounts with over $25K USD spend/month, our
                    pricing is $0.0004 for every $1 USD monitored in your
                    dashboard.
                  </p>
                  <br />
                  <p>
                    Your monthly spend is determined by summing up the spend of
                    your tracked campaigns. For campaigns in other currencies,
                    we'll convert them to USD using the latest exchange rate at
                    month-end.
                  </p>
                  <br />
                  <p>
                    We offer monthly invoicing for businesses managing over $1M
                    USD in monthly spend. Alternatively, businesses above $1M
                    USD in monitored spend can choose to stay on the standard
                    monthly subscription pricing rate.
                  </p>
                  <br />
                  <p>
                    We support billing in USD, EUR, GBP, AUD, and CAD. Please
                    see our{' '}
                    <a
                      style={{ color: '#726f95' }}
                      href="https://docs.airtomic.co/guides/usage-and-billing"
                      target="_blanc"
                    >
                      Usage & Billing
                    </a>{' '}
                    guide for more information.
                  </p>
                </div>
              )}
            </div>
            <div className={css.date}>
              {payment_method_attached && subscription_status
                ? `Next Billing Date: ${companiesBilling?.next_billing_date}`
                : ''}
            </div>
          </div>
          <ProgressBarForBilling
            used={companiesBilling?.active_cycle_used}
            active_cycle_est_used={companiesBilling?.active_cycle_est_used}
          />
          <div className={css.lineBar}></div>
          <div className={css.infoWrap}>
            <div className={`${css.info} ${css.used}`}>
              <div className={css.infoTitle}>Used</div>
              <div className={css.infoValue}>
                $
                {companiesBilling?.active_cycle_used
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                USD
              </div>
            </div>
            <div className={`${css.info} ${css.usage}`}>
              <div className={css.infoTitle}>Est. Usage</div>
              <div className={css.infoValue}>
                $
                {companiesBilling?.active_cycle_est_used
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                USD
              </div>
            </div>
            <div className={`${css.info} ${css.plan}`}>
              <div className={css.infoTitle}>Est. Cost</div>
              <div className={css.infoValue}>${calculateEstPlan()} USD</div>
            </div>
          </div>
          {isTrialPeriod && (
            <div className={css.usageWarningText}>
              <p className={css.infoUsageText}>
                Usage details will update after your free trial ends
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Usage;
