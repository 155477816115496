import React from 'react';
import styles from './styles.module.css';

const Card = ({
  title = '',
  className = '',
  bordered = true,
  children = <></>,
}) => (
  <div
    className={`${styles.wrapperCard} ${
      bordered ? styles.bordered : ''
    } ${className} `}
  >
    {title && <h4 className={`${styles.titleCard}`}>{title}</h4>}

    {children}
  </div>
);

export default Card;
