export const RULE_ACTIONS = {
  ENABLED: 'enabled',
  PAUSE: 'pause',
  REMOVE: 'remove',
};

export const STATUS_RULES = {
  ENABLE: 'E',
  PAUSE: 'P',
};

export const RULE_ACTION_TYPES = [
  { value: 0, label: 'Automatically add campaigns to budget' },
];

export const RULE_ACTION_CONDITIONS = [
  { value: 0, label: 'All campaigns' },
  { value: 1, label: 'Campaign name' },
];

export const RULE_NOTIFICATIONS = [
  { value: 0, label: 'Only if there are changes or errors' },
  { value: 1, label: 'Only if there are errors' },
  { value: 2, label: 'No emails' },
];

export const ALL_CAMPAIGNS_CONDITIONS = [
  { value: 0, label: 'Enabled' },
  {
    value: 1,
    label: 'Enabled, Paused',
  },
];

export const CONTAINS_OPTIONS = [
  {
    value: 0,
    label: 'Contains',
  },
  {
    value: 1,
    label: 'Does not contain',
  },
  {
    value: 2,
    label: 'Starts with',
  },
  {
    value: 3,
    label: 'Equals (case sensitive)',
  },
];
