import React, { useEffect, useState } from 'react';
import apiv2 from '../../../../config/apiv2';
import {
  CLIENT,
  CLIENT_METRIC,
  INITIAL_METRICS,
} from '../../../../contants/datasource';
import {
  bindingColumnsCustomViewToModal,
  getCustomViewFromLocalStorageById,
} from '../../../../helpers';
import { CustomModal } from '../CustomModal';
import existIcon from './../../../../assets/exit_icon.svg';
import Collapse from './Collapse';
import Footer from './Footer';
import LabelSection from './LabelSection';
import Metrics from './Metrics';
import SearchInput from './SearchInput';
import styles from './styles.module.css';
import { getMetricsOfAllSection } from '../../../../utils';

const CustomColumnSetModal = ({ onClose, customViewOptionSelected }) => {
  const [customView, setCustomView] = useState(
    JSON.parse(JSON.stringify(INITIAL_METRICS)),
  );
  const [nameCustomView, setNameCustomView] = useState('');
  const [error, setError] = useState({
    name: '',
    metric: '',
  });
  const [searchValue, setSearchValue] = useState('');

  const searchCustomColumnsSet = column =>
    column.name.toLowerCase().includes(searchValue.toLowerCase());

  const items = [
    {
      key: 'clients',
      label: (
        <LabelSection
          title="Clients"
          name="clients"
          checked={
            customView.clients.columns.length &&
            customView.clients.columns.every(column => column.checked)
          }
          onChange={e => handleAllCheckedOfSection(e)}
        />
      ),
      children: (
        <Metrics
          metrics={customView.clients.columns.filter(searchCustomColumnsSet)}
          setCustomView={setCustomView}
        />
      ),
    },
    {
      key: 'client_metrics',
      label: (
        <LabelSection
          title="Metrics"
          name="client_metrics"
          checked={
            customView.client_metrics.columns.length &&
            customView.client_metrics.columns.every(column => column.checked)
          }
          onChange={e => handleAllCheckedOfSection(e)}
        />
      ),
      children: (
        <Metrics
          metrics={customView.client_metrics.columns.filter(
            searchCustomColumnsSet,
          )}
          setCustomView={setCustomView}
        />
      ),
    },
  ];

  const selectedMetrics = Object.values(customView)
    .flatMap(metricType => metricType.columns)
    .map(column => column)
    .filter(column => column.checked);

  const filteredClients = selectedMetrics.filter(
    metric => metric.key.split('/')[0] === CLIENT,
  );
  const filteredMetrics = selectedMetrics.filter(
    metric => metric.key.split('/')[0] === CLIENT_METRIC,
  );

  useEffect(() => {
    fetchClientsAndSetCustomView();
  }, [customViewOptionSelected]);

  useEffect(() => {
    const columns = selectedMetrics.map(column => column.key).join(';');
    const { arrColumns, clients, client_metrics } =
      getMetricsOfAllSection(columns);

    const message = error.metric;
    const isResetErrorMetric =
      (message === 'Please select clients and metrics' && arrColumns.length) ||
      (message === 'Please select clients' && clients.length) ||
      (message === 'Please select metrics' && client_metrics.length);

    if (isResetErrorMetric)
      setError(prev => ({
        ...prev,
        metric: '',
      }));
  }, [customView]);

  const fetchClientsAndSetCustomView = async () => {
    const res = await apiv2.GET_CLIENT_HAVE_BUDGET();
    const data = res.data;
    const clients = data?.map(client => ({
      key: `clients/${client.id}/${client.name}`,
      name: client.name,
      checked: !customViewOptionSelected,
    }));

    customView.clients.columns = clients;

    // Binding custom view to modal
    if (customViewOptionSelected) {
      const temp = JSON.parse(customViewOptionSelected.label);
      await fetchCustomView(
        customViewOptionSelected.value,
        customView,
        temp.type,
      );
    }

    setCustomView({ ...customView });
  };

  const fetchCustomView = async (id, customView, type) => {
    let data;
    if (type === 'browser') {
      data = getCustomViewFromLocalStorageById(id);
    } else {
      const res = await apiv2.GET_CUSTOM_VIEW(id);
      data = res.data;
    }

    const clientColumns = customView.clients.columns;
    const metricColumns = customView.client_metrics.columns;

    bindingColumnsCustomViewToModal(data, clientColumns, metricColumns);
    setNameCustomView(data.name);
  };

  const handleAllCheckedOfSection = event => {
    const sectionType = event.target.name.toLowerCase();
    const section = customView[sectionType];
    const { checked } = event.target;

    section.columns = section.columns.map(column => ({
      ...column,
      checked: checked,
    }));

    setCustomView({
      ...customView,
    });
  };

  const handleRemoveSelectedColumns = metric => {
    const sectionType = metric.key.split('/')[0];
    const { columns } = customView[sectionType];
    const metricIndex = columns.findIndex(item => item.key === metric.key);

    if (metricIndex !== -1) {
      const metric = columns[metricIndex];
      metric.checked = false;
    }

    setCustomView({
      ...customView,
    });
  };

  const handleChangeNameCustomView = e => {
    setNameCustomView(e.target.value);
  };

  const handleChangeSearchValue = e => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <CustomModal
        title="Custom Columns Set"
        onClose={onClose}
        maxWidth={650}
        width={650}
        left={`calc(50% - 26rem)`}
        footer={
          <Footer
            selectedMetrics={selectedMetrics}
            nameCustomView={nameCustomView}
            errorCustomView={error}
            customViewOptionSelected={customViewOptionSelected}
            setError={setError}
            onChangeNameCustomView={handleChangeNameCustomView}
            onCancel={onClose}
          />
        }
      >
        <div>
          <SearchInput value={searchValue} onChange={handleChangeSearchValue} />
          <div className={styles.columnContainer}>
            <Collapse items={items} />
          </div>
          <div
            className={`${styles.selectedWrapper} ${styles.filteredClients}`}
          >
            <h4>Filtered Clients</h4>
            <div className={styles.selectedColumnsContainer}>
              {filteredClients.map(metric => {
                return (
                  <div
                    className={styles.selectedColumn}
                    onClick={() => handleRemoveSelectedColumns(metric)}
                    key={metric.key}
                  >
                    <span>{metric.name}</span>
                    <div>
                      <img src={existIcon} alt="exist_icon" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={`${styles.selectedWrapper} ${styles.filteredMetrics}`}
          >
            <h4>Selected Columns</h4>
            <div className={styles.selectedColumnsContainer}>
              {filteredMetrics.map(metric => {
                return (
                  <div
                    className={styles.selectedColumn}
                    onClick={() => handleRemoveSelectedColumns(metric)}
                    key={metric.key}
                  >
                    <span>{metric.name}</span>
                    <div>
                      <img src={existIcon} alt="exist_icon" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CustomColumnSetModal;
