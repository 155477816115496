import React from 'react';
import Button from '../reusable/Button/Button';
import css from './NotificationModal.module.css';

export const Help = ({ closeModal }) => {
  const handleContact = () => window.open('mailto:hello@airtomic.co', '_blank');

  return (
    <div className={css.overlay} onClick={() => closeModal()}>
      <div className={`${css.NotificationWrapper} ${css.help}`}>
        <h2 className={css.mainTitle}>Help & Feedback</h2>
        <a
          className={css.link}
          target="_blank"
          rel="noreferrer"
          href="https://docs.airtomic.co/overview/announcements"
        >
          New Features & Announcements
        </a>
        <a
          className={css.link}
          target="_blank"
          rel="noreferrer"
          href="https://docs.airtomic.co/overview/getting-started"
        >
          Documentation
        </a>
        <a
          className={css.link}
          target="_blank"
          rel="noreferrer"
          href="https://docs.airtomic.co/support/feedback-and-suggestions"
        >
          Leave Feedback
        </a>
        <Button styled="submit" title="Contact Us" onClick={handleContact} />
      </div>
    </div>
  );
};
