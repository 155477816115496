import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import Input from '../Input/Input';
import css from './Modals.module.css';
import Alert from '../Alert/Alert';

const ResetPassword = ({ data, onSubmit, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setEmailSent] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    // Temp code
    // setTimeout(() => {
    //   setIsLoading(false);
    //   setPasswordSent(true);
    // }, 2000);

    // Handle Submit Here...
    onSubmit(e)
      .then(() => {
        if (data.restorePassword) {
          //logic to be implemented
        } else {
          setEmailSent(true);
        }
        setIsLoading(false);
      })
      .catch(e => setIsLoading(false));
  };

  return (
    <>
      <form className={css.form} onSubmit={handleSubmit}>
        <h1 className={css.title}>Reset Your Password</h1>

        {!data.restorePassword && (
          <>
            <p className={css.formTitle}>Email</p>
            <Input
              name="email"
              type="text"
              placeholder="Enter Email"
              value={data.email}
              onChange={onChange}
              required
            />

            {isEmailSent && (
              <Alert
                type="success"
                message="Please check your mailbox. If your email is associated with our system, you will receive instructions to reset your password."
              />
            )}

            {!isEmailSent && (
              <Button
                type="submit"
                styled="submit"
                title="Send Reset Email"
                disabled={isLoading}
              />
            )}
          </>
        )}

        {data.restorePassword && (
          <>
            <p className={css.formTitle}>New Password</p>
            <Input
              name="password"
              type="password"
              placeholder="Enter a Strong Password"
              value={data.password}
              onChange={onChange}
              required
            />
            <p className={css.formTitle}>Confirm Password</p>
            <Input
              name="cpassword"
              type="password"
              placeholder="Confirm Your Password"
              value={data.cpassword}
              onChange={onChange}
              required
            />

            {data.message && (
              <Alert
                type={data.success ? 'success' : 'error'}
                message={data.message}
              />
            )}

            <Button
              type="submit"
              styled="submit"
              title="Reset Password"
              disabled={isLoading}
            />
          </>
        )}
      </form>

      <div className="auth-footer-text">
        {!isEmailSent && (
          <p className="footerText">
            {`Don't have an account? `}
            <Link className={css.regLink} to="/signup">
              Sign Up
            </Link>
          </p>
        )}
        {isEmailSent && (
          <p className="footerText">
            {`Haven’t received the email? `}
            <span className="as-link" onClick={() => setEmailSent(false)}>
              Try again.
            </span>
          </p>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
