import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDelete } from '../../../features/userSettings/userSettingsSlice';
import { errorBilling } from '../../../helpers/notyf';
import Button from '../Button/Button';
import css from './Modals.module.css';

const DeleteUser = ({ type }) => {
  const dispatch = useDispatch();
  const { cardData } = useSelector(state => state.userSettings);

  const handleDelete = () => {
    const { stripe_subscription_status, unpaid_invoice } = cardData;
    if (
      (location.pathname.split('/')[2] === 'account' &&
        stripe_subscription_status &&
        stripe_subscription_status !== 'canceled') ||
      unpaid_invoice
    ) {
      errorBilling(
        'Cannot delete because the subscription is active or there is an unpaid invoice',
      );
      return;
    }

    dispatch(setIsDelete(true));
  };

  return (
    <>
      <h1 className={css.title}>
        {type === 'user3' ? 'Delete User Account' : 'Delete Account'}
      </h1>
      <p className={css.deleteText}>
        {type === 'user3'
          ? 'Deleting your profile will mean that you no longer have access to this dashboard.'
          : 'Closing an account will remove all users from this dashboard.'}
      </p>
      <p className={css.deleteText}>
        {type === 'user3'
          ? 'All data sources, clients, and budgets connected to this user profile will remain in the dashboard.'
          : 'All data sources, clients and budgets will be removed from the dashboard. All data from this dashboard will be removed from our internal storage within 30 days. You won’t be able to retrieve any historical data.'}
      </p>
      <p className={css.deleteText}>
        {type === 'user3'
          ? 'You cannot delete your user profile if you are the only admin. You must assign another admin first or delete the account in Account Settings.'
          : 'Only admins will be able to perform this action. Deleting an account cannot be undone.'}
      </p>
      <Button
        styled="deleteAcc"
        title={type === 'user3' ? 'Delete User Account' : 'Delete Account'}
        onClick={handleDelete}
      />
    </>
  );
};

export default DeleteUser;
