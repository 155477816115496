import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addFormData,
  setAmountError,
  setNameError,
  setUniqueNameError,
} from '../../features/createBudget/createBudgetSlice';
import { parceBudget } from '../../helpers/parceBudget';
import Input from '../reusable/Input/Input';
import Select from '../reusable/Select/Select';
import css from './SetBudget.module.css';

const SetMonthlyWeeklyBudget = ({ type, weeklyOptions, monthlyOptions }) => {
  const { formData, amountError, nameError, uniqueNameError } = useSelector(
    state => state.createBudget,
  );

  const dispatch = useDispatch();

  const handleChange = (e, a) => {
    if (a?.name) {
      dispatch(addFormData({ ...formData, [a.name]: e }));
    } else {
      if (e.target.name === 'name') {
        dispatch(setNameError(false));
        dispatch(setUniqueNameError(false));
      }
      if (e.target.name === 'amount') dispatch(setAmountError(''));
      const amount = e.target.value.replace(/\,/g, '');
      if (
        e.target.name === 'amount' &&
        e.target.value &&
        (+amount === 0 || +amount > 1000000000 || isNaN(amount))
      )
        return;
      dispatch(
        addFormData({
          ...formData,
          [e.target.name]: e.target.name === 'amount' ? amount : e.target.value,
        }),
      );
    }
  };

  return (
    <>
      {type === 'weekly' && (
        <>
          <h2 className={css.select}>Select Starting Day</h2>
          <Select
            name="week_start_day"
            value={formData.week_start_day || ''}
            options={weeklyOptions}
            onChange={handleChange}
          />
        </>
      )}

      <h2 className={css.select}>Budget Amount</h2>
      {amountError && (
        <p className={css.error}>{amountError}</p>
      )}
      <Input
        name="amount"
        value={
          formData.amount && formData.amount >= 0
            ? parceBudget(formData.amount, '', 0)
            : ''
        }
        styled="mb"
        placeholder="Enter Your Budget Amount"
        onChange={handleChange}
      />
      <h2 className={css.select}>Budget Name</h2>
      {nameError && <p className={css.error}>Please provide a budget name</p>}
      <Input
        name="name"
        value={formData.name || ''}
        styled="mb15"
        type="text"
        placeholder="Enter Your Budget Name"
        onChange={handleChange}
      />
      {uniqueNameError && (
        <p className={css.error}>A budget with this name already exists</p>
      )}
    </>
  );
};
 
export default SetMonthlyWeeklyBudget;
