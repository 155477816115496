import React from 'react';
import SearchIcon from '../../../../../assets/search.svg';
import styles from './styles.module.css';

const SearchInput = ({ value, onChange }) => {
  const handleChange = e => {
    if (onChange) onChange(e);
  };

  return (
    <div className={`${styles.searchInputWrapper}`}>
      <input
        type="text"
        placeholder="Search..."
        value={value}
        onChange={handleChange}
      />
      <img src={SearchIcon} alt="search_icon" />
    </div>
  );
};

export default SearchInput;
