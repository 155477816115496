import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import pause from '../../../../assets/pause.svg';
import stylesTable from '../../../../components/reusable/Tables/Tables.module.css';
import { parceBudget, parcePercentage } from '../../../../helpers/parceBudget';
import Table from '../../Table';
import { formater } from '../DashboardTableRows/functions';

const ClientBudgetTable = ({
  data,
  type,

  isLoading,
  isDefaultCurrentCycleView,
  isAllChecked,

  handleClickClient,
  handleCheckOne,
  handleCheckAll,
}) => {
  const columns = [
    {
      title: (
        <input
          className={stylesTable.checkbox}
          type="checkbox"
          checked={isAllChecked}
          onChange={handleCheckAll}
        />
      ),
      enableColumnResizing: false,
      width: '50px',
      minWidth: '50px',
      fixed: 'left',
      render: (record, _) => {
        return (
          record.row_type !== 'total' && (
            <input
              className={stylesTable.checkbox}
              type="checkbox"
              checked={record.isChecked}
              onChange={() => {
                if (handleCheckOne)
                  handleCheckOne(formater('key', record, type));
              }}
            />
          )
        );
      },
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      key: 'client_name',
      width: '200px',
      minWidth: '200px',
      className: `${stylesTable.thNoWidth}`,
      fixed: 'left',
      sorter: true,
      style: ({ row_type }, _) => ({
        ...(row_type === 'total'
          ? { color: '#111A39', cursor: 'not-allowed' }
          : { color: '#1649EC', cursor: 'pointer' }),
      }),
      render: ({ row_type, client_name }, _) => {
        return row_type === 'total' ? 'Total' : client_name;
      },
      onClickDataCell: (record, _) => {
        if (handleClickClient) handleClickClient(record);
      },
    },
    {
      title: 'Budget Amount',
      dataIndex: 'budget_amount',
      key: 'budget_amount',
      width: '160px',
      minWidth: '160px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ budget_amount, currency }, _) => {
        return parceBudget(budget_amount, currency);
      },
    },
    {
      title: 'Spend',
      dataIndex: 'spend',
      key: 'spend',
      width: '160px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: (record, _) => {
        return isDefaultCurrentCycleView ? (
          <div className={stylesTable.progressBarContainer}>
            <ProgressBar
              completed={+record.spend}
              maxCompleted={+record.budget_amount}
              customLabel={parceBudget(record.spend, record.currency, 0)}
              borderRadius="0"
              height="3rem"
              bgColor={formater('primaryColor', +record.trend, type)}
              baseBgColor={formater('secondaryColor', +record.trend, type)}
              labelAlignment="left"
              labelColor="var(--white)"
              labelSize="1.2rem"
              labelClassName={stylesTable.progressBarLabel}
              width="13rem"
            />
            {record?.status === 'paused' && (
              <img className={stylesTable.pause} alt="pause" src={pause} />
            )}
          </div>
        ) : (
          <>{parceBudget(record.spend, record.currency, 0)}</>
        );
      },
    },
    {
      title: 'Trend',
      dataIndex: 'trend',
      key: 'trend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: ({ trend, completed_status }, _) =>
        `${stylesTable.thNoWidth} ${formater(
          'trendColor',
          trend,
          completed_status ?? '',
        )}`,
      render: ({ trend, completed_status }, _) => {
        return formater('trendName', trend, completed_status ?? '');
      },
    },
    {
      title: 'Remaining Budget',
      dataIndex: 'remaining_budget',
      key: 'remaining_budget',
      width: '160px',
      minWidth: '160px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ remaining_budget, currency }, _) => {
        return parceBudget(remaining_budget, currency);
      },
    },
    {
      title: 'Ideal Daily Spend',
      dataIndex: 'ideal_daily_spend',
      key: 'ideal_daily_spend',
      width: '160px',
      minWidth: '160px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ ideal_daily_spend, currency }, _) => {
        return parceBudget(ideal_daily_spend, currency);
      },
    },
    {
      title: "Yesterday's Spend",
      dataIndex: 'yesterday_spend',
      key: 'yesterday_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ yesterday_spend, currency }, _) => {
        return parceBudget(yesterday_spend, currency);
      },
    },
    {
      title: '3 Day Avg. Spend',
      dataIndex: 'tree_days_avg_spend',
      key: 'tree_days_avg_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ tree_days_avg_spend, currency }, _) => {
        return parceBudget(tree_days_avg_spend, currency);
      },
    },
    {
      title: 'Forecast Spend',
      dataIndex: 'forecast_spend',
      key: 'forecast_spend',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ forecast_spend, currency }, _) => {
        return parceBudget(forecast_spend, currency);
      },
    },
    {
      title: 'Lost IS (Budget)',
      dataIndex: 'lost_is_budget_percentage',
      width: '120px',
      minWidth: '120px',
      sorter: true,
      className: `${stylesTable.thNoWidth}`,
      render: ({ lost_is_budget_percentage }, _) => {
        return parcePercentage(lost_is_budget_percentage);
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      isLoading={isLoading}
      unSortLastElement
      rowClassNameProvider={record => {
        const baseClassName = stylesTable.tr;
        const additionalClassName =
          record?.row_type === 'total' ? stylesTable.trTotal : '';

        return `${baseClassName} ${additionalClassName}`;
      }}
    />
  );
};

export default ClientBudgetTable;
