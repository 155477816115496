import React from 'react';
import closeLight from '../../../../assets/closeLight.svg';
import statusBAD from '../../../../assets/statusBAD.svg';
import statusOK from '../../../../assets/statusOK.svg';
import { RULE_ACTIONS } from '../../../../contants/automatedRules';
import style from './styles.module.css';

const RuleActionCombobox = ({ className, onChangeAction }) => {
  const handleClickAction = (e, type) => {
    e.stopPropagation();
    onChangeAction && onChangeAction(type);
  };

  return (
    <div className={className}>
      <div className={style.ruleCbb}>
        <div
          className={style.ruleCbbItem}
          onClick={e => handleClickAction(e, RULE_ACTIONS.ENABLED)}
        >
          <div>
            <img alt="Status Enabled" src={statusOK} />
          </div>
          <span>Enabled</span>
        </div>
        <div
          className={style.ruleCbbItem}
          onClick={e => handleClickAction(e, RULE_ACTIONS.PAUSE)}
        >
          <div>
            <img src={statusBAD} alt="Status Paused" />
          </div>
          <span>Paused</span>
        </div>
        <div
          className={style.ruleCbbItem}
          onClick={e => handleClickAction(e, RULE_ACTIONS.REMOVE)}
        >
          <div>
            <img
              src={closeLight}
              className={style.removeIcon}
              alt="Remove icon"
            />
          </div>
          <span>Remove</span>
        </div>
      </div>
    </div>
  );
};

export default RuleActionCombobox;
