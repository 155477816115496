import React from 'react';
import css from './Button.module.css';

const Button = ({
  type,
  styled,
  onClick,
  title,
  children,
  disabled,
  style,
  className
}) => (
  <button
    type={type}
    className={`${css.button} ${css[styled]} ${disabled ? css.disabled : ''} ${className}`}
    onClick={onClick}
    disabled={disabled}
    style={style ?? { style }}
  >
    {title}
    {children}
  </button>
);

export default Button;
