import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dataSource: [],
    isNewDataSource: false,
    isOpenAddDataSourceModal: false,
};

const dataSourceSlice = createSlice({
    name: 'dataSource',
    initialState,
    reducers: {
        setDataSource: (state, action) => {
            state.dataSource = action.payload
        },
        setIsNewDataSource: (state, action) => {
            state.isNewDataSource = action.payload
        },
        setIsOpenNewDataSourceModal: (state, action) => {
            state.isOpenAddDataSourceModal = action.payload;
        }
    },
});

export const {
    setDataSource,
    setIsNewDataSource,
    setIsOpenNewDataSourceModal
} = dataSourceSlice.actions;

export default dataSourceSlice.reducer;
