import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import Input from '../Input/Input';
import googleLogo from '../../../assets/googleLogo.svg';
import css from './Modals.module.css';
import Alert from '../Alert/Alert';

const SignUp = ({ data, onGoogleLogin, onSubmit, onChange, onCheckbox }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    onSubmit(e).then(() => {
      setIsLoading(false);
    }).catch(e => {
      setIsLoading(false);
    });
  };

  return (
    <>
      <Button styled="google" onClick={onGoogleLogin}>
        <div className={css.googleWrapper}>
          <img className={css.googleLogo} alt="Google Logo" src={googleLogo} />
          <span className={css.googleText}>Sign up With Google</span>
        </div>
      </Button>


      <div className={css.orWrapper}>
        <hr className={css.orHr} />
        <span className={css.orText}>OR</span>
        <hr className={css.orHr} />
      </div>

      <form className={css.form} onSubmit={handleSubmit}>
        <h1 className={css.title}>Sign Up</h1>
        <h3 className="sub-title">No credit card required</h3>
        <p className={css.formTitle}>Name</p>
        <Input
          name="name"
          type="text"
          placeholder="Enter Full Name"
          value={data.name}
          onChange={onChange}
          required
        />
        <p className={css.formTitle}>Email</p>
        <Input
          name="email"
          type="email"
          placeholder="Enter Email"
          value={data.email}
          onChange={onChange}
          required
        />
        <p className={css.formTitle}>Password</p>
        <Input
          name="password"
          type="password"
          placeholder="Enter Password"
          value={data.password}
          onChange={onChange}
          required
        />

        {
          data.message && <Alert
            type={(data.success) ? 'success' : 'error'}
            message={data.message}
          />
        }

        <Button
          type="submit"
          styled="submit"
          title="Sign Up"
          disabled={isLoading}
        />
      </form>

      <div className="auth-footer-text">
        <p className="footerText">
          {`Already have an account? `}
          <Link className={css.regLink} to="/login">
            Sign In
          </Link>
        </p>
        <p className="term-text">
          By clicking Sign Up you agree to our{' '}
          <a className={css.regLink} href="https://docs.airtomic.co/resources/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a className={css.regLink} href="https://docs.airtomic.co/resources/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service.
          </a>
        </p>
      </div>
    </>
  );
};

export default SignUp;
