import React from 'react';
import { TYPE_DATASOURCES } from '../../../contants/datasource';
import Button from '../Button/Button';
import css from './Popups.module.css';

const AddSourcePopup = ({ onClose, onClick }) => (
  <>
    <h1 className={css.title}>Connect new Data Source</h1>
    <div className={css.sourcesWrapper}>
      {
        Object.values(TYPE_DATASOURCES).map(typeDataSource => (
          <div className={css.sourceWrapper} onClick={() => onClick(typeDataSource.type)} key={typeDataSource.type}>
            <img alt={typeDataSource.name} src={typeDataSource.logo} />
            <p className={css.sourceName}>{typeDataSource.name}</p>
          </div>
        ))
      }
    </div>
    <div className={css.btnWrapper}>
      <Button styled="close" title="Cancel" onClick={onClose} />
    </div>
  </>
);

export default AddSourcePopup;
